import React, {ReactElement, useLayoutEffect} from 'react'
import {observer} from "mobx-react";

import {useStores} from "../../../store/useStore";
import {
  TABLE_PARTICIPANTS_HEAD_VALUES,
  TABLE_PARTICIPANTS_KEYS,
  getContent,
  TSXTableRowParticipants,
} from "./helper";

import SimpleTable from "../../shared/simpleTable";

import s from "./Participants.module.scss";
import Loader from "../../shared/loader";


const Participants = observer(() => {

  const {
    informationStore: {
      getParticipantsAsync,
      participants,
      idsParticipants,
      loading,
    },
    userStore: {
      selectedOperator
    }
  } = useStores();

  useLayoutEffect(() => {
    getParticipantsAsync()
  }, [getParticipantsAsync, selectedOperator])

  const getTableHeadParticipants = (el: keyof typeof TABLE_PARTICIPANTS_HEAD_VALUES): string => {
    return getContent(TABLE_PARTICIPANTS_HEAD_VALUES[el])
  }

  const getTableRowParticipants = (id: string | number): ReactElement => {
    if (!loading && participants.length > 0) {
      return TSXTableRowParticipants(id, participants);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Participants
      </div>
      <div className={s.info}>
        In the table below you can see, how many networks are already members of<br/>
        the community (Participant) and how many will join us in the nearest future (In Progress).<br/>
        By comparing it with the list of your most important partners you can see, which of your routes<br/>
        are currently well- protected.
      </div>
      <div className={s.wrapperTable}>
        {loading ?
          <div className={s.loader}>
            <Loader color="secondary"/>
          </div> :
          <div className={s.table}>
            <SimpleTable
              styleName={s.tableBilling}
              ids={idsParticipants}
              getTableHead={getTableHeadParticipants}
              tableKeys={TABLE_PARTICIPANTS_KEYS}
              getTableRow={getTableRowParticipants}
            />
          </div>
        }
      </div>
    </div>
  )
})

export default Participants;
