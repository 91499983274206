import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Page from "../../../shared/page";
import Form from "../shared/Form";
import { MAIN } from "../../../../utils";

import s from "../Scenarios.module.scss";

const ScenarioForm = observer(() => {
  const { id } = useParams<{ id?: string }>();

  const isEdit = !!Number(id);

  return (
    <Page
      title={
        <>
          <div className={s.back}>
            <Link
              to={
                isEdit
                  ? `/${MAIN.TEST_CALLS}/scenarios/${id}/view`
                  : `/${MAIN.TEST_CALLS}`
              }
            >
              <IconButton className={s.iconButton}>
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </Link>
          </div>
          {isEdit ? "Edit scenario" : "Add new scenario"}
        </>
      }
      info={
        <>
          {isEdit ? (
            <>
              Here you can edit your scenarios for Test Call Generator. <br />
              Please contact our support if you have any questions.
            </>
          ) : (
            <>
              Here you can add new scenario for Test Call Generator. <br />
              Please contact our support if you have any questions.
            </>
          )}
        </>
      }
    >
      <Form />
    </Page>
  );
});

export default ScenarioForm;
