import React, { useCallback, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Select,
  MenuItem,
  Menu,
  Divider,
  Modal,
  Button,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useStores } from "../../../../store/useStore";
import { MAIN, Variants } from "../../../../utils";

import s from "./Header.module.scss";

const classesMUI = {
  inputClasses: ["selectCustomInput", "selectCustomInputSecondary"],
  iconClasses: ["selectCustomIcon", "selectCustomIconSecondary"],
};

const Header = observer(() => {
  const [variant, setVariant] = useState<string>(Variants.primary);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { pathname } = useLocation();

  const {
    userStore: { user, selectedOperator, setOperator, logoutUser },
  } = useStores();

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setOperator(event.target.value as number);
    },
    [setOperator]
  );

  //needed, because component wrap routes
  useLayoutEffect(() => {
    if (pathname.includes(MAIN.DASHBOARD)) {
      setVariant(Variants.primary);
    } else {
      setVariant(Variants.secondary);
    }
  }, [pathname]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const [primaryInput, secondaryInput] = classesMUI.inputClasses;
  const [primaryIcon, secondaryIcon] = classesMUI.iconClasses;

  const body = (
    <div className={s.modal}>
      <div className={s.titleModal}>Are you sure want to logout?</div>
      <div className={s.buttonBar}>
        <Button
          disableRipple={true}
          variant="contained"
          color="default"
          style={{
            marginRight: "10px",
          }}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          disableRipple={true}
          variant="contained"
          color="primary"
          onClick={logoutUser}
        >
          Log out
        </Button>
      </div>
    </div>
  );

  const openModal = () => {
    setIsOpen(true);
    setAnchorEl(null);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <Select
          value={selectedOperator}
          onChange={(e) => handleChange(e)}
          classes={{
            select: "customSelect",
          }}
          className={s.select}
          disableUnderline={true}
          MenuProps={{
            classes: {
              list: "customList",
            },
          }}
          inputProps={{
            classes: {
              root: `${
                variant === Variants.primary ? primaryInput : secondaryInput
              }`,
              icon: `${
                variant === Variants.primary ? primaryIcon : secondaryIcon
              }`,
            },
          }}
        >
          {user?.operators.map((el) => (
            <MenuItem value={el.id} key={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
        <div
          className={`${s.icon} ${
            variant === Variants.primary ? s.primary : s.secondary
          }`}
          onClick={handleClick}
        >
          <AccountCircleIcon />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            disableRipple={true}
            className={`${s.menuItem} ${s.noHover}`}
          >
            <div>
              <div>
                <strong>{user?.email}</strong>
              </div>
              <div>{`${user?.first_name} ${user?.last_name}`}</div>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem className={s.menuItem} onClick={() => openModal()}>
            Log out
          </MenuItem>
        </Menu>
      </div>
      <Modal open={isOpen} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
});

export default Header;
