import React, {FC} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../../store/useStore";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";


const SimpleSnackbar: FC<any> = observer(() => {
  const {
    notificationStore: {
      snackbar,
      changeStateSnackbar
    }
  } = useStores();

  if (snackbar?.severity) {
    return (
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          changeStateSnackbar(undefined)
        }}
        autoHideDuration={5000}
      >
        <Alert
          icon={false}
          action={false}
          severity={snackbar.severity}
          onClose={() => changeStateSnackbar(undefined)}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    )
  }

  return (
    <Snackbar
      open={snackbar.open}
      message={snackbar.message}
      onClose={() => {
        changeStateSnackbar(undefined)
      }}
      autoHideDuration={5000}
    />
  )
})

export default SimpleSnackbar
