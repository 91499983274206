import React, {useEffect, useLayoutEffect, useCallback, useState, useMemo} from "react";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import LinearProgress from '@material-ui/core/LinearProgress';
import {useHistory, useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react";
import Flag from "react-flagkit";

import NetworkForm from "../../../shared/forms/networkForm";
import Loader from "../../../shared/loader";
import ItemList from "../../../shared/itemList";

import {useStores} from "../../../../store/useStore";
import {
  HELPERS,
  MAIN,
  FieldControlPermission
} from "../../../../utils";

import s from '../Networks.module.scss'


const ListNetworks = observer(() => {

  const {params} = useRouteMatch<{ id?: string }>();
  const history = useHistory();

  const {
    networksStore: {
      getNetworkAsync,
      networks,
      network,
      loading,
    },
    userStore: {
      selectedOperator,
      user
    },
    notificationStore: {
      changeStateSnackbar
    }
  } = useStores();

  const [state, setState] = useState(selectedOperator);

  const checkPermissions = useMemo(() => {
    const selected = user?.operators.find((el) => el.id === selectedOperator);
    if (selected) {
      return selected.role.permissions.includes('networks/any');
    }
    return false
  }, [selectedOperator, user?.operators])

  useLayoutEffect(() => {
    if (state !== selectedOperator) {
      setState(selectedOperator);
      history.push(`/${MAIN.NETWORKS}`)
    }
  }, [selectedOperator, state, history])

  useEffect(() => {
    if (params.id) {
      getNetworkAsync(Number(params.id))
    } else {
      if (networks.length > 0) {
        const {id} = networks[0];
        getNetworkAsync(id)
      }
    }
  }, [getNetworkAsync, networks, params.id])

  const {item, loading: loadingItem, ids} = network;

  const changeItem = useCallback((id: number) => {
    history.push(`/${MAIN.NETWORKS}/${id}`)
  }, [history])

  const copyMessage = (message: string) => {
    changeStateSnackbar(message)
  }

  const createNode = () => {
    history.push(`/${MAIN.NETWORKS}/${HELPERS.CREATE}`)
  }

  const createPrefixBlock = () => {}

  const updatePrefixBlock = () => {};

  const clickButtons = (type: string) => {
    if (checkPermissions) {
      switch (type) {
        case FieldControlPermission.create_network:
          return createNode();
        case FieldControlPermission.create_prefix:
          return createPrefixBlock();
        case FieldControlPermission.update_prefix:
          return updatePrefixBlock();
        default:
          return;
      }
    } else {
      changeStateSnackbar("Not allowed. You don't have permission for this action. Please contact your account administrator.")
    }
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Networks
      </div>
      <div className={s.info}>
        Networks managed by Operator. Only approved networks can be used for future configuration <br/>
        and uploaded into Coordinating Center.
      </div>
      <div className={s.wrapperDetail}>
        {loading ?
          <div className={s.wrapperLoader}>
            <Loader color="secondary"/>
          </div> :
          <>
            <div className={s.wrapperItems}>
              {networks.map((el) => (
                <ItemList
                  key={el.id}
                  item={el}
                  selectedID={item?.id}
                  changeItem={changeItem}
                  clickButtons={clickButtons}
                  permissionField={FieldControlPermission.create_network}
                >
                  <div className={s.country}>
                    <Flag country={el.country_iso}/>
                  </div>
                  <div className={s.content}>
                    <span className={s.name}>{el.name}</span>
                    <div className={s.infoItem}>{`${el.country_iso}, ${el.type}`}</div>
                  </div>
                </ItemList>
              ))}
              <ItemList
                item={null}
                clickButtons={clickButtons}
                permissionField={FieldControlPermission.create_network}
              >
                <AddCircleRoundedIcon/>
                <div className={s.txt}>
                  Add network
                </div>
              </ItemList>
            </div>
            {!loadingItem && item ?
              <NetworkForm
                ids={ids}
                item={item}
                copyMessage={copyMessage}
                clickButtons={clickButtons}
              /> :
              <div className={s.wrapperLoaderLinear}>
                <LinearProgress color="secondary"/>
              </div>
            }
          </>
        }
      </div>
      <div>
      </div>
    </div>
  )
})

export default ListNetworks;
