import {SIGN_UP} from "../../utils";

export const pathSignUp = [
  {
    path: `${SIGN_UP.CREATE}`,
    text: 'Create an account'
  },
  {
    path: `${SIGN_UP.CONFIRM}`,
    text: 'Confirm email'
  },
  {
    path: `${SIGN_UP.OPERATOR}`,
    text: 'Provide operator info'
  },
  {
    path: `${SIGN_UP.INTEGRATION}`,
    text: 'Choose integration type'
  },
  {
    path: `${SIGN_UP.COMPANY}`,
    text: 'Provide company info'
  },
  {
    path: `${SIGN_UP.ACTIVATION}`,
    text: 'Wait for activation'
  },
];

export enum StateRoutes {
  integration = 'integration',
  company = 'company',
  activation = 'activation'
}

export const defaultParams = {
  integration_type: '-',
  organization_name: '-',
  organization_registration_number: '-',
  organization_country: '-',
  organization_city: '-',
  organization_zip_code: '-',
  organization_address: '-',
  network_type: '-',
  network_name: '-',
  requested_network_id: '-',
  network_country: '-',
  submitted: true,
};

