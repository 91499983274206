import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    fontFamily: "Nunito Sans, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontWeightLight: 600,
  },
  overrides: {
    MuiStepIcon: {
      root: {
        color: "#A1A1A1",
        "&$active": {
          color: "#0176FE",
        },
        "&$completed": {
          color: "#0176FE",
        },
      },
      active: {
        color: "black",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        padding: "10px 20px",
      },
    },
    MuiLinearProgress: {
      root: {
        backgroundColor: "#0176FE",
      },
      colorPrimary: {
        color: "#fff",
      },
      bar1Indeterminate: {
        backgroundColor: "#0176FE",
      },
      barColorSecondary: {
        backgroundColor: "#0176FE",
      },
      colorSecondary: {
        backgroundColor: "rgba(1, 18, 254, 0.3)",
      },
    },
    MuiCircularProgress: {
      root: {
        color: "#0176FE",
      },
      colorPrimary: {
        color: "#fff",
      },
      colorSecondary: {
        color: "#0176FE",
      },
    },
    MuiPaper: {},
    MuiList: {
      root: {
        "&.customList": {
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 16px) scale(1)",
      },
    },
    MuiSvgIcon: {
      root: {
        "&.selectCustomIcon": {
          color: "#fff",
          "&.MuiSelect-iconOpen": {
            marginRight: "0",
          },
        },
        "&.selectCustomIconSecondary": {
          color: "#0176FE",
          "&.MuiSelect-iconOpen": {
            marginRight: "0",
          },
        },
      },
      fontSizeLarge: {
        fontSize: "16px",
      },
      fontSizeSmall: {
        fontSize: "16px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
      },
      contained: {
        boxShadow: "none",
        backgroundColor: "#fff",
        color: "#0176FE",
        fontSize: "16px",
        fontWeight: 600,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "#fff",
          color: "#0176FE",
        },
      },
      containedPrimary: {
        backgroundColor: "#0176FE",
        color: "#fff",
        fontSize: "16px",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#016dea",
          color: "#fff",
        },
        "&$disabled": {
          backgroundColor: "rgba(1, 118, 254, 0.5)",
          color: "#fff",
        },
      },
      containedSecondary: {
        backgroundColor: "#FBA700",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#e59902",
          color: "#fff",
        },
      },
      textPrimary: {
        backgroundColor: "#fff",
        color: "#0176FE",
      },
      textSecondary: {
        backgroundColor: "#fff",
        color: "#FBA700",
      },
    },
    MuiTextField: {
      root: {
        margin: "12px 0",
        height: "44px",
        width: "100%",
      },
    },
    MuiSelect: {
      root: {
        "&:focus": {
          backgroundColor: "transparent !important",
        },
        "&.selectCustomInput": {
          backgroundColor: "#3593FF",
          borderRadius: "30px",
          maxWidth: "150px",
          padding: "7px 36px 5px 24px",
          paddingRight: "36px !important",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "19px",
          "&:before": {
            borderBottom: "0px solid black",
          },
          "&:focus": {
            backgroundColor: "#3593FF",
            borderRadius: "30px",
            padding: "7px 36px 5px 24px",
            paddingRight: "36px !important",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
          },
        },
        "&.selectCustomInputSecondary": {
          backgroundColor: "#DBEBFF",
          borderRadius: "30px",
          maxWidth: "150px",
          padding: "7px 36px 5px 24px",
          paddingRight: "36px !important",
          color: "#0176FE",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "19px",
          "&:before": {
            borderBottom: "0px solid black",
          },
          "&:focus": {
            backgroundColor: "#DBEBFF",
            borderRadius: "30px",
            padding: "7px 36px 5px 24px",
            paddingRight: "36px !important",
            color: "#0176FE",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "19px",
          },
        },
      },
      outlined: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: "#0176FE",
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        fontSize: "16px",
        fontWeight: 600,
      },
      label: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "44px",
        width: "100%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: 1,
        },
      },
    },
    MuiMenu: {
      paper: {
        padding: "10px 0",
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "46px",
        "&$selected": {
          backgroundColor: "#F9F9F9",
        },
        "&:hover": {
          backgroundColor: "#F9F9F9",
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "40px",
      },
      h2: {
        fontSize: "32px",
      },
      body1: {
        fontSize: "16px",
      },
      body2: {
        fontSize: "14px",
      },
    },
    MuiSwitch: {
      root: {
        borderRadius: "50px",
        width: "32px",
        height: "20px",
        padding: 0,
        margin: "8px",
      },
      switchBase: {
        padding: 0,
        "&.Mui-checked": {
          transform: "translateX(12px)",
        },
        "&.Mui-disabled": {
          pointerEvent: "none",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          filter: "grayscale(50%)",
          cursor: "not-allowed",
        },
      },
      thumb: {
        color: "white",
        height: "16px",
        margin: "2px",
        width: "16px",
      },

      track: {
        width: "32px",
        backgroundColor: "#eef2f7",
        opacity: 1,
        "$checked$checked + &": {
          width: "32px",
          opacity: 1,
          backgroundColor: "#0176FE",
        },
      },
    },
  },
});
