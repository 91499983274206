import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { observer } from "mobx-react";

import ListScenarios from "./listScenarios";
import { useStores } from "../../../store/useStore";
import DetailScenario from "./detailScenario";
import ScenarioForm from "./scenarioForm";
import { HELPERS, MAIN } from "../../../utils";

const Scenarios = observer(() => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const {
    userStore: { user, selectedOperator },
    operatorStore: { getRouteGroupsAsync },
  } = useStores();

  useEffect(() => {
    const findAccessUser = user?.operators.find(
      (el) => el.id === selectedOperator
    );

    if (findAccessUser) {
      if (!findAccessUser.tcg_enabled) {
        history.push(`/${MAIN.DASHBOARD}`);
      }
    }
  }, [selectedOperator, history, user]);

  useEffect(() => {
    getRouteGroupsAsync();
  }, [getRouteGroupsAsync, selectedOperator]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}/scenarios/${HELPERS.INDEX}`}
        component={ScenarioForm}
      />
      <Route
        exact
        path={`${path}/scenarios/${HELPERS.INDEX}/view`}
        component={DetailScenario}
      />
      <Route exact path={path} component={ListScenarios} />
      <Redirect from="*" to={path} />
    </Switch>
  );
});

export default Scenarios;
