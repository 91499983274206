import { useEffect, useState } from "react";
import HeaderContainer from "./HeaderContainer";
import { useLocation } from "react-router-dom";
import { SIGN_IN, SIGN_UP } from "../../../utils";

const Header = () => {
  const [show, setShow] = useState(false);

  const { pathname } = useLocation();

  //need, because user request start after app loading, need hide header if user in main page
  useEffect(() => {
    if (pathname.includes(SIGN_IN.ROOT)) {
      setShow(true);
      return;
    }
    if (pathname.includes(SIGN_UP.ROOT)) {
      setShow(true);
      return;
    }
    setShow(false);
  }, [pathname]);

  if (!show) {
    return null;
  }

  return <HeaderContainer />;
};

export default Header;
