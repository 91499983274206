import React, {FC} from "react";

import {TRoutes} from "../../../../types";

import s from '../Scenarios.module.scss';

type Props = {
  route: TRoutes
}

const FormLabelRoute: FC<Props> = ({route}) => {
  return (
    <div className={s.wrapperLabelRoute}>
      <div className={s.nameRoute}>
        {route.quality.charAt(0).toUpperCase() + route.quality.slice(1)}
      </div>
      <div className={s.quantityRoute}>
        {`~${route.routes_count} routes`}
      </div>
      <div className={s.priceRoute}>
        {`$${route.min_price} - ${route.max_price}`}
      </div>
    </div>
  )
}

export default FormLabelRoute;
