import React, {FC, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import {
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {userAPI} from "../../../../services";
import {TCreateAccount} from "../../../../types";
import {SIGN_UP, SIGN_IN} from "../../../../utils";
import {FIELD_REQUIRED, CORPORATE_EMAIL, PASSWORD_LENGTH, BUTTON_TIMEOUT} from "../../../../constants";
import Button from "../../button";

import s from '../forms.module.scss'

//lib check free base emails
const freeEmailDomains = require('free-email-domains');

const validationSchema = yup.object({
  first_name: yup.string().required(FIELD_REQUIRED),
  last_name: yup.string().required(FIELD_REQUIRED),
  company: yup.string().required(FIELD_REQUIRED),
  job_title: yup.string().required(FIELD_REQUIRED),
  email: yup.string().email('Enter a valid email').required(FIELD_REQUIRED).test('test-name', CORPORATE_EMAIL,
    // @ts-ignore
    function (value = '') {
      const getMail = value.split('@');
      const [user, mail] = getMail;
      return !!mail && !!user && !freeEmailDomains.includes(mail);
    }),
  password: yup.string().required(FIELD_REQUIRED).min(8, PASSWORD_LENGTH)
})

const CreateAccount: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      company: '',
      job_title: '',
      email: '',
      password: ''
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const {host, protocol} = window.location;
      const params: TCreateAccount = {
        ...values,
        verify_url: `${protocol}//${host}/${SIGN_UP.ROOT}/${SIGN_UP.CONFIRM}`
      };
      userAPI.createUser(params)
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.CONFIRM}?email=${values.email}`)
          }, BUTTON_TIMEOUT)
        }).catch((err: any) => {
        const {response} = err;
        if (response && response.data) {
          const {errors} = response.data;
          setServerErrors(errors);
        }
        setLoading(false);
      })
    },
  });

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty} = formik;

  const onChange = (event: any, field: string) => {
    if (serverErrors[field]) {
      const newServerErrors: any = serverErrors;
      delete newServerErrors[field];
      setServerErrors({
        ...newServerErrors
      })
    }
    handleChange(event);
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Create an account
      </div>
      <div className={s.createSubtitle}>
        At the moment we accept registrations only from corporate emails.
        Already have an account? <Link to={`/${SIGN_IN.ROOT}`}>Log in</Link>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={s.row}>
          <TextField
            variant="outlined"
            name="first_name"
            value={values.first_name}
            onChange={(e) => onChange(e, 'first_name')}
            onBlur={handleBlur}
            placeholder="First name"
            label="First name"
            error={(touched.first_name && Boolean(errors.first_name)) || Boolean(serverErrors.first_name)}
            helperText={(touched.first_name && errors.first_name !== FIELD_REQUIRED && errors.first_name) || serverErrors.first_name}
          />
          <TextField
            variant="outlined"
            name="last_name"
            value={values.last_name}
            onChange={(e) => onChange(e, 'last_name')}
            onBlur={handleBlur}
            placeholder="Last name"
            label="Last name"
            error={(touched.last_name && Boolean(errors.last_name)) || Boolean(serverErrors.last_name)}
            helperText={(touched.last_name && errors.last_name !== FIELD_REQUIRED && errors.last_name) || serverErrors.last_name}
          />
        </div>
        <div className={s.row}>
          <TextField
            variant="outlined"
            name="company"
            value={values.company}
            onChange={(e) => onChange(e, 'company')}
            onBlur={handleBlur}
            placeholder="Company / operator name"
            label="Company / operator name"
            error={(touched.company && Boolean(errors.company)) || Boolean(serverErrors.company)}
            helperText={(touched.company && errors.company !== FIELD_REQUIRED && errors.company) || serverErrors.company}
          />
          <TextField
            variant="outlined"
            name="job_title"
            value={values.job_title}
            onChange={(e) => onChange(e, 'job_title')}
            onBlur={handleBlur}
            placeholder="Job title"
            label="Job title"
            error={(touched.job_title && Boolean(errors.job_title)) || Boolean(serverErrors.job_title)}
            helperText={(touched.job_title && errors.job_title !== FIELD_REQUIRED && errors.job_title) || serverErrors.job_title}
          />
        </div>
        <TextField
          variant="outlined"
          name="email"
          value={values.email}
          onChange={(e) => onChange(e, 'email')}
          onBlur={handleBlur}
          placeholder="Corporate email"
          label="Corporate email"
          error={(touched.email && Boolean(errors.email)) || Boolean(serverErrors.email)}
          helperText={(touched.email && errors.email !== FIELD_REQUIRED && errors.email) || serverErrors.email}
        />
        <TextField
          variant="outlined"
          name="password"
          value={values.password}
          onChange={(e) => onChange(e, "password")}
          onBlur={handleBlur}
          type={showPassword ? 'text' : 'password'}
          placeholder="Your password"
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility/> : <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          }}
          label="Your password"
          error={(touched.password && Boolean(errors.password)) || Boolean(serverErrors.password)}
          helperText={(touched.password && errors.password !== FIELD_REQUIRED && errors.password) || serverErrors.password}
        />
        <div className={s.footer}>
          <div className={`${s.createSubtitle} ${s.footerInfo}`}>
            By registering you agree to our <Link to="/">Terms of Service</Link> and <Link
            to={{pathname: 'https://abhandshake.com/privacy-policy'}} target="_blank">Privacy
            Policy</Link>
          </div>
          <Button
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
            endIcon={<ArrowForwardIcon fontSize="small"/>}
          >
            Create account
          </Button>
        </div>
      </form>
    </div>
  )
};

export default CreateAccount;
