import React, {FC, memo, useEffect, useState} from "react";
import {BarChart, Bar, XAxis, Tooltip} from "recharts";

import {tickFormatter} from "../../../../utils";
import {TList} from "../../../../types";

import s from '../Scenarios.module.scss';

type Props = {
  list: TList[],
  namePage: string
}

const barItems = [
  {color: '#000', title: 'Route failed'},
  {color: '#49D657', title: 'Successful calls'},
  {color: '#FF8080', title: 'Local CLI'},
  {color: '#FDD380', title: 'Spoofed CLI'},
  {color: '#80bbff', title: 'Undelivered'},
]

const Schedule: FC<Props> = memo(({list, namePage}) => {
  const [width, setWidth] = useState<number>(0);
  const [waitGetWidth, setWaitGetWidth] = useState<boolean>(true);

  const watchSize = () => {
    const schedule = document.querySelector(`#${namePage}`)?.getBoundingClientRect();
    if (schedule) {
      setWidth(schedule.width);
      setWaitGetWidth(false);
    }
  }

  useEffect(() => {
    watchSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const CustomTooltip = (props: any) => {
    const {active, payload} = props;
    if (active && payload && payload.length) {
      return (
        <div className={s.tooltip}>
          <div>Route failed: {tickFormatter(payload[0].value, payload[0].value)}</div>
          <div>Successful calls: {tickFormatter(payload[1].value, payload[1].value)}</div>
          <div>Local CLI: {tickFormatter(payload[2].value, payload[2].value)}</div>
          <div>Spoofed CLI: {tickFormatter(payload[3].value, payload[3].value)}</div>
          <div>Undelivered: {tickFormatter(payload[4].value, payload[4].value)}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {list.length !== 0 && (
        <div className={s.wrapperSchedule}>
          <div className={s.bar}>
            {barItems.map((el, idx) => (
              <div key={idx} className={s.barItem}>
                <div className={s.square} style={{backgroundColor: el.color}}/>
                {el.title}
              </div>
            ))}
          </div>
          {!waitGetWidth && (
            <BarChart
              width={width}
              height={150}
              data={list}
            >
              <XAxis dataKey="date" tick={{fontSize: 11}} axisLine={false} tickLine={false}/>
              <Tooltip cursor={{fill: 'rgba(206, 206, 206, 0.2)'}} content={<CustomTooltip/>}/>
              <Bar dataKey={'failed_route_count'} barSize={5} fill={'#000'} radius={[3, 3, 3, 3]}/>
              <Bar dataKey={'ok_count'} barSize={5} fill={'#49D657'} radius={[3, 3, 3, 3]}/>
              <Bar dataKey={'domestic_cli_count'} barSize={5} fill={'#FF8080'} radius={[3, 3, 3, 3]}/>
              <Bar dataKey={'spoofed_cli_count'} barSize={5} fill={'#FDD380'} radius={[3, 3, 3, 3]}/>
              <Bar dataKey={'undelivered_count'} barSize={5} fill={'#80bbff'} radius={[3, 3, 3, 3]}/>
            </BarChart>
          )}
        </div>
      )}
    </>
  )
})

export default Schedule;
