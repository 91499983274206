import {instance} from "./root";
import {TCompany} from "../types";

export const OperatorAPI = {
  getCompany() {
    return instance.get('/api/v1/operator')
  },
  changeCompany(params: Partial<TCompany>) {
    const data: any = {}
    Object.entries(params).forEach(([key, value]) => {
      if(value) {
        data[key] = value;
      }
    })
    return instance.put('/api/v1/operator', {
      ...data
    })
  },
  getBilling() {
    return instance.get('/api/v1/invoices')
  }
}
