import React, {FC} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {MenuItem, TextField} from "@material-ui/core";
import {COUNTRY_CODES_MAP, SIGN_UP} from "../../../../utils";
import {FIELD_REQUIRED, ONLY_NUMBER} from "../../../../constants";
import {useStores} from "../../../../store/useStore";
import Button from "../../button";

import s from "../forms.module.scss";

const validationSchema = yup.object({
  organization_name: yup.string().required(FIELD_REQUIRED),
  organization_country: yup.string().required(FIELD_REQUIRED),
  organization_registration_number: yup.number().required(FIELD_REQUIRED).typeError(ONLY_NUMBER),
  organization_city: yup.string().required(FIELD_REQUIRED),
  organization_zip_code: yup.number().required(FIELD_REQUIRED).typeError(ONLY_NUMBER),
  organization_address: yup.string().required(FIELD_REQUIRED),
})

const CompanyInfo: FC = observer(() => {

  let history = useHistory();


  const {
    registrationRequestStore: {
      isSubmit,
      setDataStorage,
      getFieldValueStorage
    }
  } = useStores();

  const formik = useFormik({
    initialValues: {
      organization_name: String(getFieldValueStorage('organization_name')),
      organization_country: String(getFieldValueStorage('organization_country')),
      organization_registration_number: String(getFieldValueStorage('organization_registration_number')),
      organization_city: String(getFieldValueStorage('organization_city')),
      organization_zip_code: String(getFieldValueStorage('organization_zip_code')),
      organization_address: String(getFieldValueStorage('organization_address')),
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDataStorage({
        ...values
      })
      history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.ACTIVATION}`);
    },
  });

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty} = formik;

  const goToIntegration = () => {
    history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.INTEGRATION}`)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Company info
      </div>
      <form onSubmit={handleSubmit} className={s.companyInfo}>
        <TextField
          name="organization_name"
          label="Organization name"
          placeholder="Organization name"
          value={values.organization_name}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          disabled={isSubmit}
          error={(touched.organization_name && Boolean(errors.organization_name))}
          helperText={(touched.organization_name && errors.organization_name !== FIELD_REQUIRED && errors.organization_name)}
        />
        <div className={s.row}>
          <TextField
            select
            label="Country"
            name="organization_country"
            value={values.organization_country}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={isSubmit}
            error={(touched.organization_country && Boolean(errors.organization_country))}
            helperText={(touched.organization_country && errors.organization_country !== FIELD_REQUIRED && errors.organization_country)}
          >
            {Object.entries(COUNTRY_CODES_MAP).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="organization_registration_number"
            label="Registration number"
            placeholder="Registration number"
            value={values.organization_registration_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={isSubmit}
            error={(touched.organization_registration_number && Boolean(errors.organization_registration_number))}
            helperText={(touched.organization_registration_number && errors.organization_registration_number !== FIELD_REQUIRED && errors.organization_registration_number)}
          />
        </div>
        <div className={s.row}>
          <TextField
            name="organization_city"
            label="City / State"
            placeholder="City / State"
            value={values.organization_city}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={isSubmit}
            error={(touched.organization_city && Boolean(errors.organization_city))}
            helperText={(touched.organization_city && errors.organization_city !== FIELD_REQUIRED && errors.organization_city)}
          />
          <TextField
            name="organization_zip_code"
            label="Zip / Postcode"
            placeholder="Zip / Postcode"
            value={values.organization_zip_code}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={isSubmit}
            error={(touched.organization_zip_code && Boolean(errors.organization_zip_code))}
            helperText={(touched.organization_zip_code && errors.organization_zip_code !== FIELD_REQUIRED && errors.organization_zip_code)}
          />
        </div>
        <TextField
          name="organization_address"
          label="Address line"
          placeholder="Address line"
          value={values.organization_address}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          disabled={isSubmit}
          error={(touched.organization_address && Boolean(errors.organization_address))}
          helperText={(touched.organization_address && errors.organization_address !== FIELD_REQUIRED && errors.organization_address)}
        />
        <div className={s.buttonsBar} style={{marginTop: '44px'}}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={goToIntegration}
            startIcon={<ArrowBackIcon fontSize="small"/>}
          >
            Integration type
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
            endIcon={<ArrowForwardIcon fontSize="small"/>}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
})

export default CompanyInfo;
