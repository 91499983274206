import React, {FC, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useFormik} from "formik";
import {
  IconButton,
  InputAdornment,
  TextField
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import * as yup from "yup";

import {userAPI} from "../../../../services";
import {useStores} from "../../../../store/useStore";
import {SIGN_UP, SIGN_IN} from "../../../../utils";
import {FIELD_REQUIRED, PASSWORD_LENGTH} from "../../../../constants";
import Button from "../../button";

import s from "../forms.module.scss";

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required(FIELD_REQUIRED),
  password: yup.string().required(FIELD_REQUIRED).min(8, PASSWORD_LENGTH)
})

const LogIn: FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const {
    userStore: {setToken},
    notificationStore: {changeStateSnackbar}
  } = useStores();

  let history = useHistory();

  const onChange = (event: any, field: string) => {
    if (serverErrors[field]) {
      const newServerErrors: any = serverErrors;
      delete newServerErrors[field];
      setServerErrors({
        newServerErrors
      })
    }
    handleChange(event);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const goToForgot = () => {
    history.push(`/${SIGN_IN.ROOT}/${SIGN_IN.FORGOT}`)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      userAPI.auth(values)
        .then((res) => {
          const {data} = res;
          setToken(data.token);
        }).catch((err) => {
        const {response} = err;
        if (response && response.data) {
          const {errors, error} = response.data;
          if (errors) {
            setServerErrors(errors);
            changeStateSnackbar({message: errors?.password || errors.email || 'Something Went Wrong, Please Try Again'})
            return;
          }
          if (error) {
            changeStateSnackbar({message: error})
          }
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  });

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty} = formik;

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Sign in
      </div>
      <div className={`${s.createSubtitle} ${s.loginSubtitle}`}>
        Not a participant yet?<br/>
        <Link to={`/${SIGN_UP.ROOT}`}>Join the AB Handshake global call validation system</Link>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          name="email"
          value={values.email}
          onChange={(e) => onChange(e, 'email')}
          onBlur={handleBlur}
          placeholder="Registration email"
          label="Registration email"
          error={(touched.email && Boolean(errors.email)) || Boolean(serverErrors.email)}
          helperText={(touched.email && errors.email !== FIELD_REQUIRED && errors.email) || serverErrors.email}
        />
        <TextField
          variant="outlined"
          name="password"
          value={values.password}
          onChange={(e) => onChange(e, "password")}
          onBlur={handleBlur}
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {showPassword ? <Visibility/> : <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          }}
          label="Password"
          error={(touched.password && Boolean(errors.password)) || Boolean(serverErrors.password)}
          helperText={(touched.password && errors.password !== FIELD_REQUIRED && errors.password) || serverErrors.password}
        />
        <div className={s.buttonsBar} style={{marginTop: '192px'}}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={goToForgot}
          >
            Forgot password?
          </Button>
          <Button
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
            endIcon={<ArrowForwardIcon fontSize="small"/>}
          >
            Sign in
          </Button>
        </div>
      </form>
    </div>
  )
};


export default LogIn;
