export enum UserStatus {
  verified = 'verified',
  new = 'new',
  active = 'active'
}

export enum Variants {
  primary = 'primary',
  secondary = 'secondary'
}

export enum FieldControlPermission {
  create_node = 'create_node',
  create_network = 'create_network',
  create_prefix = 'create_prefix',
  update_prefix = 'update_prefix',
  create_token = 'create_token'
}

export enum DaysWeek {
  Sun = 'Sun',
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thu = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat'
}

export enum WindowInterval {
  all = 'all',
  day = 'day',
  hour = 'hour'
}
