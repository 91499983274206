import React, {FC, useState} from "react";
import {useIntercom} from "react-use-intercom";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import ModeCommentIcon from '@material-ui/icons/ModeComment';

import {useStores} from "../../../../store/useStore";
import {SIGN_UP} from "../../../../utils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "../../button";

import s from "../forms.module.scss";

const Activation: FC = observer(() => {
  const [loading, setLoading] = useState<boolean>(false);

  let history = useHistory();

  const {
    registrationRequestStore: {
      sendSubmit,
      setDataStorage,
      data,
      getFieldValue,
      setFieldValue
    },
    userStore: {
      user,
    },
    notificationStore: {
      changeStateSnackbar
    }
  } = useStores();

  const {
    network_name,
    network_type,
    integration_type,
    organization_name,
    organization_registration_number,
    organization_address,
    organization_city,
    organization_zip_code,
    organization_country,
  } = data()

  const {show} = useIntercom();

  const goToCompany = () => {
    history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.COMPANY}`)
  }

  const getDisabled = () => {
    return !(network_name && integration_type && organization_name);
  }

  const submit = async () => {
    setLoading(true);
    try {
      await sendSubmit();
      setFieldValue('submitted', true);
      setDataStorage({submitted: true});
    } catch (err) {
      setFieldValue('submitted', false);
      setDataStorage({submitted: false});
      changeStateSnackbar(err);
    }
    setLoading(false);
  }

  return (
    <div className={s.wrapper}>
      {Boolean(getFieldValue('submitted')) ?
        <>
          <div className={s.title}>
            Activation...
          </div>
          <div className={s.wrapperActivation}>
            Congrats! <br/>
            You provided all required information for<br/>
            registration new AB Handshake participant <span>({organization_name})</span>.<br/>
            We need time to check information and activate your account.<br/>
            You can contact us directly to send additional details:
            <div className={s.margin}/>
            <Button
              onClick={show}
              variant="contained"
              color="secondary"
              fullWidth
              startIcon={<ModeCommentIcon/>}>
              Contact us
            </Button>
          </div>
        </>
        :
        <>
          <div className={s.title}>
            Complete registration
          </div>
          <div className={s.activationRow}>
            <div className={s.name}>Your account</div>
            <div className={s.text}>
              {`${user?.first_name} ${user?.last_name} (${user?.email})`}
            </div>
          </div>
          {network_name && (
            <>
              <div className={s.activationRow}>
                <div className={s.name}>Operator Info</div>
                <div className={s.text}>
                  {`${network_name} (${network_type})`}
                </div>
              </div>
            </>
          )}
          {integration_type && (
            <>
              <div className={s.activationRow}>
                <div className={s.name}>Integration type</div>
                <div className={s.text}>
                  {`${integration_type}`}
                </div>
              </div>
            </>
          )}
          {organization_name && (
            <>
              <div className={s.activationRow}>
                <div className={s.name}>Company info</div>
                <div className={s.text}>
                  {`${organization_name} (${organization_registration_number})`}
                </div>
                <div className={s.text}>
                  {`
                  ${organization_address}, 
                  ${organization_city}, 
                  ${organization_zip_code}, 
                  ${organization_country}`}
                </div>
              </div>
            </>
          )}
          <div className={`${s.buttonsBar} ${s.buttonsActivation}`}>
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={goToCompany}
              startIcon={<ArrowBackIcon fontSize="small"/>}
            >
              Company info
            </Button>
            <Button
              loading={loading}
              onClick={submit}
              type="button"
              variant="contained"
              color="primary"
              disabled={getDisabled()}
            >
              Submit registration request
            </Button>
          </div>
        </>
      }
    </div>
  )
});

export default Activation;
