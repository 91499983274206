import { makeAutoObservable, runInAction } from "mobx";

import { DashboardAPI } from "../services/DashboardAPI";
import { TPrefixBlockNetwork, TStateNetwork, TNetwork } from "../types";

export class NetworksStore {
  networks: TNetwork[] = [];
  network: TStateNetwork = {
    item: null,
    ids: [],
    loading: true,
  };
  idsNetworks: string[] | number[] | [] = [];
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setNetworks = (data: TNetwork[]) => {
    this.idsNetworks = data.map((el) => el.id);
    this.networks = data;
    this.loading = false;
  };

  setNetwork = (data: TNetwork | null) => {
    const ids = data?.prefix_blocks.map((el: TPrefixBlockNetwork) => el.id);
    this.network = {
      item: data,
      ids: ids || [],
      loading: false,
    };
  };

  getNetworks = () => {
    this.loading = true;
    DashboardAPI.getNetworks().then(({ data }) => {
      this.setNetworks(data);
    });
  };

  getNetworkAsync = (id: number) => {
    this.network = {
      ...this.network,
      loading: true,
    };
    DashboardAPI.getNetwork(id)
      .then((res) => {
        const { data } = res;
        runInAction(() => {
          this.setNetwork(data);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.setNetwork(null);
        });
      });
  };
}
