import React, {ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import LinearProgress from "@material-ui/core/LinearProgress";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import {useHistory, useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react";

import Loader from "../../../shared/loader";
import ItemList from "../../../shared/itemList";
import SimpleTable from "../../../shared/simpleTable";

import {useStores} from "../../../../store/useStore";
import {ACCESS_TOKEN_KEY} from "../../../../services/root";
import {HELPERS, MAIN, FieldControlPermission} from "../../../../utils";
import {
  TABLE_GENERAL_HEAD_VALUES,
  TABLE_EVENT_HEAD_VALUES,
  TABLE_NETWORK_HEAD_VALUES,
  TABLE_TOKENS_HEAD_VALUES,
  TABLE_GENERAL_KEYS,
  TABLE_EVENT_KEYS,
  TABLE_NETWORK_KEYS,
  TABLE_TOKENS_KEYS,
  getContent,
  TSXTableRowGeneral,
  TSXTableRowEvent,
  TSXTableRowNetwork,
  TSXTableRowToken
} from "./helper";

import s from "./ListNodes.module.scss";


const ListNodes = observer(() => {

  const {params} = useRouteMatch<{ id?: string }>();
  const history = useHistory();

  const {
    nodesStore: {
      getNodesAsync,
      getNodeAsync,
      nodes,
      loading,
      node,
    },
    userStore: {
      user,
      selectedOperator
    },
    notificationStore: {
      changeStateSnackbar
    },
    statsStore: {
      getStatsAsync,
      activeStat,
      loading: loadingStat
    }
  } = useStores()

  const [state, setState] = useState(selectedOperator);

  useLayoutEffect(() => {
    getNodesAsync()
    getStatsAsync()
    if (state !== selectedOperator) {
      setState(selectedOperator);
      history.push(`/${MAIN.NODES}`)
    }
  }, [selectedOperator, getNodesAsync, state, history, getStatsAsync])

  useEffect(() => {
    if (params.id) {
      getNodeAsync(Number(params.id))
    } else {
      if (nodes.length > 0) {
        const {id} = nodes[0];
        getNodeAsync(id)
      }
    }
  }, [getNodeAsync, nodes, params.id])

  const changeItem = useCallback((id: number) => {
    history.push(`/${MAIN.NODES}/${id}`)
  }, [history])

  const generateHref = () => {
    const token = String(localStorage.getItem(ACCESS_TOKEN_KEY));
    return `https://docs.abhandshake.com?at=${token}&route=callregistry/cr_installation_guide`
  }

  const createNode = () => {
    history.push(`/${MAIN.NODES}/${HELPERS.CREATE}`)
  }

  const createNetwork = () => {
    history.push(`/${MAIN.NETWORKS}/${HELPERS.CREATE}`)
  }

  const createToken = () => {
  }

  const checkPermissions = useMemo(() => {
    const selected = user?.operators.find((el) => el.id === selectedOperator);
    if (selected) {
      return selected.role.permissions.includes('nodes/any');
    }
    return false
  }, [selectedOperator, user?.operators])

  const clickButtons = (type: string) => {
    if (checkPermissions) {
      switch (type) {
        case FieldControlPermission.create_node:
          return createNode();
        case FieldControlPermission.create_network:
          return createNetwork()
        case FieldControlPermission.create_token:
          return createToken()
        default:
          return;
      }
    } else {
      changeStateSnackbar("Not allowed. You don't have permission for this action. Please contact your account administrator.")
    }
  }

  const {item, loading: loadingItem, idsTokens, idsNetworks} = node;

  const getTableHeadGeneral = (el: keyof typeof TABLE_GENERAL_HEAD_VALUES): string => {
    return getContent(TABLE_GENERAL_HEAD_VALUES[el])
  }

  const getTableHeadEvent = (el: keyof typeof TABLE_EVENT_HEAD_VALUES): string => {
    return getContent(TABLE_EVENT_HEAD_VALUES[el])
  }

  const getTableHeadNetwork = (el: keyof typeof TABLE_NETWORK_HEAD_VALUES): string => {
    return getContent(TABLE_NETWORK_HEAD_VALUES[el])
  }

  const getTableHeadToken = (el: keyof typeof TABLE_TOKENS_HEAD_VALUES): string => {
    return getContent(TABLE_TOKENS_HEAD_VALUES[el])
  }

  const goToNetwork = (id: number) => {
    history.push(`/${MAIN.NETWORKS}/${id}`)
  }

  const getTableRowGeneral = (): ReactElement => {
    if (item) {
      return TSXTableRowGeneral(item);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  const getTableRowEvent = (): ReactElement => {
    if (item) {
      return TSXTableRowEvent(item);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  const getTableRowNetwork = (id: string | number): ReactElement => {
    if (item && item.networks.length > 0) {
      return TSXTableRowNetwork(id, item.networks, activeStat, goToNetwork);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  const getTableRowToken = (id: string | number): ReactElement => {
    if (item && item.networks.length > 0) {
      return TSXTableRowToken(id, item.tokens, changeStateSnackbar);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        CR Nodes
      </div>
      <div className={s.info}>
        On this screen, you can view and manage your Call Registry nodes.<br/>
        Please follow the
        <a
          href={generateHref()}
          target="_blank"
          className={s.link}
          rel="noreferrer"
        >
          Call Registry Installation Guide
        </a>
        to install CR node to your server.
      </div>
      <div className={s.wrapperDetail}>
        {loading ?
          <div className={s.wrapperLoader}>
            <Loader color="secondary"/>
          </div> :
          <>
            <div className={s.wrapperItems}>
              {nodes.map((el) => (
                <ItemList
                  key={el.id}
                  item={el}
                  selectedID={item?.id}
                  changeItem={changeItem}
                  clickButtons={clickButtons}
                  permissionField={FieldControlPermission.create_node}
                >
                  <div className={s.icon}>
                    <DnsOutlinedIcon/>
                  </div>
                  <div className={s.content}>
                    <span className={s.name}>{el.label}</span>
                    <div className={s.infoItem}>{el.state.host}</div>
                  </div>
                </ItemList>
              ))}
              <ItemList
                item={null}
                clickButtons={clickButtons}
                permissionField={FieldControlPermission.create_node}
              >
                <AddCircleRoundedIcon/>
                <div className={s.txt}>
                  Add node
                </div>
              </ItemList>
            </div>
            {!loadingItem && item && !loadingStat ?
              <div>
                <div className={s.wrapperTable}>
                  <div className={s.subtitle}>
                    General
                  </div>
                  <div className={s.table}>
                    <SimpleTable
                      styleName={s.tableGeneral}
                      ids={[item.id]}
                      getTableHead={getTableHeadGeneral}
                      tableKeys={TABLE_GENERAL_KEYS}
                      getTableRow={getTableRowGeneral}
                    />
                  </div>
                  <div style={{marginTop: '30px'}}>
                    <div className={s.table}>
                      <SimpleTable
                        styleName={s.tableGeneral}
                        ids={[item.id]}
                        getTableHead={getTableHeadEvent}
                        tableKeys={TABLE_EVENT_KEYS}
                        getTableRow={getTableRowEvent}
                      />
                    </div>
                  </div>
                </div>
                <div className={s.wrapperTable}>
                  <div className={s.subtitle}>
                    Networks
                    <span>
                    <AddCircleRoundedIcon onClick={() => clickButtons(FieldControlPermission.create_network)}/>
                  </span>
                  </div>
                  <div className={s.table}>
                    <SimpleTable
                      styleName={s.tableNetwork}
                      ids={idsNetworks}
                      getTableHead={getTableHeadNetwork}
                      tableKeys={TABLE_NETWORK_KEYS}
                      getTableRow={getTableRowNetwork}
                    />
                  </div>
                </div>
                <div className={s.wrapperTable}>
                  <div className={s.subtitle}>
                    Tokens
                    <span>
                    <AddCircleRoundedIcon onClick={() => clickButtons(FieldControlPermission.create_token)}/>
                  </span>
                  </div>
                  <div className={s.table}>
                    <SimpleTable
                      styleName={s.tableTokens}
                      ids={idsTokens}
                      getTableHead={getTableHeadToken}
                      tableKeys={TABLE_TOKENS_KEYS}
                      getTableRow={getTableRowToken}
                    />
                  </div>
                </div>

              </div> :
              <div className={s.wrapperLoaderLinear}>
                <LinearProgress color="secondary"/>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
})

export default ListNodes;
