import React from "react";
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';

import {HELPERS} from "../../../utils";

import CreateNetwork from "./createNetwork";
import ListNetworks from './listNetworks'

const Networks = () => {

  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/${HELPERS.CREATE}`} component={CreateNetwork}/>
      <Route exact path={[path, `${path}/:id`]} component={ListNetworks}/>
      <Redirect from="*" to={path}/>
    </Switch>
  )
}

export default Networks
