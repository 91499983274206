import { instance } from "./root";
import moment from "moment";
import { TNetwork } from "types";

export const DashboardAPI = {
  getStats() {
    const ago = moment().add(-30, "days").utcOffset(0);
    const startDate = Number(ago.startOf("day").format("X"));
    const fromDate = moment.unix(startDate).valueOf();
    return instance.get(`/api/v1/stats/daily?from_date=${fromDate}`);
  },
  getNodes() {
    return instance.get(`/api/v1/nodes?extended=1&state=1`);
  },
  getNode(id: number) {
    return instance.get(`/api/v1/nodes/${id}?extended&state`);
  },
  getNetworks() {
    return instance.get<TNetwork[]>("/api/v1/networks");
  },
  getNetwork(id: number) {
    return instance.get(`/api/v1/networks/${id}?extended=1`);
  },
};
