import {makeAutoObservable} from "mobx";

import {REGISTRATION_REQUEST} from "../services/root";
import {TRegistrationRequest} from "../types";
import {otherAPI} from "../services";
import {defaultRegistration} from "./defaultStates";


const stateStorage = localStorage.getItem(REGISTRATION_REQUEST);

export class RegistrationRequestStore {
  registrationRequest: TRegistrationRequest = defaultRegistration;
  submitted: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.registrationRequest = stateStorage ? JSON.parse(stateStorage) : defaultRegistration;
  }

  data = (): TRegistrationRequest => {
    const stateStorage = localStorage.getItem(REGISTRATION_REQUEST);
    if (stateStorage) {
      const json = JSON.parse(stateStorage)
      return {
        ...defaultRegistration,
        ...json,
      }
    }
    return defaultRegistration
  }

  getFieldValue = (field: keyof TRegistrationRequest) => {
    return this.registrationRequest[field]
  }

  getFieldValueStorage = (field: keyof TRegistrationRequest) => {
    const state = this.data();
    return state[field]
  }

  setFieldValue = (field: keyof TRegistrationRequest, value: string | boolean) => {
    if (field === 'submitted') {
      this.registrationRequest[field] = Boolean(value);
    } else {
      this.registrationRequest[field] = String(value);
    }
  }

  setNotSaveData = () => {
    this.registrationRequest = this.data()
  }

  setDataStorage = (params: Partial<TRegistrationRequest>) => {
    const state = this.data();
    const obj = {
      ...defaultRegistration,
      ...state,
      ...params
    };
    localStorage.setItem(REGISTRATION_REQUEST, JSON.stringify(obj))
  }

  get isSubmit(): boolean {
    const state = this.data();
    return state['submitted']
  }

  sendSubmit = async () => {
    const params = JSON.parse(localStorage.getItem(REGISTRATION_REQUEST) || '');
    await otherAPI.sendSubmitApplication(params)
  }
}
