import {instance} from "./root";
import {TRegistrationRequest, TRoutes} from "../types";

export const otherAPI = {
  getOperatorType() {
    return instance.get('/api/v1/dict/networks')
  },
  getRouteGroups() {
    return instance.get<TRoutes[]>('/api/v1/tcg/route_groups')
  },
  sendSubmitApplication(params: TRegistrationRequest) {
    const {
      network_type,
      network_country,
      network_name,
      requested_network_id,
      integration_type,
      organization_name,
      organization_registration_number,
      organization_country,
      organization_city,
      organization_zip_code,
      organization_address
    } = params;
    return instance.post('/api/v1/submit_application', {
      network_type,
      network_country,
      network_name,
      requested_network_id: Number(requested_network_id),
      integration_type,
      organization_name,
      organization_registration_number,
      organization_country,
      organization_city,
      organization_zip_code,
      organization_address
    })
  },
  sendRequestAccess(id: string) {
    return instance.post('/api/v1/request_access', {
      operator_id: Number(id)
    })
  }
}
