import { ReactElement, useCallback, useEffect, useState } from "react";
import { IconButton, Switch, LinearProgress } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { COUNTRY_CODES_MAP } from "utils";
import SimpleTable from "../../../shared/simpleTable";
import { FoldableList } from "components/Main/scenarios/shared/CallsNumbers";
import Button from "../../../shared/button";
import Loader from "../../../shared/loader";
import Schedule from "../shared/Schedule";
import Page from "../../../shared/page";
import RowItem from "../shared/RowItem";

import {
  getTableHeadCalls,
  TABLE_CALLS_KEYS,
  TSXTableRowCalls,
} from "../helper";
import { ScenariosApi } from "services";
import { useStores } from "../../../../store/useStore";
import { EStatus, TScenarioDetail } from "../../../../types";
import { MAIN } from "../../../../utils";

import { ReactComponent as IconCheckMark } from "assets/icons/check-mark.svg";

import s from "../Scenarios.module.scss";

const DetailScenario = observer(() => {
  const { id: _id } = useParams<{ id: string }>();
  const id = Number(_id);

  const history = useHistory();

  const [scenario, setScenarion] = useState<TScenarioDetail | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isSwitching, setIsSwitching] = useState(false);

  const {
    scenariosStore: { getStatsAsync, getCallAsync, editStatus, calls, stats },
    operatorStore: { routes },
    notificationStore: { changeStateSnackbar },
    networksStore: { networks },
  } = useStores();

  const destinationNetwork = networks?.find(
    (network) => network.id === scenario?.destination_network
  );

  const { loading: loadingCalls, list, ids } = calls;
  const { loading: loadingStats, list: listStats } = stats;

  useEffect(() => {
    let isCancelled = false;
    if (id) {
      setIsLoading(true);

      ScenariosApi.getCurrentScenario(id)
        .then(({ data }) => {
          if (!isCancelled) {
            setScenarion(data);
            setIsLoading(false);
          }
        })
        .catch(() => {
          changeStateSnackbar("Something went wrong.Please try again");
        });

      getCallAsync(id);
      getStatsAsync(id);
    }

    return () => {
      isCancelled = true;
    };
  }, [id, changeStateSnackbar, getCallAsync, getStatsAsync]);

  const updateStatus = (status: boolean) => {
    setIsSwitching(true);

    editStatus(id, status)
      .then(() => {
        setScenarion(scenario ? { ...scenario, enabled: status } : null);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.error;
        if (errorMessage) {
          const error = { message: errorMessage };
          changeStateSnackbar(error);
        } else {
          changeStateSnackbar("Something went wrong.Please try again");
        }
      })
      .finally(() => setIsSwitching(false));
  };

  const loadMore = () => {
    getCallAsync(id, 100, false);
  };

  const findRouteElem = useCallback(
    (id: number) => {
      return routes.find((el) => el.id === id);
    },
    [routes]
  );

  const RoutesList = () => {
    return (
      <div className={s.routeGroups}>
        {scenario?.route_group_ids.map((el, index) => {
          const route = findRouteElem(el);
          if (route) {
            return (
              <div key={index} className={s.routeWrapper}>
                <div className={s.nameRoute}>{route.quality}</div>
                <div>{`~${route.routes_count} routes`}</div>
                <div>{`$${route.min_price} - ${route.max_price}`}</div>
              </div>
            );
          } else {
            return <div key={index} />;
          }
        })}
      </div>
    );
  };

  if (isLoading) {
    return <Loader color="inherit" />;
  }

  const getTableRowCalls = (id: number | string): ReactElement => {
    return TSXTableRowCalls(id, list);
  };

  const TitleComponent = () => (
    <>
      <div className={s.back}>
        <Link to={`/${MAIN.TEST_CALLS}`}>
          <IconButton className={s.iconButton}>
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
        </Link>
      </div>
      Scenario
      {scenario && (
        <Switch
          onClick={() => updateStatus(!scenario.enabled)}
          checked={scenario.enabled}
          className={
            scenario?.status === EStatus.finished ? s.disabledSwitch : ""
          }
          disabled={isSwitching}
        />
      )}
    </>
  );

  const InfoComponent = () => (
    <>
      Here you can view information about your scenario for Test Call Generator.
      <br />
      Please contact our support if you have any questions.
    </>
  );

  const idPage = "scenarioPage";

  const clickControl = () => {
    if (scenario?.status === EStatus.finished) {
      changeStateSnackbar("Finished scenario can not be updated");
    } else {
      history.push(`/${MAIN.TEST_CALLS}/scenarios/${id}`);
    }
  };

  return (
    <Page title={<TitleComponent />} idTitle={idPage} info={<InfoComponent />}>
      <div className={s.linkItem}>
        <Button
          variant="contained"
          color="primary"
          className={scenario?.status === EStatus.finished ? s.disabled : ""}
          onClick={clickControl}
        >
          Edit scenario
        </Button>
      </div>

      {loadingStats ? (
        <LinearProgress />
      ) : list.length > 0 ? (
        <Schedule list={listStats} namePage={idPage} />
      ) : (
        <div />
      )}

      {scenario && (
        <div className={s.wrapperInfo}>
          <div className={s.mainInfo}>
            <RowItem name="Name" value={scenario.name} />
            <RowItem name="Status" value={scenario.status} format="status" />
            <RowItem
              name="Scenario start date"
              value={scenario.start_at}
              format="date"
            />
            <RowItem
              name="Scenario end date"
              value={scenario.finish_at}
              format="date"
            />
          </div>
          <div className={s.mainInfo}>
            <RowItem
              name="ACTIVE HOURS (UTC)"
              value={scenario.active_day_hours_display}
            />
            <RowItem
              name="Active days of the week"
              value={scenario.active_week_days_display}
            />
            <RowItem name="Calls Per Hour" value={scenario.calls_per_hour} />
            <RowItem name="Max Calls Per Day" value={scenario.calls_per_day} />
            <RowItem
              name="Max Calls Per Month"
              value={scenario.calls_per_month}
            />
          </div>

          {/* A-Side */}
          {scenario.a_side_type && (
            <div className={`${s.label} ${s.mt32} ${s.mb10}`}>A side</div>
          )}

          {scenario.a_side_type === "numbers" && (
            <FoldableList
              items={scenario.a_numbers.map(({ number, country_iso }) => ({
                value: number,
                country_iso,
              }))}
            />
          )}

          {scenario.a_side_type === "countries" && (
            <FoldableList
              items={scenario.a_countries?.map((country) => ({
                country_iso: country,
                value: COUNTRY_CODES_MAP[country.toUpperCase()] || "ZZ",
              }))}
            />
          )}

          {/* B-Side */}
          {scenario.b_side_type && (
            <div className={`${s.label} ${s.mt32}`}>B side</div>
          )}

          {destinationNetwork && (
            <div
              className={`${s.fontPrimary} ${s.semiBold} ${s.mb10} ${s.mt10}`}
            >
              Destination network: {destinationNetwork.name}
            </div>
          )}

          {scenario.b_side_type === "numbers" && (
            <FoldableList
              items={scenario.b_numbers?.map(({ number, country_iso }) => ({
                value: number,
                country_iso,
              }))}
            />
          )}

          {scenario.b_side_type === "prefixes" && (
            <FoldableList
              items={scenario.prefix_list.map((prefix) => ({
                value: prefix,
                country_iso: destinationNetwork?.country_iso || "",
              }))}
            />
          )}

          {((scenario.b_side_type === "numbers" &&
            scenario.use_b_number_once) ||
            (scenario.b_side_type === "prefixes" &&
              scenario.add_ported_in)) && (
            <div
              className={`${s.fontPrimary} ${s.semiBold} ${s.flex} ${s.gap10} ${s.mt10}`}
            >
              <IconCheckMark width={20} />
              {scenario.b_side_type === "numbers" && "Use number once"}
              {scenario.b_side_type === "prefixes" && "Add ported in numbers"}
            </div>
          )}

          <div className={s.mt32}>
            <RowItem name="Route groups" format="jsx" value={<RoutesList />} />
          </div>

          {!loadingCalls && list.length !== 0 && (
            <div>
              <div>
                <div className={s.titleTableCalls}>Calls</div>
              </div>
              <div className={s.tableWrap}>
                <SimpleTable
                  ids={ids}
                  styleName={s.tableNumbers}
                  tableKeys={TABLE_CALLS_KEYS}
                  getTableRow={getTableRowCalls}
                  getTableHead={getTableHeadCalls}
                />
              </div>
              {list.length === 10 && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={loadMore}
                  endIcon={<ExpandMoreIcon />}
                >
                  Show more
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </Page>
  );
});

export default DetailScenario;
