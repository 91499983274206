import { useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { SvgIcon, Switch, Tooltip } from "@material-ui/core";
import moment from "moment";

import { Tabs, Tab } from "ui";

import { ReactComponent as IconChevroneRight } from "assets/icons/chevron-right.svg";

import Button from "components/shared/button";
import Page from "components/shared/page";
import Loader from "components/shared/loader";
import { useStores } from "store/useStore";
import { getStatusIcon } from "../helper";

import { MAIN, HELPERS } from "utils";

import type { TScenario } from "types";

import s from "../Scenarios.module.scss";

type SortedList = {
  finishedList: TScenario[];
  activeList: TScenario[];
};

type Statuses = "active" | "finished" | "all";

const ListScenarios = observer(() => {
  const [activeTab, setActiveTab] = useState<Statuses>("active");
  const [isLoadingList, setIsLoadingList] = useState<Record<number, boolean>>(
    {}
  );

  const {
    scenariosStore: { getScenariosAsync, editStatus, scenarios },
    userStore: { selectedOperator },
    notificationStore: { changeStateSnackbar },
    networksStore: { networks },
  } = useStores();

  const history = useHistory();

  useLayoutEffect(() => {
    getScenariosAsync();
  }, [selectedOperator, getScenariosAsync]);

  const { loading, list } = scenarios;

  const { finishedList, activeList } = list.reduce<SortedList>(
    (acc, item) => {
      return item.status === "finished"
        ? { ...acc, finishedList: [...acc.finishedList, item] }
        : { ...acc, activeList: [...acc.activeList, item] };
    },
    { finishedList: [], activeList: [] }
  );

  const LISTS_MAP: Record<Statuses, TScenario[]> = {
    active: activeList,
    all: list,
    finished: finishedList,
  };

  const TitleComponent = () => <>Test Call Generator</>;

  const InfoComponent = () => (
    <>
      Here you can add new scenarios for Test Call Generator.
      <br />
      Please Contact our support if you have any questions.
    </>
  );

  const handleTabChange = (event: unknown, newValue: Statuses) => {
    setActiveTab(newValue);
  };

  const handleRowClick = (id: number) => {
    history.push(`${MAIN.TEST_CALLS}/scenarios/${id}/view`);
  };

  return (
    <Page title={<TitleComponent />} info={<InfoComponent />}>
      <>
        <Link
          to={`/${MAIN.TEST_CALLS}/scenarios/${HELPERS.NEW}`}
          className={s.linkBtn}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
          >
            Add test scenario
          </Button>
        </Link>

        {loading ? (
          <div className={s.wrapperLoader}>
            <Loader color="inherit" />
          </div>
        ) : (
          list.length > 0 && (
            <>
              <div className={s.titleTable}>Test scenarios</div>

              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                <Tab
                  label={`Pending & Active (${activeList.length})`}
                  value="active"
                />
                <Tab
                  label={`Finished (${finishedList.length})`}
                  value="finished"
                />
                <Tab label={`All (${list.length})`} value="all" />
              </Tabs>

              <table className={s.table}>
                <thead>
                  <tr>
                    <th>{/* switch */}</th>
                    <th>Name</th>
                    <th>{/* Status */}</th>
                    <th>Time Scope</th>
                    <th>A side</th>
                    <th>B side</th>
                    <th>Route Groups</th>
                    <th>
                      Max Calls (Per <br /> Hour/Day/Month)
                    </th>
                    <th>{/* Arrow */}</th>
                  </tr>
                </thead>

                <tbody className={s.content}>
                  {LISTS_MAP[activeTab].map(
                    ({
                      id,
                      enabled,
                      name,
                      status,
                      start_at,
                      finish_at,
                      active_day_hours_display,
                      active_week_days_display,
                      a_countries,
                      a_numbers_count,
                      a_side_type,
                      destination_network,
                      b_numbers_count,
                      prefix_list,
                      b_side_type,
                      add_ported_in,
                      route_group_ids,
                      use_b_number_once,
                      route_group_labels,
                      calls_per_hour,
                      calls_per_day,
                      calls_per_month,
                    }) => {
                      const destinationNetwork = networks.find(
                        (item) => item.id === destination_network
                      );

                      return (
                        <tr key={id} onClick={() => handleRowClick(id)}>
                          {/* Switch */}
                          <td
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Switch
                              color="primary"
                              className={s.ruleSwitch}
                              onChange={(e) => {
                                setIsLoadingList({
                                  ...isLoadingList,
                                  [id]: true,
                                });

                                const value = e.target.checked;
                                return editStatus(id, value)
                                  .catch((err) => {
                                    const errorMessage =
                                      err?.response?.data?.error;

                                    const errors = Object.entries(
                                      err?.response?.data?.errors || {}
                                    );

                                    if (errorMessage) {
                                      const error = {
                                        message: `${errorMessage} \n ${errors
                                          .map(([key, val]) => ` "${val}" `)
                                          .join("\n")}`,
                                      };
                                      changeStateSnackbar(error);
                                    } else {
                                      changeStateSnackbar(
                                        "Something went wrong.Please try again"
                                      );
                                    }
                                  })
                                  .finally(() =>
                                    setIsLoadingList((prevState) => ({
                                      ...prevState,
                                      [id]: false,
                                    }))
                                  );
                              }}
                              checked={enabled}
                              disabled={isLoadingList[id]}
                            />
                          </td>

                          {/* Name */}
                          <td className={s.nameCell}>{name}</td>

                          {/* Status */}
                          <td>
                            <Tooltip
                              title={
                                status.charAt(0).toUpperCase() + status.slice(1)
                              }
                            >
                              <SvgIcon>{getStatusIcon(status)}</SvgIcon>
                            </Tooltip>
                          </td>

                          {/* Time Scope */}
                          <td>
                            <ul className={s.listInCell}>
                              <li>
                                {`${moment(start_at).format(
                                  "DD.MM.YYYY"
                                )} - ${moment(finish_at).format("DD.MM.YYYY")}`}
                              </li>
                              <li
                                title={
                                  active_day_hours_display.length > 23
                                    ? active_day_hours_display
                                    : ""
                                }
                              >
                                {active_day_hours_display}
                              </li>
                              <li
                                title={
                                  active_week_days_display.length > 23
                                    ? active_week_days_display
                                    : ""
                                }
                              >
                                {active_week_days_display}
                              </li>
                            </ul>
                          </td>

                          {/* A side */}
                          <td>
                            {a_side_type === "numbers" &&
                              `${a_numbers_count} number${
                                a_numbers_count > 1 ? "s" : ""
                              }`}
                            {a_side_type === "countries" &&
                              `${a_countries?.length || 0} countr${
                                a_countries?.length > 1 ? "ies" : "y"
                              }`}
                          </td>

                          {/* B side */}
                          <td>
                            <ul className={s.listInCell}>
                              <li>
                                {destinationNetwork && destinationNetwork.name}
                              </li>
                              <li>
                                {b_side_type === "numbers" &&
                                  `${b_numbers_count || 0} number${
                                    b_numbers_count > 1 ? "s" : ""
                                  }`}
                                {b_side_type === "prefixes" &&
                                  `${prefix_list?.length || 0} prefix${
                                    (prefix_list?.length || 0) > 1 ? "es" : ""
                                  }`}
                              </li>
                              <li
                                className={s.italic}
                                title={
                                  b_side_type === "numbers" && use_b_number_once
                                    ? "Use number once"
                                    : b_side_type === "prefixes" &&
                                      add_ported_in
                                    ? "Add ported-in numbers"
                                    : ""
                                }
                              >
                                {b_side_type === "numbers" &&
                                  use_b_number_once &&
                                  "Use number once"}
                                {b_side_type === "prefixes" &&
                                  add_ported_in &&
                                  "Add ported-in numbers"}
                              </li>
                            </ul>
                          </td>

                          {/* Route Groups */}
                          <td>
                            {route_group_labels
                              .map((item) => {
                                const el =
                                  item.charAt(0).toUpperCase() + item.slice(1);
                                return el;
                              })
                              .join(", ")}
                          </td>

                          {/* Max calls */}
                          <td>
                            {`${calls_per_hour} / ${calls_per_day} / ${calls_per_month}`}
                          </td>

                          {/* Arrow */}
                          <td className={s.arrowCell}>
                            <IconChevroneRight width="24px" />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </>
          )
        )}
      </>
    </Page>
  );
});

export default ListScenarios;
