import React from "react";
import {observer} from "mobx-react";

const CreateNetwork = observer(() => {

  return (
    <div>
      Create networks
    </div>
  )
})

export default CreateNetwork;
