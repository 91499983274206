import React, {FC, useEffect} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../../store/useStore";
import {useIntercom} from "react-use-intercom";


const IntercomWatcher: FC = observer(() => {
  const {
    userStore: {
      user
    }
  } = useStores();
  const {boot, update, hardShutdown, shutdown} = useIntercom();

  useEffect(() => {
    if (user === null) {
      shutdown()
    } else {
      const nameUser: string = `${user?.first_name} ${user?.last_name}`;
      boot({
        name: nameUser,
        email: `${user?.email}`
      })
      update({
        hideDefaultLauncher: false
      })
    }
    return () => {
      hardShutdown()
    }
    // eslint-disable-next-line
  }, [boot, user, shutdown, hardShutdown, update])
  return (
    <></>
  )
})

export default IntercomWatcher;
