import React, {FC} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import * as yup from "yup";
import {Button, MenuItem, TextField} from "@material-ui/core";
import RemoveCircleOutlineSharpIcon from '@material-ui/icons/RemoveCircleOutlineSharp';

import {useStores} from "../../../../../store/useStore";
import {FIELD_REQUIRED} from "../../../../../constants";

import s from '../../forms.module.scss';

const schema = yup.object({
  name: yup.string().required(FIELD_REQUIRED).nullable(),
  prefix: yup.string().required(FIELD_REQUIRED).nullable(),
})

const mockPrefix = ['1', '10', '100', '1000']

const mockNetworks = [
  {
    name: 'Beeline UA',
    code: 'Mobile (255-02)'
  },
  {
    name: 'Beeline KZ',
    code: 'Mobile (255-02)'
  },
  {
    name: 'Beeline BY',
    code: 'Mobile (264-11)'
  }
]

type Props = {
  step: number,
  setStep: (arg: number) => void
}

const BoundNetworks: FC<Props> = observer(({step, setStep}) => {
  const {
    nodesStore: {
      createNodeChangeField,
      createNode,
    },
  } = useStores();

  const formik = useFormik({
    initialValues: {
      name: '',
      prefix: '',
    },
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit: ((values) => {
      let arrayNetworks = [];
      const codeNetwork = mockNetworks.find((x) => x.name === values.name);
      if (createNode.networks.length === 0) {
        arrayNetworks = [{
          ...values,
          code: codeNetwork?.code || '',
          block: 1
        }]
      } else {
        const findValue = createNode.networks.find((el) => el.name === values.name);
        if (findValue) {
          arrayNetworks = createNode.networks.map((el) => {
            if (el.name === values.name) {
              return {
                name: el.name,
                code: codeNetwork?.code || '',
                prefix: String(Number(el.prefix) + Number(values.prefix)),
                block: el.block + 1
              }
            }
            return {...el}
          })
        } else {
          arrayNetworks = [...createNode.networks, ...[{
            ...values,
            code: codeNetwork?.code || '',
            block: 1
          }]]
        }
      }
      createNodeChangeField({
        networks: arrayNetworks
      })
    })
  })

  const deleteNetwork = (name: string) => {
    const newArray = createNode.networks.filter((el) => el.name !== name)
    createNodeChangeField({
      networks: newArray
    })
  }

  const next = () => {
    setStep(step + 1);
  }

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty} = formik;

  return (
    <div>
      <div>
        {createNode.networks.map((el) => (
          <div className={s.rowNetwork}>
            <div className={s.nameNetwork}>
              <div className={s.country}/>
              <div className={s.text}>
                {el.name}
              </div>
            </div>
            <div className={s.codeNetwork}>
              {el.code}
            </div>
            <div className={s.prefixNetwork}>
              {el.prefix} prefixes ({el.block} blocks)
            </div>
            <div className={s.deleteNetwork}>
              <div className={s.delete} onClick={() => deleteNetwork(el.name)}>
                <RemoveCircleOutlineSharpIcon/>
                Detach
              </div>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.formNetworkRow}>
          <TextField
            select
            label="Network"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.name && Boolean(errors.name))}
            helperText={(touched.name && errors.name !== FIELD_REQUIRED && errors.name)}
          >
            {mockNetworks.map((el: { name: string, code: string }, index) => (
              <MenuItem key={`${index}=${el.name}`} value={el.name}>
                {`${el.name} ${el.code}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Prefixes blocks"
            name="prefix"
            value={values.prefix}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.prefix && Boolean(errors.prefix))}
            helperText={(touched.prefix && errors.prefix !== FIELD_REQUIRED && errors.prefix)}
          >
            {mockPrefix.map((el, index) => (
              <MenuItem key={`${index}-${el}`} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={s.buttonAdd}
            disabled={!isValid || !dirty}
          >
            Add network
          </Button>
        </div>
      </form>
      <div>
        <Button
          variant="contained"
          color="primary"
          type="button"
          disabled={createNode.networks.length === 0}
          onClick={next}
        >
          Next
        </Button>
      </div>
    </div>
  )
})


export default BoundNetworks;
