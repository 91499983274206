import React, {FC, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react";
import {MenuItem, TextField} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import {SIGN_UP} from "../../../../utils";
import {useStores} from "../../../../store/useStore";
import {arrayIntegration, getTextIntegration} from "../helper";

import Button from "../../button";

import s from "../forms.module.scss";

const PreferredIntegration: FC = observer(() => {
  let history = useHistory();

  const {
    registrationRequestStore: {
      setDataStorage,
      data,
      isSubmit,
      getFieldValue,
      setFieldValue,
      setNotSaveData
    }
  } = useStores();

  useEffect(() => {
    return () => {
      setNotSaveData()
    };
    // eslint-disable-next-line
  }, []);


  const goToOperatorInfo = () => {
    history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.OPERATOR}`)
  }

  const sendData = () => {
    setDataStorage({integration_type: String(getFieldValue('integration_type'))});
    history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.COMPANY}`);
  }

  const getDisabled = ():boolean => {
    const {integration_type} = data();
    return integration_type === String(getFieldValue('integration_type'));

  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Preferred integration
      </div>
      <div className={s.createSubtitle}>
        If you don’t know yet just select ‘Define later’
      </div>
      <TextField
        select
        label="Integration type"
        name="Integration type"
        value={getFieldValue('integration_type')}
        disabled={isSubmit}
        variant="outlined"
        onChange={(e) => {
          setFieldValue('integration_type', e.target.value)
        }}
      >
        {arrayIntegration.map((el: any, index: any) => (
          <MenuItem key={`${el}-${index}`} value={el}>
            {el}
          </MenuItem>
        ))}
      </TextField>
      <div className={s.more}>
        {getTextIntegration(String(getFieldValue('integration_type')))}
      </div>
      <div className={s.buttons}>
        <Button
          type="button"
          variant="contained"
          color="default"
          onClick={goToOperatorInfo}
          startIcon={<ArrowBackIcon fontSize="small"/>}
        >
          Operator info
        </Button>
        <Button
          endIcon={<ArrowForwardIcon fontSize="small"/>}
          onClick={sendData}
          type="button"
          variant="contained"
          color="primary"
          disabled={getDisabled()}
        >
          Continue
        </Button>
      </div>
    </div>
  )
})

export default PreferredIntegration;
