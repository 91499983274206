import React, {ReactElement} from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import {Icon} from "@material-ui/core";
import copy from "copy-to-clipboard";
import moment from "moment";

import {tickFormatter} from "../../../../utils";
import {
  TNetworksNode,
  TNode,
  TPrefixBlock,
  TStat,
  TTokensNode
} from "../../../../types";
import copyIcon from "assets/icons/copy.svg";

import s from "./ListNodes.module.scss";

export const TABLE_GENERAL_KEYS = ['status', 'host', 'port', 'version', 'activated'];

export const TABLE_EVENT_KEYS = ['connect_event', 'end_event', 'empty', 'link', 'empty_link'];

export const TABLE_NETWORK_KEYS = ['label', 'type', 'prefixes', 'calls', 'link'];

export const TABLE_TOKENS_KEYS = ['token', 'created_at', 'link'];

export const TABLE_GENERAL_HEAD_VALUES = {
  status: 'Status',
  host: 'Host',
  port: 'GPRC port',
  version: 'CR Version',
  activated: 'ACTIVATED ON'
};

export const TABLE_EVENT_HEAD_VALUES = {
  connect_event: 'Connect event',
  end_event: 'End event',
  empty: '',
  link: '',
  empty_link: ''
};

export const TABLE_NETWORK_HEAD_VALUES = {
  label: 'Name',
  type: 'Type',
  prefixes: 'Prefixes',
  calls: 'Validated/total calls',
  link: ''
};

export const TABLE_TOKENS_HEAD_VALUES = {
  token: 'Token',
  created_at: 'CREATED ON',
  link: ''
};

export const getContent = (value: string): string => {
  switch (value) {
    case 'empty':
    case 'link':
    case 'empty_link':
      return '';
    default:
      return value;
  }
}

export const TSXTableRowGeneral = (item: TNode) => {

  const getStatus = (value: string) => {
    switch (value) {
      case 'up':
        return <span className={s.chip} style={{backgroundColor: '#1AAB2A'}}>UP</span>
      case 'down':
        return <span className={s.chip} style={{backgroundColor: '#878787'}}>DOWN</span>
      case 'dry_run':
        return <span className={s.chip} style={{backgroundColor: '#FBA700', marginLeft: '5px'}}>DRY RUN</span>
      case 'timer':
        return <span
          className={s.chip}
          style={{backgroundColor: '#878787', marginLeft: '5px'}}
        >
          LAST HEARTBEAT <br/>
          {moment(item.state.last_heartbeat_at).fromNow()}
        </span>
    }
  }


  return (
    <tr key={item.id} className={s.row}>
      <td>
        <div className={s.rowFlex}>
          {getStatus(item.state.is_active ? 'up' : 'down')}
          {(item.state.is_active && item.state.is_dry_run) && getStatus('dry_run')}
          {!item.state.is_active && getStatus('timer')}
        </div>
      </td>
      <td>
        <div className={s.rowFlex}>
          {item.state.host}
        </div>
      </td>
      <td>
        <div className={s.rowFlex}>
          {item.state.port}
        </div>
      </td>
      <td>
        <div className={s.rowFlex}>
          {item.state.client_version}
        </div>
      </td>
      <td>
        <div className={s.rowFlex}>
          {moment(item.state.last_activated_at).format('DD MMMM YYYY')}
        </div>
      </td>
    </tr>
  )
}

export const TSXTableRowEvent = (item: TNode) => {

  const getValue = (value: boolean) => {
    if (value) {
      return <span style={{color: '#000'}}>Supported</span>
    } else {
      return <span style={{color: '#7C7C7C'}}>Not supported</span>
    }
  }

  return (
    <tr key={item.id} className={s.row}>
      <td>
        <div className={s.rowFlex}>
          {getValue(item.state.connect_event_supported)}
        </div>
      </td>
      <td>
        <div className={s.rowFlex}>
          {getValue(item.state.end_event_not_supported)}
        </div>
      </td>
      <td/>
      <td/>
      <td/>
    </tr>
  )
}

export const TSXTableRowNetwork = (
  id: string | number,
  arr: TNetworksNode[],
  activeStat: TStat | null,
  goToNetwork: (id: number) => void
): ReactElement => {
  const elem = arr.find((el) => el.id === id);

  const getPrefix = (arr: TPrefixBlock[]) => {
    let count = 0;
    arr.forEach((el) => {
      count = count + el.prefix_count
    })
    return count;
  }

  let stat: any = {statByNetwork: []};

  if (activeStat) {
    stat = activeStat;
  }

  const {statByNetwork} = stat;

  const getCalls = (valid?: number, total?: number) => (
    <div>
      <span style={{color: '#000'}}>{valid ? tickFormatter(valid, valid) : ''}</span>
      {valid && total ? ' / ' : ''}
      <span style={{color: '#7C7C7C'}}>{total ? tickFormatter(total, total) : ''}</span>
    </div>
  )


  const getCallsStat = (id: number): ReactElement => {
    const findElem = statByNetwork.find((el: any) => el.id === id);
    if (findElem) {
      return getCalls(findElem.valid, findElem.total)
    }
    return <div/>
  }

  if (elem) {
    return (
      <tr key={elem.id} className={s.row}>
        <td>
          <div className={s.rowFlex}>
            {elem.name}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {elem.type}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {getPrefix(elem.prefix_blocks)}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {getCallsStat(elem.id)}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
              <NavigateNextIcon onClick={() => goToNetwork(elem.id)} style={{cursor: 'pointer'}}/>
            </IconButton>
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}

export const TSXTableRowToken = (
  id: string | number,
  arr: TTokensNode[],
  copyMessage: (str: string) => void
): ReactElement => {
  const elem = arr.find((el) => el.id === id);

  const copyData = (token: string) => {
    copy(token);
    copyMessage('Copy')
  }

  if (elem) {
    return (
      <tr key={elem.id} className={s.row}>
        <td>
          <div className={s.rowFlex}>
            {elem.token}
            <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
              <Icon className={s.copyIcon} onClick={() => copyData(elem.token)}>
                <img src={copyIcon} alt=''/>
              </Icon>
            </IconButton>
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {moment(elem.created_at).format('DD MMMM YYYY')}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
              <MoreVertIcon style={{cursor: 'pointer'}}/>
            </IconButton>
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}

