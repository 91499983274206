import React, {useEffect, useLayoutEffect, useState} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import {MenuItem, TextField} from "@material-ui/core";
import * as yup from "yup";

import Button from "../../shared/button";
import {useStores} from "../../../store/useStore";
import {COUNTRY_CODES_MAP} from "../../../utils";
import {FIELD_REQUIRED} from "../../../constants";

import s from './CompanyInfo.module.scss';

const schema = yup.object({
  company: yup.string().required(FIELD_REQUIRED),
  country: yup.string().required(FIELD_REQUIRED),
  registration_number: yup.string().required(FIELD_REQUIRED),
  city: yup.string().required(FIELD_REQUIRED),
  postal_code: yup.string().required(FIELD_REQUIRED),
  address_line_1: yup.string().required(FIELD_REQUIRED),
  email: yup.string().required(FIELD_REQUIRED),
  phone_number: yup.string().required(FIELD_REQUIRED),
})

const CompanyInfo = observer(() => {

  const [loading, setLoading] = useState<boolean>(false);

  const {
    operatorStore: {
      getCompanyAsync,
      changeCompanyAsync,
      company,
    },
    userStore: {
      selectedOperator,
      user
    },
    notificationStore: {
      changeStateSnackbar
    }
  } = useStores()

  useLayoutEffect(() => {
    getCompanyAsync()
  }, [getCompanyAsync, selectedOperator])


  useEffect(() => {
    if (company) {
      const values = {
        company: company.company,
        country: company.country,
        registration_number: company.registration_number,
        city: company.city,
        postal_code: company.postal_code,
        address_line_1: company.address_line_1,
        email: company.email,
        phone_number: company.phone_number,
      }
      const errors: any = {}
      Object.entries(values).forEach(([key, value]) => {
        if (!value) {
          errors[key] = FIELD_REQUIRED;
        }
      })
      changeRestForm(values, errors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const formik = useFormik({
    initialValues: {
      company: '',
      country: '',
      registration_number: '',
      city: '',
      postal_code: '',
      address_line_1: '',
      email: '',
      phone_number: ''
    },
    validationSchema: schema,
    onSubmit: ((values) => {
      setLoading(true);
      if (company) {
        const userRole = user?.operators.find((el) => el.id === selectedOperator);
        if(userRole) {
          if(userRole.role.permissions.includes('operator/any')) {
            changeCompanyAsync(values)
              .then(() => {
                getCompanyAsync()
                setLoading(false)
              }).catch(() => {
              changeStateSnackbar('Something went wrong.Please try again')
              setLoading(false)
            })
          } else {
            changeStateSnackbar("Not allowed. You don't have permission for this action. Please contact your account administrator.")
            setLoading(false)
          }
        }
      }
    })
  })


  const {values, resetForm, errors, handleSubmit, handleChange, handleBlur, touched, dirty, isValid} = formik;

  const changeRestForm = (changeValues: any, changeErrors: any = {}) => {
    resetForm({
      values: {...changeValues},
      errors: {...changeErrors}
    })
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Company info
      </div>
      <div className={s.info}>
        Please provide up-to-date legal information about your Company.
        This data is needed to generate invoices and future contracts.
      </div>
      <form onSubmit={handleSubmit}>
        <div className={s.subtitle}>
          Billing address
        </div>
        <div>
          <TextField
            name="company"
            label="Organization name"
            placeholder="Organization name"
            value={values.company}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.company && Boolean(errors.company))}
            helperText={(touched.company && errors.company !== FIELD_REQUIRED && errors.company)}
          />
        </div>
        <div className={s.halfWidth}>
          <TextField
            select
            label="Country"
            name="country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.country && Boolean(errors.country))}
            helperText={(touched.country && errors.country !== FIELD_REQUIRED && errors.country)}
          >
            {Object.entries(COUNTRY_CODES_MAP).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="registration_number"
            label="Registration number"
            placeholder="Registration number"
            value={values.registration_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.registration_number && Boolean(errors.registration_number))}
            helperText={(touched.registration_number && errors.registration_number !== FIELD_REQUIRED && errors.registration_number)}
          />
        </div>
        <div className={s.halfWidth}>
          <TextField
            name="city"
            label="City / State"
            placeholder="City / State"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.city && Boolean(errors.city))}
            helperText={(touched.city && errors.city !== FIELD_REQUIRED && errors.city)}
          />
          <TextField
            name="postal_code"
            label="Zip / Postcode"
            placeholder="Zip / Postcode"
            value={values.postal_code}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.postal_code && Boolean(errors.postal_code))}
            helperText={(touched.postal_code && errors.postal_code !== FIELD_REQUIRED && errors.postal_code)}
          />
        </div>
        <div>
          <TextField
            name="address_line_1"
            label="Address line"
            placeholder="Address line"
            value={values.address_line_1}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.address_line_1 && Boolean(errors.address_line_1))}
            helperText={(touched.address_line_1 && errors.address_line_1 !== FIELD_REQUIRED && errors.address_line_1)}
          />
        </div>
        <div className={s.details}>
          Contact details
        </div>
        <div>
          <TextField
            name="email"
            label="Contact email"
            placeholder="Contact email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.email && Boolean(errors.email))}
            helperText={(touched.email && errors.email !== FIELD_REQUIRED && errors.email)}
          />
        </div>
        <div>
          <TextField
            name="phone_number"
            label="Contact phone number"
            placeholder="Contact phone number"
            value={values.phone_number}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.phone_number && Boolean(errors.phone_number))}
            helperText={(touched.phone_number && errors.phone_number !== FIELD_REQUIRED && errors.phone_number)}
          />
        </div>
        <div className={s.submit}>
          <Button
            type="submit" variant="contained" color="primary" loading={loading} disabled={!isValid || !dirty}>
            Save
          </Button>
        </div>
      </form>
    </div>
  )
})

export default CompanyInfo;
