import {makeAutoObservable, runInAction} from "mobx";
import _ from 'lodash';
import moment from 'moment';

import {DashboardAPI} from "../services/DashboardAPI";
import {TStat, TStats, TStatsFormat, TStatsResponse, TStatusCheckbox} from "../types";
import {CHECKBOX_VALID, CHECKBOX_TOTAL, OPERATOR_ID} from "../services/root";

export class StatsStore {
  stats: TStats[] | null = null;
  activeStat: TStat | null = null;
  loading: boolean = true;
  statusCheckbox: TStatusCheckbox = {
    valid: !localStorage.getItem(CHECKBOX_VALID),
    total: !localStorage.getItem(CHECKBOX_TOTAL)
  }

  constructor() {
    makeAutoObservable(this)
  }

  setActiveStat = (id: number) => {
    const findElem = this.stats?.find((el) => el.id === id);
    if (findElem) {
      this.activeStat = findElem.values;
    } else {
      this.activeStat = null;
    }
  }

  checkboxControl = (field: 'valid' | 'total', value: boolean) => {
    const fieldByStore = field === "valid" ? CHECKBOX_VALID : CHECKBOX_TOTAL;
    if (!value) {
      localStorage.setItem(fieldByStore, `${value}`)
    } else {
      localStorage.removeItem(fieldByStore)
    }
    this.statusCheckbox = {
      ...this.statusCheckbox,
      [field]: value
    }
  }

  datesArray = (from: string, to: string, interval: number) => {
    let array = [];
    const fromDate = moment(from, 'YYYY-MM-DD');
    const toDate = moment(to, 'YYYY-MM-DD');
    let date = fromDate.add(interval, 'days');
    while(toDate > date) {
      array.push(date.format('YYYY-MM-DD'));
      date = moment(date).add(interval, 'days');
    }
    return array;
  }

  setStats = (stats: TStatsResponse[]) => {
    const mouthAfter = moment().subtract(1, 'M').subtract('1', 'day').format('YYYY-MM-DD');
    const today = moment().add('1', 'day').format('YYYY-MM-DD');
    const arrayDates: string[] = this.datesArray(mouthAfter, today, 1);
    const formatArray: TStatsFormat[] = [];
    arrayDates.forEach((el) => {
      const findElem = stats.find((stat: any) => stat.date === el);
      if(findElem) {
        formatArray.push(findElem)
      } else {
        formatArray.push({
          date: el,
          domestic_total: 0,
          international_total: 0,
          international_validated: 0,
        })
      }
    })
    const sortStats = _.orderBy(formatArray, (stat) => moment(stat.date).format('YYYY-MM-DD'), ['asc']);
    const counterTotal = sortStats.reduce((prev, curr) => prev + curr.international_total, 0);
    const counterValidated = sortStats.reduce((prev, curr) => prev + curr.international_validated, 0);
    const groupByDate = _.groupBy(sortStats, (stat) => stat.date);
    const groupByNetworkId = _.groupBy(sortStats, (stat) => stat.network_id);
    const groupByNodeId = _.groupBy(sortStats, (stat) => stat.node_id);
    const statDate = Object.entries(groupByDate).map(([key, value]) => {
      const name = moment(key).format('DD/MM');
      const total = value.reduce((prev, curr) => prev + curr.international_total, 0);
      const valid = value.reduce((prev, curr) => prev + curr.international_validated, 0);
      return {date: key, name, total, valid}
    })
    const statByNetwork = Object.entries(groupByNetworkId).map(([id, value]) => {
      const total = value.reduce((prev, curr) => prev + curr.international_total, 0);
      const valid = value.reduce((prev, curr) => prev + curr.international_validated, 0);
      return {id: Number(id), total, valid}
    })
    const statByNode = Object.entries(groupByNodeId).map(([id, value]) => {
      const total = value.reduce((prev, curr) => prev + curr.international_total, 0);
      const valid = value.reduce((prev, curr) => prev + curr.international_validated, 0);
      return {id: Number(id), total, valid}
    })
    const statByNewId = [{
      id: Number(localStorage.getItem(OPERATOR_ID)),
      values: {
        counterTotal: counterTotal,
        counterValidated: counterValidated,
        groupByDate: statDate,
        statByNetwork: statByNetwork,
        statByNode: statByNode,
      }
    }]
    if (this.stats?.length) {
      this.stats = [...this.stats, ...statByNewId];
    } else {
      this.stats = [...statByNewId];
    }
    this.setActiveStat(Number(localStorage.getItem(OPERATOR_ID)))
    this.loading = false;
  }

  getStatsAsync = async () => {
    this.loading = true;
    await DashboardAPI.getStats()
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setStats(data)
        })
      }).catch(() => {
        runInAction(() => {
          this.setStats([])
        })
      })
  }
}
