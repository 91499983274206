import { instance } from "./root";
import { TNumber, TScenario, TScenarioBody, TScenarioDetail } from "../types";

export const ScenariosApi = {
  getScenarios() {
    return instance.get("/api/v1/tcg/scenarios");
  },
  editStatusScenario(id: number | string, status: boolean) {
    return instance.put<TScenario>(`/api/v1/tcg/scenarios/${id}`, {
      enabled: status,
    });
  },
  getCurrentScenario(id: number) {
    return instance.get<TScenarioDetail>(`/api/v1/tcg/scenarios/${id}`);
  },
  getNumberCalls(id: number, page_size: number) {
    return instance.get(
      `/api/v1/tcg/calls?scenario_id=${id}&page=0&page_size=${page_size}`
    );
  },
  getLimits() {
    return instance.get("/api/v1/tcg/limits");
  },
  createScenario(body: TScenarioBody) {
    return instance.post("/api/v1/tcg/scenarios", body);
  },
  updateScenario(id: number | string, body: TScenarioBody) {
    return instance.put(`/api/v1/tcg/scenarios/${id}`, body);
  },
  resolveNumber(number: string | number, networkId?: number | string) {
    return instance.get(
      `/api/v1/tcg/resolve_number?number=${number}${
        networkId ? `&network_id=${networkId}` : ""
      } `
    );
  },
  getStats(id: number) {
    return instance.get(
      `/api/v1/tcg/stats?scenario_id=${id}&window=day&graph=1`
    );
  },

  uploadFile(file: FormData, networkId?: number | string) {
    return instance.post<TNumber[] | null>(
      `/api/v1/tcg/parse_numbers_csv${
        networkId ? `?network_id=${networkId}` : ""
      }`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
};
