import React, {FC, useState} from "react";
import * as yup from "yup";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import {userAPI} from "../../../../services";
import {SIGN_IN} from "../../../../utils";
import {CORPORATE_EMAIL, FIELD_REQUIRED} from "../../../../constants";
import Button from "../../button";

import s from '../forms.module.scss'

//lib check free base emails
const freeEmailDomains = require('free-email-domains');

const MESSAGE = "Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately."

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required(FIELD_REQUIRED).test('test-email', CORPORATE_EMAIL,
    // add ignore, because ts use arrow func, but yup use declared func
    // @ts-ignore
    function (value = '') {
      const getMail = value.split('@');
      const [user, mail] = getMail;
      return !!mail && !!user && !freeEmailDomains.includes(mail);
    }),
})

const Forgot: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      userAPI.restPassword(values.email)
        .then(() => {
          setLoading(false);
          setOpen(true);
        }).catch(() => {
        setLoading(false);
        setOpen(true);
      })
    },
  });

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty} = formik;

  const onChange = (event: any, field: string) => {
    if (serverErrors[field]) {
      const newServerErrors: any = serverErrors;
      delete newServerErrors[field];
      setServerErrors({
        ...newServerErrors
      })
    }
    handleChange(event);
  }

  const goToLogin = () => {
    history.push(`/${SIGN_IN.ROOT}`)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Reset your password
      </div>
      <div className={s.createSubtitle}>
        To receive a link to reset your password, <br/>please enter your email address.
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          name="email"
          value={values.email}
          onChange={(e) => onChange(e, 'email')}
          onBlur={handleBlur}
          placeholder="Registration email"
          label="Registration email"
          error={(touched.email && Boolean(errors.email)) || Boolean(serverErrors.email)}
          helperText={(touched.email && errors.email !== FIELD_REQUIRED && errors.email) || serverErrors.email}
        />
        <div className={`${s.footer} ${s.footerForgot}`}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={goToLogin}
            startIcon={<ArrowBackIcon fontSize="small"/>}
          >
            Go back to login
          </Button>
          <Button
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty}
          >
            Reset password
          </Button>
        </div>
      </form>
      <Dialog
        open={open}
        onClose={closeModal}
      >
        <DialogContent>
          <DialogContentText>
            {MESSAGE}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={closeModal}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default Forgot;
