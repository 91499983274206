import {makeAutoObservable, runInAction} from "mobx";
import {InformationAPI} from "../services";
import {TParticipants} from "../types";

export class InformationStore {
  participants: TParticipants[] = [];
  idsParticipants: string[] | number[] | [] = [];
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  setParticipants = (data: TParticipants[]) => {
    this.idsParticipants = data.map((el) => el.id);
    this.participants = data;
    this.loading = false;
  }

  getParticipantsAsync = () => {
    this.loading = true;
    InformationAPI.getParticipants()
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setParticipants(data)
        })
      })
  }
}
