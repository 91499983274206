import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import logo from "assets/icons/logo_white.svg";

import s from "./Header.module.scss";

const HeaderContainer = observer(() => {
  return (
    <div className={s.wrapper}>
      <Link to="/">
        <div className={s.logo}>
          <img src={logo} alt="logo" />
        </div>
      </Link>

      <Link to="/sign-in">Log in</Link>
    </div>
  );
});

export default HeaderContainer;
