import React, {FC, useLayoutEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {observer} from "mobx-react";

import {userAPI} from "../../../../services";
import {useStores} from "../../../../store/useStore";
import {CONFIRM_EMAIL} from "../../../../services/root";
import {SIGN_UP, UserStatus} from "../../../../utils";
import Button from "../../button";

import s from "../forms.module.scss";

const Confirm: FC = observer(() => {
  let history = useHistory();
  const location = useLocation();

  const {userStore: {setToken, user}} = useStores();

  const search = new URLSearchParams(location.search);
  const verification_token = search.get('token');
  const email = search.get('email');
  const emailStorage = localStorage.getItem(CONFIRM_EMAIL);

  const checkStorageEmail = () => {
    if (emailStorage === null) {
      history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.CREATE}`);
    }
  }

  useLayoutEffect(() => {
    if (email && verification_token) {
      const params = {email: email || search.get('email') || '', verification_token}
      userAPI.verifyEmail(params)
        .then((res) => {
          const {data} = res;
          setToken(data.token);
        }).catch((err) => {
        console.error(err)
      })
    } else {
      checkStorageEmail()
    }
    // eslint-disable-next-line
  }, [history, setToken])

  useLayoutEffect(() => {
    if (user?.status === UserStatus.verified) {
      history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.OPERATOR}`);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Confirm your email
      </div>
      <div className={s.createSubtitle}>
        Check your inbox.<br/>
        Please click on the link to confirm your email.<br/>An email verification link has been sent to
      </div>
      <div className={s.confirmEmail}>
        {email || emailStorage}
      </div>
      <div className={s.wrapperButton}>
        <Button variant="contained" color="primary" disabled>
          Waiting for confirm...
        </Button>
      </div>
    </div>
  )
})

export default Confirm;
