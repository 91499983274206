import React, {FC} from "react";
import {NavLink} from "react-router-dom";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {SIGN_UP} from "../../../utils";

import s from "./SignInStepper.module.scss"

const SignInStepper: FC = () => {

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Not Participant yet?
      </div>
      <div className={s.subtitle}>
        Join our global participant's network of AB Handshake validation system.
      </div>
      <NavLink to={`/${SIGN_UP.ROOT}`} className={s.navLink}>
        <div className={s.text}>
          Create an account
          <ArrowForwardIcon fontSize="small"/>
        </div>
      </NavLink>
    </div>
  )
};

export default SignInStepper;
