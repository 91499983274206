import { WindowInterval } from "../utils";

export enum EStatus {
  pending = "pending",
  running = "running",
  paused = "paused",
  finished = "finished",
}

export enum EStatusCall {
  unknown = "Unknown",
  ok = "Ok",
  domestic_cli = "Domestic CLI",
  spoofed_cli = "Spoofed CLI",
  undelivered = "Undelivered",
  validation_failed = "Validation failed",
  initiation_failed = "Initiation failed",
  route_failed = "Route failed",
}

export type TScenario = {
  id: number;
  created_at: number;
  updated_at: number;
  status: EStatus;
  enabled: boolean;
  name: string;
  start_at: number;
  finish_at: number;
  active_day_hours_utc: number[];
  active_week_days: number[];
  route_group_ids: number[];
  active_day_hours_display: string;
  active_week_days_display: string;
  route_group_labels: string[];
  destination_network?: number;
  b_numbers_count: number;
  prefix_list?: string[];
  add_ported_in?: boolean;
  b_side_type: "numbers" | "prefixes" | "";
  use_b_number_once: boolean;
  calls_per_hour: number;
  calls_per_day: number;
  calls_per_month: number;
  a_numbers_count: number;
  a_countries: string[];
  a_side_type: "numbers" | "countries" | "";
};

export type TScenariosList = {
  loading: boolean;
  list: TScenario[];
  ids: number[];
};

export type TCall = {
  id: number;
  scenario_id: number;
  route_group_id: number;
  route_group_label: string;
  a_number: string;
  b_number: string;
  status: EStatusCall;
  initiated_at: number;
  started_at: number;
  ringing_at: number;
  connected_at: number;
  ended_at: number;
  sip_status: number;
};

export type TCallsList = {
  loading: boolean;
  list: TCall[];
  ids: number[];
};

export type TNumber = {
  number: string;
  country_iso: string;
  is_valid: boolean;
  belongs_to_network: boolean;
  network_id?: number;
};

export type TScenarioDetail = {
  id: number;
  created_at: number;
  updated_at: number;
  status: EStatus;
  enabled: boolean;
  name: string;
  start_at: number;
  finish_at: number;
  active_day_hours_utc: number[];
  active_week_days: number[];
  route_group_ids: number[];
  active_day_hours_display: string;
  active_week_days_display: string;
  route_group_labels: string[];
  calls_per_hour: number;
  calls_per_day: number;
  calls_per_month: number;
  a_side_type: "numbers" | "countries" | "";
  b_side_type: "numbers" | "prefixes" | "";
  a_numbers: TNumber[];
  a_countries: string[] | null;
  b_numbers?: TNumber[];
  prefix_list: string[];
  a_numbers_count: number;
  b_numbers_count: number;
  add_ported_in: boolean;
  use_b_number_once: boolean;
  destination_network: number;
};

export type TScenarioCurrent = {
  loading: boolean;
  item?: TScenarioDetail;
};

export type TLimit = {
  max_calls_per_day: number;
  max_calls_per_hour: number;
  max_calls_per_month: number;
};

export type TLimits = {
  loading: boolean;
  limit: TLimit | null;
};

export type TStatScenario = {
  datetime: number;
  domestic_cli_count: number;
  failed_count: number;
  initiated_count: number;
  ok_count: number;
  scenario_id: number;
  spoofed_cli_count: number;
  undelivered_count: number;
  validation_failed_count: number;
  failed_route_count: number;
  window: WindowInterval;
};

export type TList = {
  datetime: number;
  date: string;
  ok_count: number;
  initiated_count: number;
  domestic_cli_count: number;
  spoofed_cli_count: number;
  undelivered_count: number;
  failed_route_count: number;
};

export type TStatsScenario = {
  loading: boolean;
  list: TList[];
};

export type TScenarioBody = {
  id?: number;
  name: string;
  enabled: boolean;
  start_at: number;
  finish_at: number;
  active_day_hours_utc: number[];
  active_week_days: number[];
  route_group_ids: number[];
  a_numbers: string[];
  b_numbers: string[];
  prefix_list: string[];
  calls_per_hour: number;
  calls_per_day: number;
  calls_per_month: number;
};
