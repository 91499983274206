import { FC } from "react";
import { CircleFlag } from "react-circle-flags";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import { TNumber } from "../../../../types";
import { TNumberValue } from "./Form";

import s from "../Scenarios.module.scss";

type Props = {
  item: Partial<TNumber>;
  removeItem: (item: Partial<TNumber>, type: TNumberValue) => void;
  type: TNumberValue;
};

const NumberItem: FC<Props> = ({ item, removeItem, type }) => {
  return (
    <div className={s.wrapperItemNumber}>
      <div className={s.wrapperNumber}>
        <span className={s.flag}>
          <CircleFlag countryCode={item.country_iso?.toLowerCase() || ""} />
        </span>
      </div>

      <div className={s.numberText}>{item.number}</div>

      <div>
        <IconButton
          className={s.iconButton}
          onClick={() => removeItem(item, type)}
        >
          <CloseIcon className={s.sizeIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default NumberItem;
