import {createContext} from "react";
import {UserStore} from "./UserStore";
import {NotificationStore} from "./NotificationStore";
import {RegistrationRequestStore} from "./RegistrationRequestStore";
import {NodesStore} from "./NodesStore";
import {NetworksStore} from "./NetworksStore";
import {StatsStore} from "./StatsStore";
import {OperatorStore} from "./OperatorStore";
import {InformationStore} from "./InformationStore";
import {ScenariosStore} from "./ScenariosStore";

export const rootStoreContext = createContext({
  userStore: new UserStore(),
  statsStore: new StatsStore(),
  notificationStore: new NotificationStore(),
  registrationRequestStore: new RegistrationRequestStore(),
  nodesStore: new NodesStore(),
  networksStore: new NetworksStore(),
  operatorStore: new OperatorStore(),
  informationStore: new InformationStore(),
  scenariosStore: new ScenariosStore()
});
