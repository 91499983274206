import {makeAutoObservable, runInAction} from "mobx";

import {userAPI} from "../services";
import {TUserResponse} from "../types";
import {
  ACCESS_TOKEN_KEY,
  CONFIRM_EMAIL, OPERATOR_ID,
} from "../services/root";
import * as Sentry from "@sentry/react";

export class UserStore {
  user: null | TUserResponse = null;
  loading: boolean = true;
  token: string = localStorage.getItem(ACCESS_TOKEN_KEY) || '';
  selectedOperator: number | null = null;

  constructor() {
    makeAutoObservable(this)
  }

  get loadingProfile() {
    return this.loading
  }

  get isAuthorized() {
    return this.token !== '';
  }

  clearUserData = () => {
    localStorage.clear()
    this.user = null;
    this.token = '';
  }

  setOperator = (id: number) => {
    this.selectedOperator = id;
    localStorage.setItem(OPERATOR_ID, String(id));
  }

  setUserData = (user: TUserResponse) => {
    localStorage.setItem(CONFIRM_EMAIL, user.email);
    this.user = user;
    Sentry.setUser({email: user.email});
    if (user.operators.length > 0) {
      const id = Number(localStorage.getItem(OPERATOR_ID));
      this.setOperator(id || user.operators[0].id);
    }
    this.loading = false;
  }

  setToken = (token: string) => {
    this.token = token;
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  clearToken = () => {
    this.loading = false;
  }

  logoutUser = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    this.clearUserData();
    Sentry.setUser(null);
    this.loading = false;
  }

  getUserAsync = async () => {
    this.loading = true;
    await userAPI.getDataUser()
      .then((res) => {
        runInAction(() => {
          this.setUserData(res.data)
        })
      }).catch(() => {
        runInAction(() => {
          this.logoutUser()
        })
      })
  }
}
