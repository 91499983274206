import React, {FC} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import {MenuItem, TextField, Button} from "@material-ui/core";
import * as yup from "yup";

import {useStores} from "../../../../../store/useStore";
import {arrayIntegration, getTextIntegration} from "../../helper";
import {FIELD_REQUIRED} from "../../../../../constants";

import s from '../../forms.module.scss'

const schema = yup.object({
  type: yup.string().required(FIELD_REQUIRED).nullable(),
})

type Props = {
  step: number,
  setStep: (arg: number) => void
}

const IntegrationType: FC<Props> = observer(({step, setStep}) => {

  const {
    nodesStore: {
      getCreateNodeFieldValue,
      createNodeChangeField
    }
  } = useStores();

  const formik = useFormik({
    initialValues: {
      type: getCreateNodeFieldValue('type')
    },
    validationSchema: schema,
    onSubmit: ((values) => {
      createNodeChangeField({
        type: String(values.type)
      })
      setStep(step + 1);
    })
  })

  const {handleSubmit, values, handleChange, isValid, errors, touched} = formik;

  return (
    <div>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.createIntegration}>
          <TextField
            select
            label="Integration type"
            name="type"
            value={values.type}
            variant="outlined"
            onChange={handleChange}
            error={(touched.type && Boolean(errors.type))}
            helperText={(touched.type && errors.type !== FIELD_REQUIRED && errors.type)}
          >
            {arrayIntegration.map((el: any, index: any) => (
              <MenuItem key={`${el}-${index}`} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={s.integrationContent}>
          {getTextIntegration(String(values.type))}
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={s.buttonAdd}
            disabled={!isValid}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
})

export default IntegrationType
