import React, {FC, useEffect, useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {useHistory} from "react-router-dom";
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {useIntercom} from "react-use-intercom";

import {COUNTRY_CODES_MAP} from "../../../../utils";
import {FIELD_REQUIRED} from "../../../../constants";
import {useStores} from "../../../../store/useStore";
import {otherAPI} from "../../../../services";
import {SIGN_UP} from "../../../../utils";
import {defaultParams, operatorTypes} from "./helper";
import Button from "../../button";
import {TOperatorTypes} from "../../../../types";
import Loader from "../../loader";

import s from "../forms.module.scss";

const validationSchema = yup.object({
  type: yup.string().required(FIELD_REQUIRED),
  name: yup.string().required(FIELD_REQUIRED),
  country: yup.string().required(FIELD_REQUIRED),
});

const OperatorInfo: FC = () => {
  const [startValid, setStartValid] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [types, setTypes] = useState<Array<any>>([]);
  const [showList, setShowList] = useState<Array<any>>([]);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [disabled, setDisabled] = useState({
    massDisable: false,
    autocomplete: false
  });
  const [show, setShow] = useState({
    showCheckbox: true,
    showForm: false
  });
  const [initialValues, setInitialValues] = useState({
    type: '',
    name: '',
    country: '',
    notFound: false
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [hasOperator, setHasOperator] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);

  let history = useHistory();

  const {trackEvent} = useIntercom();

  const {
    registrationRequestStore: {
      setDataStorage,
      data,
      isSubmit
    },
    notificationStore: {
      changeStateSnackbar
    }
  } = useStores();

  const changeShow = (form: boolean, checkbox: boolean) => {
    setShow({showForm: form, showCheckbox: checkbox})
  }

  const changeDisabled = (mass: boolean, auto: boolean) => {
    setDisabled({massDisable: mass, autocomplete: auto})
  }

  useEffect(() => {
    otherAPI.getOperatorType()
      .then((res) => {
        const newArrayTypes = res.data.map((el: TOperatorTypes) => ({
            id: el.id,
            type: el.type,
            name: el.name,
            country_iso: el.country_iso.toUpperCase(),
            operator_id: el.operator_id
          }
        )).filter((el: TOperatorTypes) => el.type <= 90)
        setTypes([
          ...newArrayTypes,
          {
            id: 0, name: "Other",
          }
        ])
      })
      .catch((err) => {
        changeStateSnackbar(err)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (types.length > 0) {
      const dataStorage = data();
      const networkCountry = dataStorage ? dataStorage.network_country : null;
      const networkName = dataStorage ? dataStorage.network_name : null;
      const networkType = dataStorage ? dataStorage.network_type : null;
      const networkId = dataStorage ? dataStorage.requested_network_id : null;
      if (networkId && networkType && networkName && networkCountry) {
        const findElem = types.find((el) => el.id === Number(networkId))
        setSelectedValue(findElem);
        const type = operatorTypes.find((el) => el.displayName === networkType)
        const updateValues = {
          name: networkId === '0' ? networkName : findElem.name,
          type: type?.id !== undefined ? String(type.id) : '',
          country: networkCountry,
          notFound: networkId === '0'
        }
        changeRestForm(updateValues)
        setInitialValues({
          ...updateValues
        })
        if (networkId === '0') {
          changeDisabled(false, true)
          changeShow(true, true)
        } else {
          changeDisabled(true, false);
          changeShow(true, false)
        }
      }
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [types.length])

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: () => {
      sendData();
      history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.INTEGRATION}`);
    },
  });

  const sendData = (updateData = {}) => {
    const idType = operatorTypes.find((el) => el.id === Number(values?.type))
    const networkId = types.find((el) => el.name === values.name);
    const params = {
      network_type: idType ? idType.displayName : 'Other',
      network_name: values.name,
      requested_network_id: networkId ? String(networkId.id) : String(0),
      network_country: values.country,
      ...updateData
    }
    setDataStorage(params);
    trackEvent('reg_operator_info', {
      networkName: params.network_name,
      networkType: params.network_type,
      networkCountry: params.network_country
    })
  }

  const searchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value) {
      const results = types.filter((el) => el.name.toLowerCase().substring(0, value.length) === value.toLowerCase());
      setShowList(results);
    } else {
      setShowList([])
    }
    setSearch(value);
  }

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, setFieldValue, resetForm} = formik;

  const changeRestForm = (changeValues: any, changeErrors: any = {}) => {
    resetForm({
      values: {...changeValues},
      errors: {...changeErrors}
    })
  }

  const onChange = (e: any, value: any) => {
    setStartValid(false);
    if (value === null) {
      changeShow(false, true);
      setFieldValue('type', '');
      setFieldValue('country', '');
      setFieldValue('name', '');
    }
    if (value?.name && value?.name !== 'Other') {
      changeDisabled(true, disabled.autocomplete);
      changeShow(true, false);
      changeRestForm({type: value?.type, name: value?.name, country: value?.country_iso, notFound: false})
    }
    if (value?.name && value?.name === 'Other') {
      changeDisabled(false, disabled.autocomplete);
      changeShow(true, true);
      changeRestForm({type: '', name: '', country: '', notFound: true}, {type: FIELD_REQUIRED})
    }
    if (value?.operator_id && value?.operator_id > 0) {
      setHasOperator(true);
    } else {
      setHasOperator(false);
    }
    setSelectedValue(value);
    const array = types.filter((el) => el.name === value?.name);
    setShowList(array)
  }

  const sendHasOperatorInfo = () => {
    const networkId = types.find((el) => el.name === values.name);
    history.push(`/${SIGN_UP.ROOT}/${SIGN_UP.ACTIVATION}`);
    otherAPI.sendRequestAccess(networkId ? String(networkId.id) : String(0))
      .then(() => {
        sendData(defaultParams);
      }).catch(() => {
      changeStateSnackbar('something went wrong please try again later')
    })
  }

  const useCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartValid(false);
    setFieldValue('notFound', !values.notFound)
    changeDisabled(disabled.massDisable, !values.notFound);
    if (e.target.checked) {
      const selected = types.find((el) => el.name === 'Other');
      setSelectedValue(selected);
      changeRestForm({type: '', name: '', country: '', notFound: e.target.checked}, {type: FIELD_REQUIRED})
      changeShow(true, true);
      changeDisabled(false, true);
    } else {
      changeRestForm({type: '', name: '', country: '', notFound: e.target.checked}, {type: FIELD_REQUIRED})
      setSelectedValue(null);
      changeShow(false, true);
      changeDisabled(true, false);
    }
  }

  const getClassName = () => {
    const {showCheckbox, showForm} = show;
    if (!showCheckbox && showForm) return s.empty
    if (showCheckbox && !showForm) return s.hasData
    if (showCheckbox && showForm) return s.other
  }

  const showDialog = () => {
    setDialog(!dialog)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Operator info
      </div>
      <div className={s.createSubtitle}>
        Please select your network from the list below or provide details manually. If you operate several networks
        choose any to start integration.
      </div>
      {loading ?
        <div className={s.loader}><Loader color="inherit"/></div> :
        <form onSubmit={handleSubmit} className={s.formOperator}>
          <Autocomplete
            options={showList}
            getOptionLabel={(option) => option.country_iso ? `${option.name} (${option.country_iso})` : `${option.name}`}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={onChange}
            disabled={disabled.autocomplete || isSubmit}
            value={selectedValue}
            freeSolo={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Your network / operator"
                placeholder="Find your operator by name"
                variant="outlined"
                onChange={searchValue}
                value={search}
                helperText={(touched.name && errors.name !== FIELD_REQUIRED && errors.name)}
              />
            )}
          />
          {hasOperator && <div className={s.errorHasOperator}>
            This operator is already registered as an ABHandshake participant. You can request access to manage this
            operator.
          </div>}
          {show.showCheckbox && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.notFound}
                  onChange={useCheckbox}
                  disabled={isSubmit}
                  name="notFound"
                  color="default"
                />
              }
              label="Network not found, will be defined later"
            />
          )}
          {show.showForm && (
            <>
              <TextField
                select
                label="Operator type"
                name="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                disabled={disabled.massDisable || isSubmit}
                error={(touched.type && Boolean(errors.type))}
                helperText={(touched.type && errors.type !== FIELD_REQUIRED && errors.type)}
              >
                {operatorTypes.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                disabled={disabled.massDisable || isSubmit}
                error={(touched.country && Boolean(errors.country))}
                helperText={(touched.country && errors.country !== FIELD_REQUIRED && errors.country)}
              >
                {Object.entries(COUNTRY_CODES_MAP).map(([code, name]) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              {!disabled.massDisable && (
                <TextField
                  label="Network / operator name"
                  name="name"
                  placeholder="Network / operator name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  disabled={isSubmit}
                  error={(touched.name && Boolean(errors.name))}
                  helperText={(touched.name && errors.name !== FIELD_REQUIRED && errors.name)}
                />
              )}
            </>
          )}
          <div className={`${s.buttons} ${getClassName()}`}>
            <div/>
            {hasOperator ?
              <Button
                onClick={showDialog}
                type="button"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon fontSize="small"/>}
              >
                Request access
              </Button> :
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || startValid}
                endIcon={<ArrowForwardIcon fontSize="small"/>}
              >
                Continue
              </Button>
            }
          </div>
        </form>
      }
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
      >
        <DialogTitle>
          Request access
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Our manager will contact you to confirm access.<br/>
            Are you ready to send the request?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={s.dialogButtons}>
          <Button
            type="button"
            variant="contained"
            color="default"
            onClick={() => setDialog(false)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={sendHasOperatorInfo}
          >
            Send request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OperatorInfo;
