import React, {FC} from "react";
import {
  Switch as SwitchRoute,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";

import SignInStepper from "./SignInStepper";
import LogIn from "../shared/forms/logIn";
import Forgot from "../shared/forms/forgot";
import {SIGN_IN} from "../../utils";

import s from "./SignIn.module.scss";

const SignIn: FC = () => {
  const {path} = useRouteMatch();

  return (
    <div className={s.wrapper}>
      <SignInStepper/>
      <div className={s.general}>
        <div className={s.wrapperForms}>
          <SwitchRoute>
            <Route path={path} exact component={LogIn}/>
            <Route path={`${path}/${SIGN_IN.FORGOT}`} component={Forgot}/>
            <Redirect from='*' to={path}/>
          </SwitchRoute>
        </div>
      </div>
    </div>
  )
};

export default SignIn;
