export const defaultCreateNode = {
  name: '',
  networks: [],
  type: '',
  certificate: {
    name: '',
    country: '',
    organization: '',
    units: '',
    ip: ''
  },
  host_system: '',
  //mock!!!
  install_script: 'CR_TOKEN="ff2o0Wmqc1Nmqi91h" bash -c "$(curl -L https://portal.abhandhsake.com/scripts/cr_install.sh)"'
}

export const defaultRegistration = {
  submitted: false,
  network_type: '',
  network_country: '',
  network_name: '',
  requested_network_id: '',
  integration_type: '',
  organization_name: '',
  organization_registration_number: '',
  organization_country: '',
  organization_city: '',
  organization_zip_code: '',
  organization_address: ''
}
