import React, {FC, useEffect, useState} from "react";
import {
  Switch as SwitchRoute,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import {observer} from "mobx-react";

import SignUpStepper from "./signUpStepper";
import CreateAccount from "../shared/forms/createAccount";
import Confirm from "../shared/forms/confirm";
import OperatorInfo from "../shared/forms/operatorInfo";
import PreferredIntegration from "../shared/forms/preferredIntegration";
import Activation from "../shared/forms/activation";
import CompanyInfo from "../shared/forms/companyInfo";
import {SIGN_UP, UserStatus} from "../../utils";
import {StateRoutes, defaultParams} from "./utils";
import {useStores} from "../../store/useStore";
import {useHookRoute} from "../../utils/useHookRoute";

import s from "./SignUp.module.scss";

const SignUp: FC = observer(() => {
  const {path} = useRouteMatch();
  const {pathname} = useLocation();

  const {
    userStore: {
      isAuthorized,
      user
    },
    registrationRequestStore: {
      data,
      setDataStorage,
      setFieldValue
    }
  } = useStores();

  //need use state, because in useEffect we change default path
  const [defaultPath, setDefaultPath] = useState<string>(`${path}/${SIGN_UP.CREATE}`);

  const {network_name, integration_type, organization_name, submitted} = data();

  const getPathRedirect = (field: string): string => {
    const pathOperator = `${path}/${SIGN_UP.OPERATOR}`;
    const pathIntegration = `${path}/${SIGN_UP.INTEGRATION}`;
    const pathCompany = `${path}/${SIGN_UP.COMPANY}`;
    const pathActivation = `${path}/${SIGN_UP.ACTIVATION}`;
    switch (field) {
      case StateRoutes.integration: {
        if (network_name === '') return pathOperator;
        if (submitted) return pathActivation;
        return pathIntegration;
      }
      case StateRoutes.company: {
        if (network_name === '') return pathOperator;
        if (integration_type === '') return pathIntegration;
        if (submitted) return pathActivation;
        return pathCompany
      }
      case StateRoutes.activation: {
        if (network_name === '') return pathOperator;
        if (integration_type === '') return pathIntegration;
        if (organization_name === '') return pathCompany;
        return pathActivation
      }
      default:
        return defaultPath;
    }
  }

  // change default path in SIGN-UP route, if request submitted
  useEffect(() => {
    if (submitted) {
      setDefaultPath(`${path}/${SIGN_UP.ACTIVATION}`)
    }
  }, [path, submitted])

  const getStatusUser = () => user?.status;

  useEffect(() => {
    if (user?.application_id && user?.application_id > 0) {
      setDataStorage(defaultParams);
      setFieldValue('submitted', true);
    }
  }, [setDataStorage, setFieldValue, user?.application_id])

  const getDefaultPath = () => {
    if (submitted) {
      return `${path}/${SIGN_UP.ACTIVATION}`;
    }
    return defaultPath;
  }

  const getPath = (): string => {
    const status = getStatusUser();
    switch (status) {
      case UserStatus.verified:
        return `${path}/${SIGN_UP.OPERATOR}`;
      case UserStatus.new:
        return `${path}/${SIGN_UP.CONFIRM}`;
      default:
        return `${path}/${SIGN_UP.CREATE}`
    }
  }

  return (
    <div className={s.wrapper}>
      <SignUpStepper path={path} pathname={pathname}/>
      <div className={s.general}>
        <div className={s.wrapperForms}>
          <SwitchRoute>
            <Route
              path={`${path}/${SIGN_UP.CREATE}`}
              render={() => (
                useHookRoute({
                  component: CreateAccount,
                  condition: true,
                  sideCondition: isAuthorized,
                  pathRedirect: getPath(),
                  defaultPath: defaultPath
                })
              )}
            />
            <Route
              path={`${path}/${SIGN_UP.CONFIRM}`}
              render={() => (
                useHookRoute({
                  component: Confirm,
                  condition: true,
                  defaultPath: defaultPath
                }))}
            />
            <Route
              path={`${path}/${SIGN_UP.OPERATOR}`}
              render={() => (
                useHookRoute({
                  component: OperatorInfo,
                  condition: isAuthorized && getStatusUser() === UserStatus.verified && !submitted,
                  defaultPath: defaultPath,
                })
              )}
            />
            <Route
              path={`${path}/${SIGN_UP.INTEGRATION}`}
              render={() => (
                useHookRoute({
                  component: PreferredIntegration,
                  condition: isAuthorized && getStatusUser() === UserStatus.verified && !submitted,
                  sideCondition: network_name === '',
                  pathRedirect: getPathRedirect(StateRoutes.integration),
                  defaultPath: defaultPath
                })
              )}
            />
            <Route
              path={`${path}/${SIGN_UP.COMPANY}`}
              render={() => (
                useHookRoute({
                  component: CompanyInfo,
                  condition: isAuthorized && getStatusUser() === UserStatus.verified && !submitted,
                  sideCondition: network_name === '' || integration_type === '',
                  pathRedirect: getPathRedirect(StateRoutes.company),
                  defaultPath: defaultPath
                })
              )}
            />
            <Route
              path={`${path}/${SIGN_UP.ACTIVATION}`}
              render={() => (
                useHookRoute({
                  component: Activation,
                  condition: isAuthorized && getStatusUser() === UserStatus.verified,
                  sideCondition: network_name === '' || integration_type === '' || organization_name === '',
                  pathRedirect: getPathRedirect(StateRoutes.activation),
                  defaultPath: getDefaultPath(),
                })
              )}
            />
            <Redirect from='*' to={`${path}/${SIGN_UP.CREATE}`}/>
          </SwitchRoute>
        </div>
      </div>
    </div>
  )
});

export default SignUp;
