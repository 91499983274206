import { ReactElement } from "react";
import moment from "moment";

import { ReactComponent as FinishIcon } from "assets/icons/finish.svg";
import { ReactComponent as PauseIcon } from "assets/icons/pause.svg";
import { ReactComponent as PendingIcon } from "assets/icons/pending.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";

import { EStatus, EStatusCall, TCall } from "../../../types";

import s from "./Scenarios.module.scss";

export const TABLE_CALLS_KEYS = [
  "a_number",
  "b_number",
  "call",
  "route",
  "sip_status",
  "status",
];

export const getContent = (value: string): string | ReactElement => {
  switch (value) {
    case "empty":
    case "link":
    case "status":
      return "";
    case "Active days of the week": {
      return (
        <>
          Active days <br /> of the week
        </>
      );
    }
    case "Max calls (per hour/day/month)": {
      return (
        <>
          Max calls (per <br /> hour/day/month)
        </>
      );
    }
    default:
      return value;
  }
};

export const getStatusIcon = (status: EStatus) => {
  switch (status) {
    case EStatus.finished:
      return <FinishIcon />;
    case EStatus.paused:
      return <PauseIcon />;
    case EStatus.pending:
      return <PendingIcon />;
    case EStatus.running:
      return <PlayIcon />;
  }
};

export const TABLE_SCENARIO_HEAD_VALUES = {
  id: "empty",
  name: "name",
  status: "status",
  dates: "dates",
  hours: "Active hours",
  days: "Active days of the week",
  a_numbers: "A numbers",
  b_numbers: "B numbers",
  groups: "Route groups",
  calls: "Max calls (per hour/day/month)",
  link: "",
};

export const TABLE_CALLS_HEAD_VALUES = {
  a_number: "A number",
  b_number: "B number",
  call: "Call origination time (UTC)",
  route: "Route group",
  sip_status: "sip status",
  status: "STATUS",
  link: "",
};

export const getContentCalls = (value: string): string | ReactElement => {
  switch (value) {
    case "empty":
    case "link":
    case "status":
      return "";
    case "Call origination time (UTC)": {
      return (
        <>
          Call <br /> origination time (UTC)
        </>
      );
    }
    case "Route group": {
      return (
        <>
          Route <br />
          group
        </>
      );
    }
    default:
      return value;
  }
};

export const getTableHeadCalls = (
  el: keyof typeof TABLE_CALLS_HEAD_VALUES
): string | ReactElement => {
  return getContentCalls(TABLE_CALLS_HEAD_VALUES[el]);
};

export const getStatusColor = (status: EStatusCall) => {
  switch (status) {
    case EStatusCall.ok:
      return <div className={s.green} />;
    case EStatusCall.domestic_cli:
      return <div className={s.red} />;
    case EStatusCall.spoofed_cli:
      return <div className={s.yellow} />;
    case EStatusCall.undelivered:
      return <div className={s.blue} />;
    case EStatusCall.route_failed:
      return <div className={s.blackCircle} />;
    case EStatusCall.initiation_failed:
    case EStatusCall.validation_failed:
    case EStatusCall.unknown:
      return <div className={s.gray} />;
  }
};

export const TSXTableRowCalls = (id: number | string, list: TCall[]) => {
  const item = list.find((el) => el.id === id);
  if (item) {
    return (
      <tr key={`table-item${item.id}`} className={s.row}>
        <td>
          <div className={`${s.rowFlex} ${s.black}`}>{item.a_number}</div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.black}`}>{item.b_number}</div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {moment(item.started_at).format("YYYY-MM-DD HH:mm:ss")}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.upper}`}>
            {item.route_group_label}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>{item.sip_status}</div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.upper}`}>
            <div className={s.circle}>{getStatusColor(item.status)}</div>
            {item.status}
          </div>
        </td>
      </tr>
    );
  }
  return <tr key={id} />;
};
