import { withStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";

export const Tabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(MuiTabs);

type TabProps = {
  label: string;
  value: string;
};
export const Tab = withStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontSize: "16px",
    fontWeight: 800,
    color: "#2F3037",

    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#0176FE",
    },
  },
  selected: {},
}))((props: TabProps) => <MuiTab disableRipple {...props} />);
