import React, {FC} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import * as yup from "yup";

import {useStores} from "../../../../../store/useStore";
import {FIELD_REQUIRED} from "../../../../../constants";
import {TCertificate} from "../../../../../types";

import s from '../../forms.module.scss';
import {Button, MenuItem, TextField} from "@material-ui/core";
import {COUNTRY_CODES_MAP} from "../../../../../utils";

const schema = yup.object({
  name: yup.string().required(FIELD_REQUIRED).nullable(),
  country: yup.string().required(FIELD_REQUIRED).nullable(),
  organization: yup.string().required(FIELD_REQUIRED).nullable(),
  unit: yup.string().required(FIELD_REQUIRED).nullable(),
  ip: yup.string().required(FIELD_REQUIRED).nullable(),
})

type Props = {
  step: number,
  setStep: (arg: number) => void
}

const CSR: FC<Props> = observer(({step, setStep}) => {

  const {
    nodesStore: {
      createNodeChangeField,
      createNode,
    }
  } = useStores();

  const storeCertificate: TCertificate = createNode.certificate;

  const formik = useFormik({
    initialValues: {
      name: storeCertificate.name,
      country: storeCertificate.country,
      organization: storeCertificate.organization,
      unit: storeCertificate.units,
      ip: storeCertificate.ip
    },
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit: ((values) => {
      createNodeChangeField({
        certificate: {
          name: values.name,
          country: values.country,
          organization: values.organization,
          units: values.unit,
          ip: values.ip
        }
      })
      setStep(step + 1);
    })
  })

  const {handleSubmit, values, isValid, handleBlur, handleChange, errors, touched} = formik;

  return (
    <div>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.csrRow}>
          <TextField
            value={values.name}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            variant="outlined"
            label="Common name"
            placeholder="Common name"
            error={(touched.name && Boolean(errors.name))}
            helperText={(touched.name && errors.name !== FIELD_REQUIRED && errors.name)}
          />
          <TextField
            select
            label="Country"
            name="country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.country && Boolean(errors.country))}
            helperText={(touched.country && errors.country !== FIELD_REQUIRED && errors.country)}
          >
            {Object.entries(COUNTRY_CODES_MAP).map(([code, name]) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={s.csrRow}>
          <TextField
            value={values.organization}
            name="organization"
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            variant="outlined"
            label="Organization"
            placeholder="Organization"
            error={(touched.organization && Boolean(errors.organization))}
            helperText={(touched.organization && errors.organization !== FIELD_REQUIRED && errors.organization)}
          />
          <TextField
            value={values.unit}
            name="unit"
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            variant="outlined"
            label="Organization Unit"
            placeholder="Organization Unit"
            error={(touched.unit && Boolean(errors.unit))}
            helperText={(touched.unit && errors.unit !== FIELD_REQUIRED && errors.unit)}
          />
        </div>
        <div className={s.csrRowAll}>
          <TextField
            value={values.ip}
            name="ip"
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            variant="outlined"
            label="Host IP address"
            placeholder="Host IP address"
            error={(touched.ip && Boolean(errors.ip))}
            helperText={(touched.ip && errors.ip !== FIELD_REQUIRED && errors.ip)}
          />
        </div>
        <div className={s.footerText}>
          Provide information for creating CSR, we will generate TLS certificate automatically for your node instance.<br/>
          Specify the correct IP address of the host machine where you will install the CR agent.
        </div>
        <div className={s.footer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
})

export default CSR;
