import React, {FC} from "react";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";

import {pathSignUp} from "../utils";
import {SIGN_UP, UserStatus} from "../../../utils";
import {useStores} from "../../../store/useStore";

import s from "./SignUpStepper.module.scss"

type Props = {
  path: string,
  pathname: string
};

const SignUpStepper: FC<Props> = observer(({path, pathname}) => {
  const {userStore: {user}} = useStores();
  const [str] = pathname.split('/').filter((el) => el !== '' && el !== SIGN_UP.ROOT);

  const getActive = (url: string, index: number): string => {
    const indexElement = pathSignUp.map((el) => el.path).indexOf(str);
    if (indexElement >= index) {
      return s.activeWrapperLinks;
    }
    return ''
  }

  const getDisabled = (path: string) => {
    if (path === `${SIGN_UP.CREATE}` || path === `${SIGN_UP.CONFIRM}`) {
      if (user?.status === UserStatus.verified) {
        return s.disable
      }
    }
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Participant registration
      </div>
      <div className={s.subtitle}>
        Join our global participant's network of AB Handshake validation system.
      </div>
      {pathSignUp.map((el, index) => {
        return (
          <div key={index} className={`${s.wrapperLinks} ${getActive(el.path, index)}`}>
            {index !== 0 && (
              <div className={s.lineContainer}>
                <div className={s.line}/>
              </div>
            )}
            <NavLink to={`${path}/${el.path}`} className={`${s.navLink} ${getDisabled(el.path)}`}
                     activeClassName={s.active}>
              <div className={s.dotContainer}>
                <div className={s.dot}/>
              </div>
              <div className={s.text}>{el.text}</div>
            </NavLink>
          </div>
        )
      })}
    </div>
  )
});

export default SignUpStepper;
