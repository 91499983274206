import React, {ReactElement} from "react";
import Flag from "react-flagkit";

import {TParticipants} from "../../../types";
import {tickFormatter} from "../../../utils";

import s from "./Participants.module.scss";

export const TABLE_PARTICIPANTS_KEYS = ['network', 'country', 'type', 'mccmc', 'prefixes', 'status'];

export const TABLE_PARTICIPANTS_HEAD_VALUES = {
  network: 'Network',
  country: 'Country',
  type: 'Type',
  mccmc: 'MCCMNC',
  prefixes: 'Prefixes',
  status: 'status'
};

export const getContent = (value: string): string => {
  switch (value) {
    case 'empty':
    case 'link':
      return '';
    default:
      return value;
  }
}

export const TSXTableRowParticipants = (id: string | number, arr: TParticipants[]): ReactElement => {
  const elem = arr.find((el) => el.id === id);
  if (elem) {
    const getStatus = () => {
      switch (elem.participation_type.toLowerCase()) {
        case 'full':
          return <span className={s.chip} style={{backgroundColor: '#1AAB2A'}}>Participant</span>
        case 'in progress':
          return <span className={s.chip} style={{backgroundColor: '#0176FE'}}>In progress</span>
        case 'partial':
          return <span className={s.chip} style={{backgroundColor: '#1AAB2A'}}>Partial</span>
        default:
          return <span className={s.chip} style={{backgroundColor: '#878787'}}>Unknown</span>
      }
    }
    return (
      <tr key={elem.id} className={s.row}>
        <td>
          <div className={`${s.rowFlex} ${s.name}`}>
            <div className={s.flag}>
              {elem.country_iso === "ZZ" ? <div className={s.greyCircle}/> : <Flag country={elem.country_iso}/>}
             </div>
            <div>
              {elem.name}
            </div>
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {elem.country_iso}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {elem.type}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.grey}`}>
            {elem.mcc_mnc || '-'}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {elem.prefix_count !== 0 ? tickFormatter(elem.prefix_count, elem.prefix_count) : '-'}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {getStatus()}
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}
