import { useState } from "react";
import { CircleFlag } from "react-circle-flags";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "../../../shared/button";
import RowItem from "./RowItem";

import s from "../Scenarios.module.scss";

type Item = {
  value: string;
  country_iso: string;
};

type FlagItemProps = {
  item: Item;
};

const FlagItem = ({ item }: FlagItemProps) => (
  <div className={s.wrapperNumber}>
    <span className={s.flag}>
      <CircleFlag countryCode={item.country_iso.toLowerCase()} />
    </span>
    <span>{item.value}</span>
  </div>
);

type Props = {
  items?: Item[];
  title?: string;
};

export const FoldableList = ({ items, title }: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const setShowItems = () => {
    setShow(true);
  };

  return !items ? null : (
    <>
      <div className={`${s.mainInfo} ${s.m0} ${s.rowGap25}`}>
        {items.slice(0, show ? items.length : 5).map((el, index) => {
          return (
            <RowItem
              name={title}
              value={<FlagItem item={el} />}
              format="jsx"
              hide={index !== 0}
              key={index}
            />
          );
        })}
      </div>

      {!show && items.length > 5 && (
        <div className={`${s.footer} ${s.mb0}`}>
          <Button
            color="primary"
            variant="text"
            onClick={setShowItems}
            endIcon={<ExpandMoreIcon />}
          >
            Show more
          </Button>
          <span>
            {`Showing ${items.slice(0, 5).length} of ${items.length} numbers`}
          </span>
        </div>
      )}
    </>
  );
};
