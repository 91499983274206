import React, {FC, memo} from "react";
import {Button as MuiButton, ButtonProps, CircularProgress} from "@material-ui/core";

import s from './Button.module.scss'

interface IProps extends ButtonProps {
  loading?: boolean
}

//this func required. need delete errors, when material-ui not used
const removeErrors = (attr: IProps) => {
  let newProps = Object.assign({}, attr);
  if(newProps.hasOwnProperty('loading')) {
    delete newProps['loading']
  }
  return newProps
}

const Button: FC<IProps> = memo((props) => {
  return (
    <MuiButton
      className={`${s.button} ${props.loading ? s.transparent : s.inherit}`}
      {...removeErrors(props)}
    >
      {props.children}
      <div className={`${s.loader} ${props.loading ? s.visible : s.hidden}`}>
        <CircularProgress color="primary" size={28}/>
      </div>
    </MuiButton>
  )
});

export default Button;
