import React, {ReactElement, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Stepper, Step, StepLabel, StepContent} from "@material-ui/core";

import {useStores} from "../../../../store/useStore";
import {MAIN} from "../../../../utils";

import NodeName from "../../../shared/forms/createNodes/nodeName";
import BoundNetworks from "../../../shared/forms/createNodes/boundNetworks";
import IntegrationType from "../../../shared/forms/createNodes/integrationType";
import CSR from "../../../shared/forms/createNodes/CSR";
import Install from "../../../shared/forms/createNodes/intstall";

import s from './CreateNode.module.scss'

const CreateNode = observer(() => {

  const {
    nodesStore: {
      createNode
    }
  } = useStores();

  const getSteps = () => {
    return ['Node name', 'Bound networks', 'Integration type', 'Certificate Signing Request (CSR)', 'Install CR agent']
  }

  const [step, setStep] = useState<number>(0);
  const steps = getSteps();

  const goToStep = (idx: number): void => {
    setStep(idx);
  }

  useEffect(() => {
    //needed. because material-ui stepper logger errors (content show, when step non active)
    console.clear();
  }, [step])


  const getContent = (idx: number) => {
    switch (idx) {
      case 0:
        return <NodeName step={step} setStep={setStep}/>;
      case 1:
        return <BoundNetworks step={step} setStep={setStep}/>;
      case 2:
        return <IntegrationType step={step} setStep={setStep}/>;
      case 3:
        return <CSR step={step} setStep={setStep}/>;
      case 4:
        return <Install/>;
      default:
        return <NodeName step={step} setStep={setStep}/>
    }
  }

  const getStopEvents = (idx: number): string => {
    switch (idx) {
      case 0:
        return '';
      case 1:
        if (createNode.name === '') {
          return s.disabled
        }
        return s.active;
      case 2:
        if (createNode.networks.length === 0) {
          return s.disabled;
        }
        return s.active;
      case 3:
        if (createNode.type === '') {
          return s.disabled;
        }
        return s.active;
      case 4:
        if (createNode.certificate.name === '') {
          return s.disabled;
        }
        return s.active;
      default:
        return s.disabled;
    }
  }


  const getLabelContent = (idx: number): string | null | ReactElement => {
    if (step === idx) {
      return null
    }
    switch (idx) {
      case 0:
        if (createNode.name !== '') {
          return createNode.name
        }
        return null;
      case 1:
        if (createNode.networks.length > 0) {
          let str = '';
          createNode.networks.forEach((el) => {
            str = `${str} ${el.name} `
          })
          return str
        }
        return null;
      case 2:
        if (createNode.type !== '') {
          return createNode.type
        }
        return null;
      case 3:
        if (createNode.certificate.name !== '') {
          const {name, ip, country, organization, units} = createNode.certificate;
          return <div className={s.companyInfo}>
            Company {name}, Country: {country}, Organization: {organization}, Units: {units}, Host IP address: {ip}
          </div>
        }
        return null;
      default:
        return null;
    }
  }

  let history = useHistory();

  const goToDashboard = (): void => {
    history.push(`/${MAIN.NODES}`)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.contentWrapper}>
        <div className={s.header}>
          <div className={s.back}>
            <ArrowBackIcon onClick={goToDashboard}/>
          </div>
          <div className={s.headerContent}>
            <div className={s.breadcrumbs}>
              Nodes
            </div>
            <div className={s.title}>
              Node registration
            </div>
          </div>
        </div>
        <div className={s.content}>
          <Stepper activeStep={step} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  className={`${s.stepLabel} ${getStopEvents(index)}`}
                  onClick={() => goToStep(index)}>
                  <div className={`${s.label} ${step === index ? s.labelActive : ''}`}>
                    {label}
                  </div>
                </StepLabel>
                <div>
                  <div className={s.completedContent}>
                    {getLabelContent(index)}
                  </div>
                </div>
                <StepContent>
                  <div>
                    {getContent(index)}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  )
})

export default CreateNode;
