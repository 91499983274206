export const arrayIntegration = ['SIP Integration', 'Radius', 'Diameter', 'HTTP', 'Define later']

export const getTextIntegration = (value: string) => {
  const [sip, radius, diameter, http, later] = arrayIntegration;
  switch (value) {
    case sip:
      return 'A SIP-Proxy is installed as b2bUA, and connects to a Call Registry through proprietary protocol. This type of integration is compatible with various Operators that use a SIP calling agent to connect to their customers. It can also be used by PSTN operators with infrastructure that allows b2bUA systems installation.';
    case radius:
      return 'The most universal integration method. Any switch or SBC can be easily configured to send Radius Accounting messages to the Call Registry. It fits any operator';
    case diameter:
    case http:
      return 'An integration method recommended for Mobile Operators. It allows the detection of roaming fraud.'
    case later:
      return 'Our engineers will help you to determine convenient integration method for your infrastructure';
    default:
      return ''
  }
}
