import { DaysWeek } from "./enums";

export const dayInMillisec = 3600 * 24 * 1000;

export const numberDivision = (
  dividend: number,
  divider: number,
  digits: number
): string => {
  const value = dividend / divider;
  return String(parseFloat(value.toString()).toFixed(digits));
};

export const tickFormatter = (count: number, max: number) => {
  if (max <= 10000) {
    return String(count.toLocaleString());
  }
  if (max > 10000 && max <= 1000000) {
    const value = numberDivision(count, 1000, 2);
    return value + "K";
  }
  if (max > 1000000) {
    const value = numberDivision(count, 1000000, 2);
    return value + "M";
  }
};

export const daysWeek: string[] = [
  DaysWeek.Sun,
  DaysWeek.Mon,
  DaysWeek.Tue,
  DaysWeek.Wed,
  DaysWeek.Thu,
  DaysWeek.Fri,
  DaysWeek.Sat,
];
