import React, {FC, memo, useState, useEffect} from "react";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {BarChart, Bar, XAxis, Tooltip} from "recharts";
import moment from 'moment';

import {TStat, TStatusCheckbox} from "../../../../types";
import {tickFormatter} from "../../../../utils";

import s from './Schedule.module.scss';

type Props = {
  stat: TStat | null,
  nameOperator: () => string,
  statusCheckbox: TStatusCheckbox,
  checkboxControl: (field: 'valid' | 'total', value: boolean) => void;
}

const Schedule: FC<Props> = memo(({stat, nameOperator, statusCheckbox, checkboxControl}) => {

  const [width, setWidth] = useState<number>(0);
  const [waitGetWidth, setWaitGetWidth] = useState<boolean>(true);

  useEffect(() => {
    watchSize();
  }, [])

  const watchSize = () => {
    const schedule = document.querySelector(`#dashboardHeader`)?.getBoundingClientRect();
    if (schedule) {
      setWidth(schedule.width);
      setWaitGetWidth(false);
    }
  }

  if (!stat) {
    return <div/>;
  }

  const CustomTooltip = (props: any) => {
    const {active, payload, label} = props;
    if (active && payload && payload.length) {
      const findDate = stat?.groupByDate.find((el) => el.name === label);
      const format = findDate ? moment(findDate.date).format('DD/MM/YYYY') : '';
      if(payload.length === 2) {
        return (
          <div className={s.tooltip}>
            <div>Date: {format}</div>
            <div>Valid: {tickFormatter(payload[0].value, payload[0].value)}</div>
            <div>Total:  {tickFormatter(payload[1].value, payload[1].value)}</div>
          </div>
        );
      } else {
        const title = statusCheckbox.valid ? "Valid" : "Total"
        return (
          <div className={s.tooltip}>
            <div>Date: {format}</div>
            <div>{title}: {tickFormatter(payload[0].value, payload[0].value)}</div>
          </div>
        );
      }

    }
    return null;
  };

  return (
    <div className={s.wrapperSchedule}>
      <div className={s.wrapperCounters}>
        <div className={s.info}>
          <div className={s.name}>{nameOperator()}</div>
          <div>Statistics per 30 days</div>
        </div>
        <div className={s.flex}>
          <div>
            <div className={`${s.counter} ${s.flex}`}>
              {tickFormatter(stat.counterValidated, stat.counterValidated)}
            </div>
            <div className={s.flex}>
              <FormControlLabel
                className={s.label}
                control={
                  <Checkbox
                    className={`${s.point} ${s.green}`}
                    checked={statusCheckbox.valid}
                    onChange={(e) =>
                      checkboxControl('valid', e.target.checked)}
                    disableTouchRipple
                  />
                }
                label="Validated calls"
              />
            </div>
          </div>
          <div className={s.mg}>
            <div className={`${s.counter} ${s.flex}`}>
              {tickFormatter(stat.counterTotal, stat.counterTotal)}
            </div>
            <div className={s.flex}>
              <FormControlLabel
                className={s.label}
                control={
                  <Checkbox
                    className={`${s.point} ${s.white}`}
                    checked={statusCheckbox.total}
                    onChange={(e) =>
                      checkboxControl('total', e.target.checked)}
                    disableTouchRipple
                  />
                }
                label="Total calls"
              />
            </div>
          </div>
        </div>
      </div>
      {!waitGetWidth && stat?.groupByDate.length > 0 && (
        <BarChart
          width={width}
          height={150}
          data={stat?.groupByDate}
        >
          <XAxis dataKey="name" tick={{fontSize: 11}} axisLine={false} tickLine={false} stroke={'#FFFFFF'}/>
          <Tooltip cursor={{fill: 'rgba(206, 206, 206, 0.2)'}} content={<CustomTooltip/>}/>
          {statusCheckbox.valid && (
            <Bar dataKey={'valid'} barSize={8} fill={'#49D657'} radius={[5, 5, 5, 5]}/>
          )}
          {statusCheckbox.total && (
            <Bar dataKey={'total'} barSize={8} fill={'#ffffff'} radius={[5, 5, 5, 5]}/>
          )}
        </BarChart>
      )}
    </div>

  )
})

export default Schedule;
