import { FC } from "react";
import moment from "moment";

import { getStatusIcon } from "../helper";

import s from "../Scenarios.module.scss";

type Props = {
  name?: string;
  value: any;
  format?: "string" | "date" | "number" | "status" | "jsx";
  hide?: boolean;
};

const RowItem: FC<Props> = ({
  name,
  value,
  format = "string",
  hide = false,
}) => {
  const formatValue = () => {
    switch (format) {
      case "string":
        return String(value);
      case "number":
        return Number(value);
      case "date":
        return moment(value).format("DD.MM.YYYY");
      case "status":
        return (
          <>
            {getStatusIcon(value)}
            <span className={s.upper} style={{ marginLeft: "5px" }}>
              {value}
            </span>
          </>
        );
      case "jsx":
        return value;
    }
  };

  return (
    <div className={s.rowMain}>
      {name && (
        <div className={`${s.label} ${hide ? s.empty : ""}`}>{name}</div>
      )}
      <div className={s.text}>{formatValue()}</div>
    </div>
  );
};

export default RowItem;
