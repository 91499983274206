/* eslint-disable react/jsx-no-target-blank */
import React, {ReactElement, useCallback, useLayoutEffect} from "react";
import {observer} from "mobx-react";
import {Link} from 'react-router-dom';

import SimpleTable from "../../shared/simpleTable";
import {useStores} from "../../../store/useStore";
import {MAIN} from "../../../utils";

import s from "./Billing.module.scss";
import {
  getContent,
  TSXTableRowBilling,
  TABLE_BILLING_HEAD_VALUES,
  TABLE_BILLING_KEYS,
} from "./helper";
import moment from "moment";
import Loader from "../../shared/loader";

const Billing = observer(() => {

  const {
    operatorStore: {
      getBillingAsync,
      getCompanyAsync,
      company,
      billing,
    },
    userStore: {
      selectedOperator
    }
  } = useStores();

  useLayoutEffect(() => {
    getBillingAsync();
    getCompanyAsync();
  }, [
    getBillingAsync,
    getCompanyAsync,
    selectedOperator])


  const {ids, loading, list} = billing;

  const getTableHeadBilling = (el: keyof typeof TABLE_BILLING_HEAD_VALUES): string => {
    return getContent(TABLE_BILLING_HEAD_VALUES[el])
  }

  const getTableRowBilling = (id: string | number): ReactElement => {
    if (!loading && list.length > 0) {
      return TSXTableRowBilling(id, list);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  const getTrial = useCallback((): string => {
    if(company?.trial_till_period) {
      return moment(`${company.trial_till_period}-01`).format('MMM YYYY');
    }
    return '';
  }, [company])

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        Billing details
      </div>
      <div className={s.info}>
        Company name: {company?.company} <br/>
        Contact email: {company?.email} <br/>
        <Link to={`/${MAIN.COMPANY_INFO}`}>Edit billing details</Link>
      </div>
      <div className={s.subtitle}>
        Pricing model
      </div>
      <div className={s.infoBilling}>
        The charging unit is a validated int. call atempt (no CAPEX, and there is a sliding scale for growing
        volumes).<br/>
        You are a participant of <a href="https://abhandshake.com/community" target="_blank">the Early Adopters program</a> (trial period till {getTrial()}).<br/>
        Current price: <strong>0.001$</strong> per validated call
      </div>
      <div className={s.subtitle}>
        Invoice and Usage History
      </div>
      {loading ?
        <div className={s.wrapperLoader}>
          <Loader color='secondary'/>
        </div> :
        <div className={s.table}>
          <SimpleTable
            styleName={s.tableBilling}
            ids={ids}
            getTableHead={getTableHeadBilling}
            tableKeys={TABLE_BILLING_KEYS}
            getTableRow={getTableRowBilling}
          />
        </div>
      }
    </div>
  )
})

export default Billing;
