export const FIELD_REQUIRED = 'Field required';
export const CORPORATE_EMAIL = 'Please use your corporate email address';
export const PASSWORD_LENGTH = 'Password must be at least 8 characters long';
export const ONLY_NUMBER = 'Only number';
export const NOT_VALID_DATA = 'Date not valid';
export const NUMBER_LIMIT = 'Number of calls exceeds allowed limit';
export const LIMIT = 'Number exceeds allowed limit';
export const POSITIVE = "Number of call exceeds can't be negative";

export const MAX_NUMBER = 1000000;
