import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { version, environment } from "./utils/senreyVariables";
import App from "./App";

import "./index.css";

Sentry.init({
  release: version,
  environment: environment(window.location.host),
  dsn: "https://dd6d5d6249bb45b8b61db7a46b636bce@sentry.etservice.net/12",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));
