import React, {ReactElement, useEffect, useLayoutEffect} from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion";
import {Link, useHistory} from 'react-router-dom';
import {useIntercom} from "react-use-intercom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {Button, CircularProgress} from "@material-ui/core";

import SimpleTable from "../../shared/simpleTable";
import Schedule from "./schedule";
import {
  TABLE_NODES_KEYS,
  TABLE_NODES_HEAD_VALUES,
  TABLE_NETWORKS_HEAD_VALUES,
  TABLE_NETWORKS_KEYS,
  getContent,
  TSXTableRowNodes,
  TSXTableRowNetworks
} from "./helper";
import {useStores} from "../../../store/useStore";
import {MAIN} from "../../../utils";

import s from './Dashboard.module.scss';

const Dashboard = observer(() => {

  const {show} = useIntercom();

  let history = useHistory();

  const {
    networksStore: {
      networks,
      idsNetworks,
    },
    nodesStore: {
      nodes,
      idsNodes,
      getNodesAsync,
    },
    userStore: {
      user,
      selectedOperator
    },
    statsStore: {
      getStatsAsync,
      stats,
      loading,
      activeStat,
      setActiveStat,
      statusCheckbox,
      checkboxControl
    }
  } = useStores();

  useEffect(() => {
    getNodesAsync();
  }, [getNodesAsync, selectedOperator])

  useLayoutEffect(() => {
    const findElem = stats?.find((el) => el.id === selectedOperator);
    if (!findElem) {
      getStatsAsync();
    } else {
      setActiveStat(Number(selectedOperator))
    }
  }, [getStatsAsync, selectedOperator, setActiveStat, stats])

  const getTableHeadNodes = (el: keyof typeof TABLE_NODES_HEAD_VALUES): string => {
    return getContent(TABLE_NODES_HEAD_VALUES[el])
  }

  const getTableRowNodes = (id: string | number): ReactElement => {
    const goTo = (id: string | number) => {
      history.push(`${MAIN.NODES}/${id}`)
    }
    if (activeStat && activeStat?.statByNode) {
      return TSXTableRowNodes(id, nodes, activeStat.statByNode, goTo);
    }
    return <tr/>
  }

  const getTableHeadNetworks = (el: keyof typeof TABLE_NETWORKS_HEAD_VALUES): string => {
    return getContent(TABLE_NETWORKS_HEAD_VALUES[el])
  }

  const getTableRowNetworks = (id: string | number): ReactElement => {
    const goTo = (id: string | number) => {
      history.push(`${MAIN.NETWORKS}/${id}`)
    }
    if (activeStat && activeStat?.statByNetwork) {
      return TSXTableRowNetworks(id, networks, activeStat.statByNetwork, goTo);
    }
    return <tr/>
  }

  const goToCreateNodes = () => {
    history.push(`/${MAIN.NODES}`)
  }

  const goToCreateNetworks = () => {
    history.push(`/${MAIN.NETWORKS}`)
  }

  const nameOperator = () => {
    const findElem = user?.operators.find((el) => el.id === selectedOperator);
    if (findElem) {
      return findElem.name;
    }
    return ''
  }

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <motion.div
          className={s.bg}
        />
        {!loading ?
          <>
            {activeStat && activeStat?.groupByDate.length > 0 ?
              <>
                <div className={s.header} id="dashboardHeader">
                  <Schedule
                    stat={activeStat}
                    nameOperator={nameOperator}
                    statusCheckbox={statusCheckbox}
                    checkboxControl={checkboxControl}
                  />
                </div>
              </> :
              <>
                <div className={s.header}>
                  <div className={s.title}>
                    Dashboard ({nameOperator()})
                  </div>
                  <div className={s.text}>
                    Welcome to AB Handshake Operator portal. <br/>
                    Here you can manage your Networks and Call Registration (CR) Nodes. <br/>
                    Please <span className={s.link} onClick={show}>Contact our support</span> if you have any
                    questions.
                  </div>
                </div>
              </>}
          </> :
          <div className={s.wrapperLoader}>
            <CircularProgress/>
          </div>
        }
        <div className={s.body}>
          {idsNetworks.length > 0 && (
            <div>
              <div className={s.titleTable}>
                <div>Nodes</div>
              </div>
              {idsNodes.length > 0 ?
                <SimpleTable
                  styleName={s.tableNodes}
                  ids={idsNodes}
                  tableKeys={TABLE_NODES_KEYS}
                  getTableHead={getTableHeadNodes}
                  getTableRow={getTableRowNodes}
                />
                :
                <div className={s.empty}>
                  <div>
                    No Call Registration (CR) nodes yet.<br/>
                    Please follow the instruction guide to register and install a new node.
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    <div className={s.buttonContent}>
                      <AddCircleIcon onClick={goToCreateNodes}/>
                      <span>Register CR node</span>
                    </div>
                  </Button>
                </div>
              }
            </div>
          )}
          <div>
            <div className={s.titleTable}>
              <div>Networks</div>
            </div>
            {idsNetworks.length > 0 ?
              <>
                <SimpleTable
                  styleName={s.tableNetworks}
                  ids={idsNetworks}
                  getTableHead={getTableHeadNetworks}
                  tableKeys={TABLE_NETWORKS_KEYS}
                  getTableRow={getTableRowNetworks}
                />
                <div className={s.footerTable}>
                  You should provide information about all owned networks for correct onboarding to the AB Handshake call validation system. <br/>
                  You can see the current participant list on the <Link to={`/${MAIN.PARTICIPANTS}`}>Participants screen</Link>.
                </div>
              </>

              :
              <div className={s.empty}>
                <div>
                  No networks linked to operator (Beeline) yet.<br/>
                  Please follow the instruction guide to find or create network.
                </div>
                <Button
                  variant="contained"
                  color="primary"
                >
                  <div className={s.buttonContent}>
                    <AddCircleIcon onClick={goToCreateNetworks}/>
                    <span>Add network</span>
                  </div>
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
});

export default Dashboard;
