import React, {FC} from "react"
import {CircularProgress} from "@material-ui/core";

import s from './Loader.module.scss'

type Props = {
  color?: 'primary' | 'secondary' | 'inherit' | undefined;
}

const Loader: FC<Props> = ({color = 'primary'}) => {
  return (
    <div className={s.wrapper}>
      <CircularProgress color={color}/>
    </div>
  )
};

export default Loader;
