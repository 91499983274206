import { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch as SwitchRoute,
  Route,
  useHistory,
} from "react-router-dom";
import { observer } from "mobx-react";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "@material-ui/styles";

import { theme } from "./assets/theme";
import { useStores } from "./store/useStore";
import { useHookRoute } from "./utils/useHookRoute";
import { SIGN_IN, SIGN_UP, UserStatus } from "./utils";

import Header from "./components/shared/header";
import SignUp from "./components/signUp";
import SignIn from "./components/SignIn";
import Main from "./components/Main";
import Loader from "./components/shared/loader";
import SimpleSnackbar from "./components/shared/simpleSnackbar";
import IntercomWatcher from "./components/shared/intercomWatcher";

import s from "./App.module.scss";

const Control = observer(() => {
  let history = useHistory();

  const {
    userStore: { isAuthorized },
  } = useStores();

  useEffect(() => {
    if (!isAuthorized) {
      history.push("/sign-in");
    }
  }, [isAuthorized, history]);

  return <Main />;
});

const App: FC = observer(() => {
  const {
    userStore: { user, loadingProfile, getUserAsync, isAuthorized, logoutUser },
    networksStore: { getNetworks },
  } = useStores();

  useEffect(() => {
    getUserAsync();
    if (!isAuthorized) {
      logoutUser();
    }
  }, [getUserAsync, isAuthorized, logoutUser]);

  useEffect(() => {
    getNetworks();
  }, [getNetworks]);

  const defaultPath = `/${SIGN_IN.ROOT}`;

  const getPathRedirect = () => {
    const operatorPath = `/${SIGN_UP.ROOT}/${SIGN_UP.OPERATOR}`;
    const confirmPath = `/${SIGN_UP.ROOT}/${SIGN_UP.OPERATOR}`;
    if (user?.status === UserStatus.verified) {
      return operatorPath;
    }
    if (user?.status === UserStatus.new) {
      return confirmPath;
    }
    return defaultPath;
  };

  return (
    <ThemeProvider theme={theme}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID || ""}>
        <SimpleSnackbar />
        <Router>
          <div className={s.wrapper}>
            <Header />
            <IntercomWatcher />
            {loadingProfile ? (
              <div className={s.loader}>
                <Loader />
              </div>
            ) : (
              <SwitchRoute>
                <Route
                  path={`/${SIGN_UP.ROOT}`}
                  render={() =>
                    useHookRoute({
                      component: SignUp,
                      condition: true,
                      sideCondition: user?.status === UserStatus.active,
                      pathRedirect: "/dashboard",
                      defaultPath: defaultPath,
                    })
                  }
                />
                <Route
                  path={`/${SIGN_IN.ROOT}`}
                  render={() =>
                    useHookRoute({
                      component: SignIn,
                      condition: true,
                      sideCondition: isAuthorized && !!user,
                      pathRedirect: "/",
                      defaultPath: defaultPath,
                    })
                  }
                />
                <Route
                  path="/"
                  render={() =>
                    useHookRoute({
                      component: Control,
                      condition: isAuthorized && !!user,
                      sideCondition:
                        user?.status === UserStatus.verified ||
                        user?.status === UserStatus.new,
                      pathRedirect: getPathRedirect(),
                      defaultPath: defaultPath,
                    })
                  }
                />
              </SwitchRoute>
            )}
          </div>
        </Router>
      </IntercomProvider>
    </ThemeProvider>
  );
});

export default App;
