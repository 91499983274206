import { FC, ReactElement } from "react";

import s from "./SimpleTable.module.scss";

type Props = {
  ids: string[] | number[];
  getTableHead: (el: any) => string | ReactElement;
  tableKeys: string[];
  getTableRow: (id: string | number) => ReactElement;
  styleName?: string;
};

const SimpleTable: FC<Props> = ({
  ids,
  getTableHead,
  tableKeys,
  getTableRow,
  styleName,
}) => (
  <table className={`${s.table} ${styleName}`}>
    <thead className={s.tableHead}>
      <tr>
        {tableKeys.map((el, index) => (
          <th key={`${el}-${index}`}>{getTableHead(el)}</th>
        ))}
      </tr>
    </thead>
    <tbody>{ids.map((id: string | number) => getTableRow(id))}</tbody>
  </table>
);

export default SimpleTable;
