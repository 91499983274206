import React from "react";
import {Switch, Route, useRouteMatch, Redirect, useLocation} from "react-router-dom";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";

import Header from "./shared/header";
import Sidebar from "../shared/sidebar";
import Dashboard from "./dashnoard";
import Nodes from "./nodes";
import Networks from "./networks";
import Scenarios from "./scenarios";
import CompanyInfo from "./compayInfo";
import Billing from "./billing";
import Participants from "./participants";

import {HELPERS, MAIN} from "../../utils";

import s from './Main.module.scss';

const arrayDivider = [`/${MAIN.DASHBOARD}`, `/${MAIN.NODES}/${HELPERS.CREATE}`];

const Main = () => {
  const {path} = useRouteMatch();
  const {pathname} = useLocation();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={`${s.wrapper} ${arrayDivider.includes(pathname) && s.wrapperDivider}`}>
        <Sidebar/>
        <Header/>
        <div className={s.content}>
          <Switch>
            <Route path={`${path}${MAIN.DASHBOARD}`} exact component={Dashboard}/>
            <Route path={`${path}${MAIN.NODES}`} component={Nodes}/>
            <Route path={`${path}${MAIN.NETWORKS}`} component={Networks}/>
            <Route path={`${path}${MAIN.TEST_CALLS}`} component={Scenarios}/>
            <Route path={`${path}${MAIN.COMPANY_INFO}`} component={CompanyInfo}/>
            <Route path={`${path}${MAIN.BILLING}`} component={Billing}/>
            <Route path={`${path}${MAIN.PARTICIPANTS}`} component={Participants}/>
            <Redirect from='*' to={`${path}${MAIN.DASHBOARD}`}/>
          </Switch>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default Main;
