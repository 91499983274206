/* eslint-disable react/jsx-no-target-blank */
import React, {useLayoutEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";
//icons
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import PublicIcon from '@material-ui/icons/Public';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import DeviceHubOutlinedIcon from '@material-ui/icons/DeviceHubOutlined';
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';


import {MAIN} from "../../../utils";
// import {ACCESS_TOKEN_KEY} from "../../../services/root";
import {useStores} from "../../../store/useStore";

import s from './Sidebar.module.scss'

const Sidebar = observer(() => {

  const {userStore: {selectedOperator, user}} = useStores();

  const [show, setShow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const findAccessUser = user?.operators.find((el) => el.id === selectedOperator);
    if (findAccessUser?.tcg_enabled) setShow(true)
    else setShow(false)
  }, [selectedOperator, user])


  // const generateHref = (route: string) => {
  //   const token = String(localStorage.getItem(ACCESS_TOKEN_KEY));
  //   return `https://docs.abhandshake.com?at=${token}&route=${route}`
  // }

  return (
    <div className={s.wrapper}>
      <div>
        <div className={s.logoWrap}>
          <div className={s.logo}/>
          <div className={s.logoText}>
            Operator self-service portal
          </div>
        </div>
        <div className={s.navigation}>
          <div>
            <div className={s.navTitle}>
              Configuration
            </div>
            <NavLink to={`/${MAIN.DASHBOARD}`} className={s.nav} activeClassName={s.activeNav}>
              <DashboardOutlinedIcon/>
              <span>Dashboard</span>
            </NavLink>
            <NavLink to={`/${MAIN.NODES}`} className={s.nav} activeClassName={s.activeNav}>
              <DnsOutlinedIcon/>
              <span>Nodes</span>
            </NavLink>
            <NavLink to={`/${MAIN.NETWORKS}`} className={s.nav} activeClassName={s.activeNav}>
              <PublicIcon/>
              <span>Networks</span>
            </NavLink>
            {show && (
              <NavLink to={`/${MAIN.TEST_CALLS}`} className={s.nav} activeClassName={s.activeNav}>
                <PhoneInTalkIcon/>
                <span>Test Call Generator</span>
              </NavLink>
            )}
          </div>
          <div>
            <div className={`${s.navTitle} ${s.notFirst}`}>
              Operator account
            </div>
            <NavLink to={`/${MAIN.COMPANY_INFO}`} className={s.nav} activeClassName={s.activeNav}>
              <WorkOutlineIcon/>
              <span>Company info</span>
            </NavLink>
            <NavLink to={`/${MAIN.BILLING}`} className={s.nav} activeClassName={s.activeNav}>
              <AccountBalanceWalletOutlinedIcon/>
              <span>Billing and usage</span>
            </NavLink>
            {/*  <NavLink to="/123" className={s.nav} activeClassName={s.activeNav}>*/}
            {/*    <PeopleOutlinedIcon/>*/}
            {/*    <span>*/}
            {/*  User management*/}
            {/*</span>*/}
            {/*  </NavLink>*/}
          </div>
          <div>
            <div className={`${s.navTitle} ${s.notFirst}`}>
              Information
            </div>
            <NavLink to={`/${MAIN.PARTICIPANTS}`} className={s.nav} activeClassName={s.activeNav}>
              <DeviceHubOutlinedIcon/>
              <span>Participants</span>
            </NavLink>
            {/*<a*/}
            {/*  href={generateHref('callregistry/cr_installation_guide')}*/}
            {/*  className={s.nav}*/}
            {/*>*/}
            {/*  <HelpOutlineOutlinedIcon/>*/}
            {/*  <span>Knowledgebase</span>*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Sidebar;
