export const operatorTypes = [
  {id: 2, displayName: 'Mobile network operator (MNO)'},
  {id: 1, displayName: 'Mobile virtual network operator (MVNO)'},
  {id: 4, displayName: 'Fix line operator'},
  {id: 5, displayName: 'Application (SIP agent)'},
  {id: 0, displayName: 'Other'},
];

export const defaultParams = {
  integration_type: '-',
  organization_name: '-',
  organization_registration_number: '-',
  organization_country: '-',
  organization_city: '-',
  organization_zip_code: '-',
  organization_address: '-',
  submitted: true,
};
