import { FC, ReactNode } from "react";

import s from "./Page.module.scss";

type Props = {
  title: JSX.Element;
  info: JSX.Element;
  children: ReactNode;
  idTitle?: string;
};

const Page: FC<Props> = ({ title, idTitle, info, children }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.title} id={idTitle}>
        {title}
      </div>
      <div className={s.info}>{info}</div>
      {children}
    </div>
  );
};

export default Page;
