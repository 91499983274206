import React, {FC} from "react";
import {TNetworks, TNode} from "../../../types";
import s from './ItemList.module.scss'

type Props = {
  item: TNetworks | TNode | null,
  selectedID?: number,
  changeItem?: (id: number) => void,
  clickButtons: (str: string) => void;
  children: React.ReactNode;
  permissionField: string;
}

const ItemList: FC<Props> = ({
                               item,
                               selectedID,
                               changeItem,
                               clickButtons,
                               children,
                               permissionField
                             }) => {

  const clickItem = () => {
    if (item) {
      if (selectedID !== item.id && changeItem) {
        changeItem(item.id)
      }
    }
  }

  return (
    <>
      {item ?
        <div className={`${s.wrapper} ${selectedID === item?.id ? s.active : ''}`} onClick={clickItem}>
          {children}
        </div>
        :
        <div className={`${s.wrapper} ${s.addWrapper}`} onClick={() => clickButtons(permissionField)}>
          {children}
        </div>
      }
    </>
  )
}

export default ItemList;
