import React, {ReactElement} from "react";
import {Icon} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import copy from "copy-to-clipboard";
import moment from "moment";

import {FieldControlPermission} from "../../../../utils";
import copyIcon from "assets/icons/copy.svg";
import {TPrefixBlockNetwork} from "../../../../types";

import s from "./NetworkForm.module.scss";

export const TABLE_NETWORK_KEYS = ['source', 'all_prefixes', 'msrn_prefixes', 'did_prefixes', 'updated_on', 'link'];

export const TABLE_NETWORK_HEAD_VALUES = {
  source: 'source',
  all_prefixes: 'All prefixes',
  msrn_prefixes: 'MSRN prefixes',
  did_prefixes: 'DID Prefixes',
  updated_on: 'Updated on',
  link: ''
};

export const getContent = (value: string): string => {
  switch (value) {
    case 'empty':
    case 'link':
      return '';
    default:
      return value;
  }
}

export const TSXTableRowNetwork = (
  id: string | number,
  arr: TPrefixBlockNetwork[],
  copyMessage: (str: string) => void,
  anchorEl: any,
  handleClick: (e: any) => void,
  handleClose: () => void,
  clickButtons: (str: string) => void
): ReactElement => {
  const elem = arr.find((el) => el.id === id);

  const copyData = (array: string[]) => {
    const arrToStr = array.toString().replaceAll(',', ', ');
    copy(arrToStr);
    copyMessage('Copy')
  }

  const clickMenuItems = () => {
    clickButtons(FieldControlPermission.update_prefix);
    handleClose()
  }

  if (elem) {
    return (
      <tr key={elem.id} className={s.row}>
        <td>
          <div className={s.rowFlex}>
            {elem.label}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${elem.prefix_list.length === 0 ? s.disabled : ''}`}>
            {elem.prefix_list.length} ALL
            <>
              {elem.prefix_list.length > 0 && (
                <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
                  <Icon className={s.copyIcon} onClick={() => copyData(elem.prefix_list)}>
                    <img src={copyIcon} alt=''/>
                  </Icon>
                </IconButton>
              )}
            </>
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${elem.msrn_prefix_list.length === 0 ? s.disabled : ''}`}>
            {elem.msrn_prefix_list.length} MSRN
            <>
              {elem.msrn_prefix_list.length > 0 && (
                <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
                  <Icon className={s.copyIcon} onClick={() => copyData(elem.msrn_prefix_list)}>
                    <img src={copyIcon} alt=''/>
                  </Icon>
                </IconButton>
              )}
            </>
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${elem.did_prefix_list.length === 0 ? s.disabled : ''}`}>
            {elem.did_prefix_list.length} DID
            <>
              {elem.did_prefix_list.length > 0 && (
                <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
                  <Icon className={s.copyIcon} onClick={() => copyData(elem.did_prefix_list)}>
                    <img src={copyIcon} alt=''/>
                  </Icon>
                </IconButton>
              )}
            </>
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {moment(elem.updated_at).format('DD MMMM YYYY')}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            <IconButton component="span" style={{padding: '6px', marginLeft: '5px'}}>
              <MoreVertIcon onClick={handleClick} style={{cursor: 'pointer'}}/>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={clickMenuItems}>Update Prefixes</MenuItem>
              <MenuItem onClick={clickMenuItems}>Update MSRN Prefixes</MenuItem>
              <MenuItem onClick={clickMenuItems}>Update DID Prefixes</MenuItem>
            </Menu>
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}
