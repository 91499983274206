import {TUseHook} from "../types";
import {Redirect} from "react-router-dom";
import React from "react";

export const useHookRoute = (params: TUseHook & { defaultPath: string }) => {
  const {
    component: Component,
    condition,
    sideCondition,
    pathRedirect,
    defaultPath
  } = params
  if (condition) {
    if (sideCondition !== undefined && sideCondition) {
      return <Redirect to={pathRedirect || defaultPath}/>
    }
    return <Component/>
  }
  return <Redirect to={defaultPath}/>
}
