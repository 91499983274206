import {instance, CONFIRM_EMAIL} from "./root";
import {TCreateAccount, TVerify, TAuth, TUserResponse} from "../types";

export const userAPI = {
  createUser(params: TCreateAccount) {
    const {first_name, last_name, company, job_title, email, password, verify_url} = params;
    localStorage.setItem(CONFIRM_EMAIL, email);
    return instance.post(`/api/v1/register`, {
      first_name, last_name, company, job_title, email, password, verify_url
    })
  },
  verifyEmail(params: TVerify) {
    const {email, verification_token} = params;
    return instance.post('/api/v1/register/verify_email', {
      email, verification_token
    })
  },
  auth(params: TAuth) {
    const {email, password} = params;
    return instance.post<TUserResponse>('/api/v1/auth', {
      email, password
    })
  },
  restPassword(email: string) {
    return instance.post('/api/v1/reset_password', {
      email
    })
  },
  getDataUser() {
    return instance.get('/api/v1/profile')
  }
}
