import { ChangeEvent, useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import * as yup from "yup";

import Loader from "components/shared/loader";

import {
  Checkbox,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
  MenuItem,
  OutlinedInputProps,
  Tooltip,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";

import { COUNTRY_CODES_MAP } from "utils";
import { ScenariosApi } from "services";
import Button from "../../../shared/button";
import FormLabelRoute from "./FormLabelRoute";
import NumberItem from "./NumberItem";
import { useStores } from "store/useStore";

import { ReactComponent as IconQuestionCircle } from "assets/icons/question_circle.svg";

import {
  FIELD_REQUIRED,
  LIMIT,
  MAX_NUMBER,
  NUMBER_LIMIT,
  ONLY_NUMBER,
  POSITIVE,
} from "../../../../constants";
import { TNumber, TScenarioDetail, TSeverity } from "../../../../types";
import { daysWeek, MAIN } from "../../../../utils";

import s from "../Scenarios.module.scss";

export type TNumberValue = "a" | "b";

const FILE_UPLOAD_A = "file-upload-a";
const FILE_UPLOAD_B = "file-upload-b";

type FormData = {
  name: string;
  start_at: number | null;
  finish_at: number | null;
  active_day_hours_utc: number[];
  active_week_days: number[];

  // A-side
  a_side_type: "numbers" | "countries" | "";
  a_numbers: TNumber[];
  a_countries: string[];

  // B-side
  destination_network: number | "";
  b_side_type: "numbers" | "prefixes";
  b_numbers: TNumber[];
  prefix_list: string[];
  add_ported_in: boolean;
  use_b_number_once: boolean;

  route_group_ids: number[];
  calls_per_hour: number;
  calls_per_day: number;
  calls_per_month: number;
};

const Form = observer(() => {
  const [aNumberInput, setANumberInput] = useState<string>("");
  const [aCountrySelect, setACountrySelect] = useState<string>("");
  const [bNumberInput, setBNumberInput] = useState<string>("");

  const [serverErrors, setServerErrors] = useState<any>({});

  const history = useHistory();
  const { id: _id } = useParams<{ id?: string }>();
  const id = Number(_id);
  const isEdit = !!id;

  const [scenario, setScenarion] = useState<TScenarioDetail | null>(null);
  const [isLoading, setIsLoading] = useState(id ? true : false);

  const {
    operatorStore: {
      routes,
      // request is in src/components/Main/scenarios/Scenarios.tsx
      loadingRoutes,
    },
    networksStore: {
      networks,
      // request is in src/App.tsx
      loading: loadingNetworks,
    },
    notificationStore: { changeStateSnackbar },
    scenariosStore: {
      resolveNumberRequest,
      createScenario,
      updateScenario,
      getLimits,
      limits,
    },
  } = useStores();

  useEffect(() => {
    getLimits();
  }, [getLimits]);

  const { limit, loading: loadingLimit } = limits;

  const notification = (str: string, severity: TSeverity) => {
    changeStateSnackbar(str, severity);
  };

  const validationSchema = yup.object({
    name: yup.string().required(FIELD_REQUIRED),

    start_at: yup.string().required(FIELD_REQUIRED).nullable(),
    finish_at: yup
      .string()
      .required(FIELD_REQUIRED)
      .test(
        "start-after-finish",
        "Start date after finish date",
        function (dateB) {
          const start_at = this.resolve(yup.ref("start_at"));

          if (start_at && dateB) {
            return Number(dateB) > Number(start_at);
          }

          return true;
        }
      )
      .test(
        "after-today",
        "Finish date before today",
        (finish_at) => Number(finish_at) > Date.now()
      )
      .nullable(),

    active_day_hours_utc: yup.array().min(1, FIELD_REQUIRED),
    active_week_days: yup.array().min(1, FIELD_REQUIRED),
    route_group_ids: yup.array().min(1, FIELD_REQUIRED),
    calls_per_hour: yup
      .number()
      .positive(POSITIVE)
      .required(FIELD_REQUIRED)
      .max(limit ? limit.max_calls_per_hour : MAX_NUMBER, NUMBER_LIMIT)
      .typeError(ONLY_NUMBER),
    calls_per_day: yup
      .number()
      .positive(POSITIVE)
      .required(FIELD_REQUIRED)
      .max(limit ? limit.max_calls_per_day : MAX_NUMBER, NUMBER_LIMIT)
      .typeError(ONLY_NUMBER),

    // A-side
    a_side_type: yup.string().required(FIELD_REQUIRED),
    a_numbers: yup.array().when("a_side_type", {
      is: (a_side_type: "numbers" | "countries") => a_side_type === "numbers",
      then: (schema) => schema.min(1, FIELD_REQUIRED).max(5000, LIMIT),
    }),
    a_countries: yup.array().when("a_side_type", {
      is: (a_side_type: "numbers" | "countries") => a_side_type === "countries",
      then: (schema) => schema.min(1, FIELD_REQUIRED),
    }),

    // B-side
    destination_network: yup.string().required(FIELD_REQUIRED),
    b_side_type: yup.string().required(FIELD_REQUIRED),
    b_numbers: yup.array().when("b_side_type", {
      is: (b_side_type: "numbers" | "prefixes") => b_side_type === "numbers",
      then: (schema) => schema.min(1, FIELD_REQUIRED).max(5000, LIMIT),
    }),
    prefix_list: yup.array().when("b_side_type", {
      is: (b_side_type: "numbers" | "prefixes") => b_side_type === "prefixes",
      then: (schema) => schema.min(1, FIELD_REQUIRED).max(5000, LIMIT),
    }),
    calls_per_month: yup
      .number()
      .positive(POSITIVE)
      .required(FIELD_REQUIRED)
      .max(limit ? limit.max_calls_per_month : MAX_NUMBER, NUMBER_LIMIT)
      .typeError(ONLY_NUMBER),
  });

  const {
    values,
    setFieldValue,
    setValues,
    handleBlur,
    setFieldTouched,
    handleSubmit,
    handleChange,
    validateForm,
    errors,
    touched,
    // isValid,
    // dirty,
  } = useFormik<FormData>({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      start_at: null,
      finish_at: null,
      active_day_hours_utc: [],
      active_week_days: [],

      // A-side
      a_side_type: "numbers",
      a_numbers: [],
      a_countries: [],

      // B-side
      destination_network: networks.length === 1 ? networks[0].id : "",
      b_side_type: "numbers",
      b_numbers: [],
      prefix_list: [],
      add_ported_in: false,
      use_b_number_once: false,

      route_group_ids: [],
      calls_per_hour: 0,
      calls_per_day: 0,
      calls_per_month: 0,
    },
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { b_side_type } = values;

      const data = {
        ...values,
        enabled: isEdit ? Boolean(scenario?.enabled) : false,
        start_at: values.start_at as number,
        finish_at: values.finish_at as number,
        a_numbers: values.a_numbers.map((item) => item.number),

        b_numbers:
          b_side_type === "numbers"
            ? values.b_numbers.map((item) => item.number)
            : [],
        prefix_list: b_side_type === "numbers" ? [] : values.prefix_list,
      };

      return (isEdit ? updateScenario(id, data) : createScenario(data))
        .then(() => {
          if (isEdit) {
            notification("Scenario update", "success");
            history.push(`/${MAIN.TEST_CALLS}/scenarios/${id}/view`);
          } else {
            notification("Scenario create", "success");
            history.push(`/${MAIN.TEST_CALLS}`);
          }
        })
        .catch((err) => {
          if (err?.response?.data) {
            const { error, errors } = err.response.data;
            notification(error, "error");
            setServerErrors(errors);
          } else {
            notification("Something went wrong. Please try again", "error");
          }
        });
    },
  });

  useEffect(() => {
    let isCancelled = false;

    if (id) {
      setIsLoading(true);

      ScenariosApi.getCurrentScenario(id)
        .then(({ data }) => {
          if (!isCancelled) {
            setScenarion(data);
            setValues({
              name: data.name || "",
              start_at: data.start_at || null,
              finish_at: data.finish_at || null,
              active_day_hours_utc: data.active_day_hours_utc || [],
              active_week_days: data.active_week_days || [],

              // A-side
              a_side_type: data.a_side_type || "numbers",
              a_numbers: data.a_numbers || [],
              a_countries: data.a_countries || [],

              // B-side
              destination_network: data.destination_network || "",
              b_side_type: data.b_side_type || "numbers",
              b_numbers: data.b_numbers || [],
              prefix_list: data.prefix_list || [],
              add_ported_in: data.add_ported_in || false,
              use_b_number_once: data.use_b_number_once || false,

              route_group_ids: data.route_group_ids || [],
              calls_per_hour: data.calls_per_hour || 0,
              calls_per_day: data.calls_per_day || 0,
              calls_per_month: data.calls_per_month || 0,
            });
            setIsLoading(false);
          }
        })
        .catch(() => {
          changeStateSnackbar("Something went wrong.Please try again");
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [id, changeStateSnackbar, setValues]);

  useEffect(() => {
    // if there is only one network set it as selected one
    if (networks.length === 1) {
      setFieldValue("destination_network", networks[0].id);
    }
  }, [networks, setFieldValue]);

  const onChange = (event: any, field: string) => {
    if (serverErrors[field]) {
      const newServerErrors: any = serverErrors;
      delete newServerErrors[field];
      setServerErrors({
        ...newServerErrors,
      });
    }
    handleChange(event);
  };

  const removeServerError = (field: string) => {
    if (serverErrors[field]) {
      const newServerErrors: any = serverErrors;
      delete newServerErrors[field];
      setServerErrors({
        ...newServerErrors,
      });
    }
  };

  const changeCheckbox = (
    field: "hour" | "day" | "route",
    value: string | number
  ) => {
    switch (field) {
      case "hour": {
        setFieldTouched("active_day_hours_utc", true);
        const { active_day_hours_utc } = values;
        if (active_day_hours_utc.includes(value as number)) {
          setFieldValue(
            "active_day_hours_utc",
            active_day_hours_utc.filter((el) => el !== value)
          );
        } else {
          setFieldValue(
            "active_day_hours_utc",
            active_day_hours_utc.concat([value as number])
          );
        }
        break;
      }
      case "day": {
        setFieldTouched("active_week_days", true);
        const { active_week_days } = values;
        if (active_week_days.includes(value as number)) {
          setFieldValue(
            "active_week_days",
            active_week_days.filter((el) => el !== value)
          );
        } else {
          setFieldValue(
            "active_week_days",
            active_week_days.concat([value as number])
          );
        }
        break;
      }
      case "route": {
        const val = Number(value);
        setFieldTouched("route_group_ids", true);
        const oldValues: any[] = values.route_group_ids;
        if (oldValues.find((el) => el === val)) {
          setFieldValue(
            "route_group_ids",
            oldValues.filter((el) => el !== val)
          );
        } else {
          setFieldValue("route_group_ids", oldValues.concat([val]));
        }
      }
    }
  };

  const resolve = async (value: number | string, type: "a" | "b") => {
    try {
      const request = await resolveNumberRequest(
        value,
        type === "b" ? values.destination_network : ""
      );

      if (request.status === 200) {
        const { data } = request;
        if (type === "b") {
          if (!data.belongs_to_network) {
            changeStateSnackbar("Number doen't belong to selected network");
          } else {
            return data;
          }
        } else {
          return data;
        }
      }
    } catch (err: any) {
      if (err?.response?.data) {
        const {
          errors: { number },
        } = err.response.data;
        changeStateSnackbar(number);
      } else {
        changeStateSnackbar("Something went wrong. Please try again");
      }
      return undefined;
    }
  };

  // TODO DRY!!!
  const resolveNumber = async (type: TNumberValue) => {
    if (type === "a") {
      if (!aNumberInput) return;
      const number = (await resolve(aNumberInput, "a")) as TNumber;

      if (number) {
        setANumberInput("");
        setFieldValue("a_numbers", [
          ...values.a_numbers.filter((item) => item.number !== number.number),
          number,
        ]);
      }
    } else {
      const { b_side_type } = values;
      if (!bNumberInput) return;
      const number = (await resolve(bNumberInput, "b")) as TNumber;

      if (number) {
        setBNumberInput("");
        setFieldValue(b_side_type === "numbers" ? "b_numbers" : "prefix_list", [
          ...(b_side_type === "numbers"
            ? values.b_numbers.filter((value) => value.number !== number.number)
            : values.prefix_list.filter((value) => value !== number.number)),
          b_side_type === "numbers" ? number : number.number,
        ]);
      }
    }
  };

  const removeItem = (item: Partial<TNumber>, type: TNumberValue) => {
    const { b_side_type } = values;
    setFieldValue(
      type === "a"
        ? "a_numbers"
        : b_side_type === "numbers"
        ? "b_numbers"
        : "prefix_list",
      type === "a"
        ? values.a_numbers.filter((el) => el.number !== item.number)
        : b_side_type === "numbers"
        ? values.b_numbers.filter((el) => el.number !== item.number)
        : values.prefix_list.filter((prefix) => prefix !== item.number)
    );
  };

  const changeCustomField = (
    value: string,
    type: "a_numbers" | "b_numbers"
  ) => {
    if (type === "a_numbers") {
      setANumberInput(value);
    } else {
      setBNumberInput(value);
    }
    validateForm();
    removeServerError(type);
  };

  const handleCountriesSelectChange: OutlinedInputProps["onChange"] = (e) => {
    setACountrySelect(e.target.value);
  };

  const handleAddCountry = () => {
    if (aCountrySelect) {
      handleChange({
        target: {
          name: "a_countries",
          value: [...values.a_countries, aCountrySelect],
        },
      });
      setACountrySelect("");
    }
  };

  const handleRemoveCountry = (item: Partial<TNumber>) => {
    handleChange({
      target: {
        name: "a_countries",
        value: values.a_countries.filter((code) => code !== item.country_iso),
      },
    });
  };

  const uplodAInputRef = useRef<HTMLInputElement>(null);
  const uplodBInputRef = useRef<HTMLInputElement>(null);
  const [isLoadingUplodA, setIsLoadingUploaA] = useState(false);
  const [isLoadingUplodB, setIsLoadingUploaB] = useState(false);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { b_side_type } = values;
    const file = event.target.files && event.target.files[0];

    const { id } = event.target;
    const isBSide = id === FILE_UPLOAD_B;

    isBSide ? setIsLoadingUploaB(true) : setIsLoadingUploaA(true);

    if (file) {
      const formData = new FormData();

      formData.append("file", file);

      ScenariosApi.uploadFile(
        formData,
        isBSide ? values.destination_network : ""
      )
        .then(({ data }) => {
          if (data) {
            if (isBSide) {
              const filteredNumbers = data.filter((item) => {
                return (
                  Boolean(item.number) &&
                  // exclude existing value
                  (b_side_type === "numbers"
                    ? values.b_numbers.map((item) => item.number)
                    : values.prefix_list
                  ).every((value) => value !== item.number)
                );
              });

              // filter the result by belongs_to_network = true
              // TODO why i should filter the result i've got just a moment ago?
              const [belongsArr, notBelongsArr] = filteredNumbers.reduce<
                [TNumber[], TNumber[]]
              >(
                (acc, item) => {
                  const [a, b] = acc;

                  return item.belongs_to_network
                    ? [[...a, item], b]
                    : [a, [...b, item]];
                },
                [[], []]
              );

              setFieldValue(
                b_side_type === "numbers" ? "b_numbers" : "prefix_list",
                [
                  ...(b_side_type === "numbers"
                    ? values.b_numbers
                    : values.prefix_list),
                  ...(b_side_type === "numbers"
                    ? belongsArr
                    : belongsArr.map((item) => item.number)),
                ]
              );

              notBelongsArr.length &&
                notification(
                  `There are ${notBelongsArr.length} ignored values`,
                  "warning"
                );
            } else {
              const filteredNumbers = data.filter((item) => {
                return (
                  Boolean(item.number) &&
                  // exclude existing value
                  values.a_numbers.every(({ number }) => number !== item.number)
                );
              });

              setFieldValue("a_numbers", [
                ...values.a_numbers,
                ...filteredNumbers,
              ]);
            }
          } else {
            notification("There is no values to add", "warning");
          }

          isBSide ? setIsLoadingUploaB(false) : setIsLoadingUploaA(false);
        })
        .catch((err) => {
          console.warn("[ERROR] ScenariosApi.uploadFile: ", err);
        });
    }
  };

  // TODO improve loading state

  const inLoad = isLoading || loadingLimit || loadingNetworks || loadingRoutes;

  if (inLoad) {
    return <Loader color="inherit" />;
  }

  return (
    <form className={s.form} onSubmit={handleSubmit}>
      {/* Name */}
      <div className={`${s.formRow} ${s.field}`}>
        <div className={s.formLabel}>name</div>
        <TextField
          name="name"
          variant="outlined"
          value={values.name}
          onBlur={handleBlur}
          placeholder="Scenario name"
          onChange={(e) => onChange(e, "name")}
          error={
            (touched.name && Boolean(errors.name)) || Boolean(serverErrors.name)
          }
          helperText={
            (touched.name && errors.name !== FIELD_REQUIRED && errors.name) ||
            serverErrors.name
          }
        />
      </div>

      {/* start_at / finish_at */}
      <div className={`${s.formRow} ${s.field}`}>
        <div className={s.formLabel}>date</div>
        <div className={`${s.fieldWidth} ${s.date}`}>
          <KeyboardDatePicker
            disableToolbar
            name="start_at"
            format="DD-MM-YYYY"
            inputVariant="outlined"
            value={values.start_at}
            className={s.dateInput}
            placeholder="Start date"
            DialogProps={{ PaperProps: { className: s.wrapperPaper } }}
            onChange={(date) =>
              handleChange({
                target: { name: "start_at", value: date?.valueOf() },
              })
            }
            onBlur={handleBlur}
            error={
              (touched.start_at && Boolean(errors.start_at)) ||
              Boolean(serverErrors.start_at)
            }
            helperText={
              (touched.start_at &&
                errors.start_at !== FIELD_REQUIRED &&
                errors.start_at) ||
              serverErrors.start_at
            }
          />

          <KeyboardDatePicker
            disableToolbar
            name="finish_at"
            format="DD-MM-YYYY"
            inputVariant="outlined"
            className={s.dateInput}
            value={values.finish_at}
            placeholder="Finish date"
            DialogProps={{ PaperProps: { className: s.wrapperPaper } }}
            onChange={(date) =>
              handleChange({
                target: { name: "finish_at", value: date?.valueOf() },
              })
            }
            onBlur={handleBlur}
            error={
              (touched.finish_at && Boolean(errors.finish_at)) ||
              Boolean(serverErrors.finish_at)
            }
            helperText={
              (touched.finish_at &&
                errors.finish_at !== FIELD_REQUIRED &&
                errors.finish_at) ||
              serverErrors.finish_at
            }
          />
        </div>
      </div>

      {/* active_day_hours_utc */}
      <div className={`${s.formRow} ${s.gridRow} ${s.hoursMargin}`}>
        <div className={s.formLabel}>
          Active hours
          <br />
          (UTC)
        </div>
        <div className={s.rowGrid}>
          {Array.from({ length: 24 }).map((_, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    name={`checkbox-${index}`}
                    onChange={() => changeCheckbox("hour", index)}
                    checked={Boolean(
                      values.active_day_hours_utc.includes(index)
                    )}
                    className={
                      (touched.active_day_hours_utc &&
                        Boolean(errors.active_day_hours_utc)) ||
                      Boolean(serverErrors.active_day_hours_utc)
                        ? s.errorCheckbox
                        : ""
                    }
                  />
                }
                key={index}
                label={`${index}`.padStart(2, "0")}
              />
            );
          })}
        </div>
      </div>

      {/* active_week_days */}
      <div className={`${s.formRow} ${s.gridRow} ${s.daysMargin}`}>
        <div className={s.formLabel}>
          Active days <br /> of the week
        </div>
        <div className={`${s.rowGrid} ${s.days}`}>
          {daysWeek.map((day, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    name={`checkbox-${day}`}
                    onChange={() => changeCheckbox("day", index)}
                    checked={Boolean(values.active_week_days.includes(index))}
                    className={
                      (touched.active_week_days &&
                        Boolean(errors.active_week_days)) ||
                      Boolean(serverErrors.active_week_days)
                        ? s.errorCheckbox
                        : ""
                    }
                  />
                }
                key={index}
                label={day}
              />
            );
          })}
        </div>
      </div>

      {/* A-side */}
      <div className={`${s.formRow} ${s.number} ${s.mb56}`}>
        <div className={`${s.formLabel} ${s.labelNumber}`}>A side</div>
        <div className={s.fullWidth}>
          <RadioGroup
            name="a_side_type"
            row
            color="default"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.a_side_type}
          >
            <FormControlLabel
              label="Numbers"
              value="numbers"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Countries"
              value="countries"
              control={<Radio color="primary" />}
            />
          </RadioGroup>

          {/* A-side Numbers */}
          {values.a_side_type === "numbers" && (
            <>
              <div className={s.flex}>
                <div className={s.numberField}>
                  <TextField
                    type="number"
                    value={aNumberInput}
                    variant="outlined"
                    placeholder="Add number"
                    onWheel={(e: any) => e.target.blur()}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        resolveNumber("a");
                      }
                    }}
                    onChange={(e) =>
                      changeCustomField(e.target.value, "a_numbers")
                    }
                    error={
                      (touched.a_numbers && Boolean(errors.a_numbers)) ||
                      Boolean(serverErrors.a_numbers)
                    }
                    helperText={
                      (touched.a_numbers &&
                        errors.a_numbers !== FIELD_REQUIRED &&
                        errors.a_numbers) ||
                      serverErrors.a_numbers
                    }
                  />
                  <div className={`${s.message} ${s.absolute}`}>
                    Maximum allowed number of entries: 5000
                  </div>
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={aNumberInput === ""}
                  onClick={() => resolveNumber("a")}
                >
                  Add number
                </Button>

                <span>or</span>

                {/* A-side csv upload */}
                <Button
                  className={s.btnSecondary}
                  variant="contained"
                  onClick={() => uplodAInputRef?.current?.click()}
                  loading={isLoadingUplodA}
                  disabled={isLoadingUplodA}
                >
                  Upload CSV file
                  <input
                    ref={uplodAInputRef}
                    id={FILE_UPLOAD_A}
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>

                <Tooltip
                  title="First column of each row in CSV file will be converted into number/prefix value. It is recommnded to use CSV file with a single column of values: numbers or prefixes."
                  aria-label="Upload CSV"
                >
                  <IconQuestionCircle className={s.iconQuestion} width={20} />
                </Tooltip>
              </div>

              {values.a_numbers?.length > 0 && (
                <div className={s.numberGrid}>
                  {values.a_numbers.map((el, idx) => (
                    <NumberItem
                      key={idx}
                      item={el}
                      removeItem={removeItem}
                      type={"a"}
                    />
                  ))}
                </div>
              )}
            </>
          )}

          {/* A-side Countries */}
          {values.a_side_type === "countries" && (
            <>
              <div className={s.flex}>
                <div className={s.numberField}>
                  <TextField
                    select
                    label="Country"
                    name="country"
                    value={aCountrySelect}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        handleAddCountry();
                      }
                    }}
                    onChange={handleCountriesSelectChange}
                    variant="outlined"
                    error={
                      (touched.a_countries && Boolean(errors.a_countries)) ||
                      Boolean(serverErrors.a_countries)
                    }
                    helperText={
                      (touched.a_countries &&
                        errors.a_countries !== FIELD_REQUIRED &&
                        errors.a_countries) ||
                      serverErrors.a_countries
                    }
                  >
                    {Object.entries(COUNTRY_CODES_MAP)
                      .filter(([code]) => code !== "ZZ")
                      .map(([code, name]) => (
                        <MenuItem
                          key={code}
                          value={code}
                          disabled={values.a_countries.includes(
                            code as keyof typeof COUNTRY_CODES_MAP
                          )}
                        >
                          {name}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={aCountrySelect === ""}
                  onClick={handleAddCountry}
                >
                  Add country
                </Button>
              </div>

              {values.a_countries?.length > 0 && (
                <div className={s.numberGrid}>
                  {values.a_countries.map((code) => (
                    <NumberItem
                      key={code}
                      item={{
                        number: COUNTRY_CODES_MAP[code.toUpperCase()] || "",
                        country_iso: code,
                        is_valid: true,
                        belongs_to_network: true,
                      }}
                      removeItem={handleRemoveCountry}
                      type={"a"}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* B-side */}
      <div className={`${s.formRow} ${s.start} ${s.mb56}`}>
        <div className={`${s.formLabel} ${s.labelNumber}`}>B side</div>

        <div>
          <TextField
            select
            label="Network"
            id="destination_network"
            name="destination_network"
            value={values.destination_network}
            onChange={handleChange}
            className={s.networkSelect}
            variant="outlined"
            error={
              (touched.destination_network &&
                Boolean(errors.destination_network)) ||
              Boolean(serverErrors.destination_network)
            }
            helperText={
              (touched.destination_network &&
                errors.destination_network !== FIELD_REQUIRED &&
                errors.destination_network) ||
              serverErrors.destination_network
            }
          >
            {networks.map(({ name, id }) => (
              <MenuItem key={name} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>

          {values.destination_network ? (
            <>
              <RadioGroup
                name="b_side_type"
                row
                color="default"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.b_side_type}
              >
                <FormControlLabel
                  label="Numbers"
                  value="numbers"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  label="Prefixes"
                  value="prefixes"
                  control={<Radio color="primary" />}
                />
              </RadioGroup>

              <div className={s.txtPrimary}>
                {values.b_side_type === "numbers" &&
                  `TCG will generate calls to exact phone numbers you specified`}
                {values.b_side_type === "prefixes" &&
                  `TCG will generate random numbers using specified prefixes. If
                  no prefixes are added, TCG will use all prefixes of selected
                  network.`}
              </div>

              <div className={s.flex}>
                <div className={s.numberField}>
                  <TextField
                    type="number"
                    value={bNumberInput}
                    variant="outlined"
                    onWheel={(e: any) => e.target.blur()}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        resolveNumber("b");
                      }
                    }}
                    placeholder={
                      values.b_side_type === "numbers"
                        ? "Add number"
                        : "Add prefix"
                    }
                    onChange={(e) =>
                      changeCustomField(e.target.value, "b_numbers")
                    }
                    error={
                      values.b_side_type === "prefixes"
                        ? (touched.prefix_list &&
                            Boolean(errors.prefix_list)) ||
                          Boolean(serverErrors.prefix_list)
                        : values.b_side_type === "numbers"
                        ? (touched.b_numbers && Boolean(errors.b_numbers)) ||
                          Boolean(serverErrors.b_numbers)
                        : false
                    }
                    helperText={
                      values.b_side_type === "prefixes"
                        ? (touched.prefix_list &&
                            errors.prefix_list !== FIELD_REQUIRED &&
                            errors.prefix_list) ||
                          serverErrors.prefix_list
                        : values.b_side_type === "numbers"
                        ? (touched.b_numbers &&
                            errors.b_numbers !== FIELD_REQUIRED &&
                            errors.b_numbers) ||
                          serverErrors.b_numbers
                        : null
                    }
                  />
                  <div className={`${s.message} ${s.absolute}`}>
                    Maximum allowed number of entries: 5000
                  </div>
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={bNumberInput === ""}
                  onClick={() => resolveNumber("b")}
                >
                  {values.b_side_type === "numbers"
                    ? "Add number"
                    : "Add prefix"}
                </Button>

                <span>or</span>

                <Button
                  className={s.btnSecondary}
                  variant="contained"
                  onClick={() => uplodBInputRef?.current?.click()}
                  loading={isLoadingUplodB}
                  disabled={isLoadingUplodB}
                >
                  Upload CSV file
                  <input
                    ref={uplodBInputRef}
                    id={FILE_UPLOAD_B}
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>

                <Tooltip
                  title="First column of each row in CSV file will be converted into number/prefix value. It is recommnded to use CSV file with a single column of values: numbers or prefixes."
                  aria-label="Upload CSV side B"
                >
                  <IconQuestionCircle className={s.iconQuestion} width={20} />
                </Tooltip>
              </div>

              {values.b_side_type === "numbers"
                ? values.b_numbers?.length > 0 && (
                    <div className={s.numberGrid}>
                      {values.b_numbers.map((el, idx) => (
                        <NumberItem
                          key={idx}
                          item={el}
                          removeItem={removeItem}
                          type={"b"}
                        />
                      ))}
                    </div>
                  )
                : values.prefix_list?.length > 0 && (
                    <div className={s.numberGrid}>
                      {values.prefix_list.map((el, idx) => (
                        <NumberItem
                          key={idx}
                          item={{
                            number: el,
                            country_iso:
                              networks?.find(
                                (network) =>
                                  network.id === values.destination_network
                              )?.country_iso || "",
                          }}
                          removeItem={removeItem}
                          type={"b"}
                        />
                      ))}
                    </div>
                  )}

              {/* Use number once */}
              {values.b_side_type === "numbers" && (
                <div className={`${s.flex} ${s.mt24} `}>
                  <FormControlLabel
                    label={"Use number once"}
                    control={
                      <Checkbox
                        color="default"
                        id="use_b_number_once"
                        name="use_b_number_once"
                        onChange={handleChange}
                        value={values.use_b_number_once}
                        checked={values.use_b_number_once}
                      />
                    }
                  />
                  <Tooltip
                    title="Each number from the list will be used only once during scenario execution. Scenario will be finished when finish date/limits are reached or when all the numbers are used."
                    aria-label="Use B-numbers only once"
                  >
                    <IconQuestionCircle className={s.iconQuestion} width={20} />
                  </Tooltip>
                </div>
              )}

              {/* Add ported-in numbers */}
              {values.b_side_type === "prefixes" && (
                <div className={`${s.flex} ${s.mt24} `}>
                  <FormControlLabel
                    label={"Add ported-in numbers"}
                    control={
                      <Checkbox
                        color="default"
                        id="add_ported_in"
                        name="add_ported_in"
                        onChange={handleChange}
                        value={values.add_ported_in}
                        checked={values.add_ported_in}
                      />
                    }
                  />
                  <Tooltip
                    title="TCG will use ported-in B-numbers fetched from Call Registry node instance. If ported-in number fetching is not supported or your network does not support number portability, this flag will be ignored."
                    aria-label="Add ported-in numbers"
                  >
                    <IconQuestionCircle className={s.iconQuestion} width={20} />
                  </Tooltip>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>

      {/* route_group_ids */}
      <div className={`${s.formRow} ${s.start}`}>
        <div className={s.formLabel}>
          Route <br />
          groups
        </div>
        <div className={s.block}>
          {routes.map((route) => {
            return (
              <FormControlLabel
                key={route.id}
                control={
                  <Checkbox
                    color="default"
                    name={`checkbox-${route.id}`}
                    onChange={() => changeCheckbox("route", route.id)}
                    checked={Boolean(
                      (values.route_group_ids as []).find((x) => x === route.id)
                    )}
                    className={
                      (touched.route_group_ids &&
                        Boolean(errors.route_group_ids)) ||
                      Boolean(serverErrors.route_group_ids)
                        ? s.errorCheckbox
                        : ""
                    }
                  />
                }
                label={<FormLabelRoute route={route} />}
              />
            );
          })}
        </div>
      </div>

      {/* calls_per_hour */}
      <div className={`${s.formRow} ${s.field}`}>
        <div className={s.formLabel}>Calls per hour</div>
        <div className={s.fieldWidth}>
          <TextField
            type="number"
            variant="outlined"
            onBlur={handleBlur}
            className={s.input}
            name="calls_per_hour"
            onWheel={(e: any) => e.target.blur()}
            value={values.calls_per_hour}
            placeholder="Enter the number of calls"
            onChange={(e) => onChange(e, "calls_per_hour")}
            error={
              (touched.calls_per_hour && Boolean(errors.calls_per_hour)) ||
              Boolean(serverErrors.calls_per_month)
            }
            helperText={
              (touched.calls_per_hour &&
                errors.calls_per_hour !== FIELD_REQUIRED &&
                errors.calls_per_hour) ||
              serverErrors.calls_per_hour
            }
          />
          <div className={s.message}>
            Overall hourly limit for all scenarios: {limit?.max_calls_per_hour}
          </div>
        </div>
      </div>

      {/* calls_per_day */}
      <div className={`${s.formRow} ${s.field}`}>
        <div className={s.formLabel}>
          Max calls <br /> per day
        </div>
        <div className={s.fieldWidth}>
          <TextField
            type="number"
            variant="outlined"
            onBlur={handleBlur}
            className={s.input}
            name="calls_per_day"
            value={values.calls_per_day}
            onWheel={(e: any) => e.target.blur()}
            placeholder="Enter the number of calls"
            onChange={(e) => onChange(e, "calls_per_day")}
            error={
              (touched.calls_per_day && Boolean(errors.calls_per_day)) ||
              Boolean(serverErrors.calls_per_day)
            }
            helperText={
              (touched.calls_per_day &&
                errors.calls_per_day !== FIELD_REQUIRED &&
                errors.calls_per_day) ||
              serverErrors.calls_per_day
            }
          />
          <div className={s.message}>
            Overall daily limit for all scenarios: {limit?.max_calls_per_day}
          </div>
        </div>
      </div>

      {/* calls_per_month */}
      <div className={`${s.formRow} ${s.field}`}>
        <div className={s.formLabel}>
          Max calls <br /> per month
        </div>
        <div className={s.fieldWidth}>
          <TextField
            type="number"
            variant="outlined"
            onBlur={handleBlur}
            className={s.input}
            name="calls_per_month"
            value={values.calls_per_month}
            onWheel={(e: any) => e.target.blur()}
            placeholder="Enter the number of calls"
            onChange={(e) => onChange(e, "calls_per_month")}
            error={
              (touched.calls_per_month && Boolean(errors.calls_per_month)) ||
              Boolean(serverErrors.calls_per_month)
            }
            helperText={
              (touched.calls_per_month &&
                errors.calls_per_month !== FIELD_REQUIRED &&
                errors.calls_per_month) ||
              serverErrors.calls_per_month
            }
          />
          <div className={s.message}>
            Overall monthly limit for all scenarios:{" "}
            {limit?.max_calls_per_month}
          </div>
        </div>
      </div>

      <Button type="submit" color="primary" variant="contained">
        Save
      </Button>
    </form>
  );
});

export default Form;
