import React, {ReactElement} from "react";
import moment from "moment";

import {TBilling} from "../../../types";
import {tickFormatter} from "../../../utils";

import s from './Billing.module.scss'

export const TABLE_BILLING_KEYS = ['id', 'period', 'calls', 'cost', 'setup_fee_total', 'credit', 'amount'];

export const TABLE_BILLING_HEAD_VALUES = {
  id: 'ID',
  period: 'Period',
  calls: 'Validated calls',
  cost: 'Validation cost',
  setup_fee_total: 'Setup fee total',
  credit: 'Credit Note',
  amount: 'Total amount'
};

export const getContent = (value: string): string => {
  switch (value) {
    case 'empty':
    case 'link':
      return '';
    default:
      return value;
  }
}


export const TSXTableRowBilling = (id: string | number, arr: TBilling[]): ReactElement => {
  const elem = arr.find((el) => el.id === id);
  if (elem) {

    return (
      <tr key={elem.id} className={s.row}>
        <td>
          <div className={`${s.rowFlex} ${s.uppercase}`}>
            {`${1000 + elem.id}`}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {moment(elem.period).format('MMM YYYY')}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {elem.validation_units ? tickFormatter(elem.validation_units, elem.validation_units): '-'}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${elem.total - elem.credit_note_total === 0 ? s.dash : s.notDash}`}>
            ${parseFloat(String(Math.round(elem.validation_units_total / 100))).toFixed(2)}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.fee}`}>
            ${parseFloat(String(elem.setup_fee_total / 100)).toFixed(2)}
          </div>
        </td>
        <td>
          <div className={`${s.rowFlex} ${s.fee}`}>
            +${parseFloat(String(elem.credit_note_total / 100)).toFixed(2)}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>${parseFloat(String((elem.total - elem.credit_note_total) / 100)).toFixed(2)}</div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}
