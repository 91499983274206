import {makeAutoObservable, runInAction} from "mobx";
import {TNode, TNodeCreate, TStateNodeStore} from "../types";
import {defaultCreateNode} from "./defaultStates";
import {DashboardAPI} from "../services/DashboardAPI";

export class NodesStore {
  nodes: TNode[] = [];
  idsNodes: number[] | string[] | [] = [];
  node: TStateNodeStore = {
    item: null,
    idsNetworks: [],
    idsTokens: [],
    loading: true
  };
  createNode: TNodeCreate = defaultCreateNode;
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  setNodes = (nodes: TNode[]) => {
    this.idsNodes = nodes.map((el) => el.id);
    this.nodes = nodes;
    this.loading = false;
  }

  setNode = (data: TNode | null) => {
    const idsNetworks = data?.networks.map((el) => el.id)
    const idsTokens = data?.tokens.map((el) => el.id);
    this.node = {
      item: data,
      idsNetworks: idsNetworks || [],
      idsTokens: idsTokens || [],
      loading: false
    }
  }

  getNodesAsync = () => {
    this.loading = true;
    DashboardAPI.getNodes()
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setNodes(data)
        })
      })
  }

  getNodeAsync = (id: number) => {
    this.node = {
      ...this.node,
      loading: true
    }
    DashboardAPI.getNode(id)
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setNode(data)
        })
      }).catch(() => {
      runInAction(() => {
        this.setNode(null)
      })
    })
  }

  clearDataCreateNode = () => {
    this.createNode = defaultCreateNode;
  }

  createNodeChangeField = (params: Partial<TNodeCreate>) => {
    this.createNode = {
      ...this.createNode,
      ...params
    }
  }

  getCreateNodeFieldValue = (field: keyof TNodeCreate) => {
    return this.createNode[field]
  }
}
