import React, {ReactElement} from "react";
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Flag from "react-flagkit";

import {tickFormatter} from "../../../utils";
import {TGroupNetwork, TGroupNode, TNetworks, TNode} from "../../../types";
import s from './Dashboard.module.scss'

export const TABLE_NODES_KEYS = ['id', 'name', 'status', 'version', 'ip', 'calls', 'link'];

export const TABLE_NODES_HEAD_VALUES = {
  id: 'empty',
  name: 'name',
  status: 'status',
  version: 'cr version',
  ip: 'ip address',
  calls: 'Validated/Total Calls',
  link: 'empty'
};

export const TABLE_NETWORKS_KEYS = ['id', 'name', 'status', 'type', 'prefixes', 'calls', 'link'];


export const TABLE_NETWORKS_HEAD_VALUES = {
  id: 'empty',
  name: 'name',
  status: 'status',
  type: 'type',
  prefixes: 'prefixes',
  calls: 'Validated/Total Calls',
  link: 'empty'
};

export const getContent = (value: string): string => {
  switch (value) {
    case 'empty':
    case 'link':
      return '';
    default:
      return value;
  }
}

export const getColor = (status: string): string => {
  let val = status.toLowerCase()
  switch (val) {
    case 'connected':
      return '#1AAB2A';
    case 'setup':
      return '#8C8C8C';
    case 'registered':
      return '#2F3037';
    case 'in review':
      return '#8C8C8C';
    case 'down':
      return '#FBA700';
    case 'dry_run':
      return '#FBA700';
    default:
      return '#2D2D2D'
  }
}

export const TSXTableRowNodes = (id: string | number, arr: TNode[], calls: Array<TGroupNode>, goTo: (str: string | number) => void): ReactElement => {
  const elem = arr.find((el) => el.id === id);
  const findElem = calls.find((el) => el.id === id);
  const getCalls = () => (
    <div>
      <span style={{color: '#000'}}>{findElem?.valid ? tickFormatter(findElem.valid, findElem.valid) : ''}</span>
      {findElem?.valid && findElem?.total ? ' / ' : ''}
      <span>{findElem?.total ? tickFormatter(findElem.total, findElem.total) : ''}</span>
    </div>
  )

  const getStatusChip = () => {
    if (elem) {
      return (
        <>
          {elem.state.is_active ?
            <span className={s.chip} style={{marginRight: '5px', backgroundColor: getColor('connected')}}>UP</span> :
            <span className={s.chip} style={{marginRight: '5px', backgroundColor: getColor('down')}}>DOWN</span>
          }
          {elem.state.is_dry_run && (
            <span className={s.chip} style={{backgroundColor: getColor('dry_run')}}>Dry run</span>
          )}
        </>
      )
    }
    return null;
  }

  if (elem) {
    return (
      <tr key={elem.id} className={s.row}>
        <td/>
        <td>
          <div className={`${s.rowFlex} ${s.name}`}>
            <DnsOutlinedIcon/>
            <div>{elem.label}</div>
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>
            {getStatusChip()}
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>{elem.state?.client_version || '-'}</div>
        </td>
        <td>
          <div className={s.rowFlex}>{elem.state?.host || '-'}</div>
        </td>
        <td>
          <div className={s.rowFlex}>{getCalls()}</div>
        </td>
        <td>
          <div className={s.rowFlex}>
            <div className={s.more}><ArrowForwardIosIcon onClick={() => goTo(elem.id)}/></div>
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}

export const TSXTableRowNetworks = (id: string | number, arr: TNetworks[] | [], calls: Array<TGroupNetwork>, goTo: (str: string | number) => void): ReactElement => {
  const elem = arr.find((el) => el.id === id);
  const findElem = calls.find((el) => el.id === id);

  if (elem) {
    const getCalls = () => (
      <div>
        <span style={{color: '#000'}}>{findElem?.valid ? tickFormatter(findElem.valid, findElem.valid) : ''}</span>
        {findElem?.valid && findElem?.total ? ' / ' : ''}
        <span>{findElem?.total ? tickFormatter(findElem.total, findElem.total) : ''}</span>
      </div>
    )

    const getStatus = () => {
      switch (elem.participation_type.toLowerCase()) {
        case 'full':
          return <span className={s.chip} style={{backgroundColor: '#1AAB2A'}}>Participant</span>
        case 'in progress':
          return <span className={s.chip} style={{backgroundColor: '#0176FE'}}>In progress</span>
        case 'partial':
          return <span className={s.chip} style={{backgroundColor: '#1AAB2A'}}>Partial</span>
        default:
          return <span className={s.chip} style={{backgroundColor: '#878787'}}>Unknown</span>
      }
    }

    return (
      <tr key={elem.id} className={`${s.row} ${s.link}`} onClick={() => goTo(elem.id)}>
        <td/>
        <td>
          <div className={`${s.rowFlex} ${s.name}`}>
            <div className={s.flag}><Flag country={elem.country_iso}/></div>
            <div>
              {elem.name}
            </div>
          </div>
        </td>
        <td>
          <div className={s.rowFlex}>{getStatus()}</div>
        </td>
        <td>
          <div className={s.rowFlex}>{elem.type}</div>
        </td>
        <td>
          <div className={s.rowFlex}>{elem.prefix_count}</div>
        </td>
        <td>
          <div className={s.rowFlex}>{getCalls()}</div>
        </td>
        <td>
          <div className={s.rowFlex}>
            <div className={s.more}><ArrowForwardIosIcon onClick={() => goTo(elem.id)}/></div>
          </div>
        </td>
      </tr>
    )
  }
  return <tr key={id}/>
}
