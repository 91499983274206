import React, {FC} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import * as yup from "yup";
import copy from "copy-to-clipboard";
import {MenuItem, TextField, Icon, Button, CircularProgress} from "@material-ui/core";

import {useStores} from "../../../../../store/useStore";
import {FIELD_REQUIRED} from "../../../../../constants";

import s from '../../forms.module.scss';

import copyIcon from 'assets/icons/copy.svg'

const mockHostSystem = ['Windows', 'Linux', 'Mac'];

const schema = yup.object({
  system: yup.string().required(FIELD_REQUIRED).nullable(),
  script: yup.string().required(FIELD_REQUIRED).nullable(),
})


const Install: FC<any> = observer(() => {

  const {
    nodesStore: {
      createNode,
      getCreateNodeFieldValue
    },
    notificationStore: {
      changeStateSnackbar
    }
  } = useStores();

  const handleCopyLink = () => {
    copy(createNode.install_script);
    changeStateSnackbar("copy")
  }

  const formik = useFormik({
    initialValues: {
      system: getCreateNodeFieldValue('host_system'),
      script: getCreateNodeFieldValue('install_script')
    },
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit: ((values) => {
      console.log("values", values)
    })
  })

  const {handleSubmit, values, handleChange, errors, touched, handleBlur} = formik;

  return (
    <div>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.systemSelect}>
          <TextField
            select
            label="Your host system"
            placeholder="Your host system"
            name="system"
            value={values.system}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.system && Boolean(errors.system))}
            helperText={(touched.system && errors.system !== FIELD_REQUIRED && errors.system)}
          >
            {mockHostSystem.map((el, index) => (
              <MenuItem key={`${el}-${index}`} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={s.installTitle}>Use the following install script to install CR agent on your host:</div>
        <div className={s.scriptCopy}>
          <TextField
            value={values.script}
            name="script"
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            variant="outlined"
            InputProps={{
              startAdornment: <Icon className={s.copyIcon} onClick={handleCopyLink}>
                <img src={copyIcon} alt=''/>
              </Icon>
            }}
            error={(touched.script && Boolean(errors.script))}
            helperText={(touched.script && errors.script !== FIELD_REQUIRED && errors.script)}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={true}
          className={s.buttonInstall}
        >
          <div className={s.wrapperProgress}>
            <CircularProgress/>
          </div>
          <div>
            Waiting for installation...
          </div>
        </Button>
      </form>
    </div>
  )
});

export default Install;
