import axios from "axios";

const AUTH_HEADER = 'X-ABH-Auth-Token';
export const ACCESS_TOKEN_KEY = "abh.portal.accessToken";
export const OPERATOR_ID = "abh.portal.operator";
export const CONFIRM_EMAIL = "abh.portal.signup.email";
export const REGISTRATION_REQUEST = "abh.portal.onboarding.registrationRequest";
export const CHECKBOX_VALID = 'ACTIVE_VALID';
export const CHECKBOX_TOTAL = 'CHECKBOX_TOTAL'

export const instance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    'Cache-Control': 'no-cache'
  }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    const operatorId = localStorage.getItem(OPERATOR_ID);
    if(config?.headers) {
      if (token) {
        config.headers[AUTH_HEADER] = String(localStorage.getItem(ACCESS_TOKEN_KEY));
      }
      if (operatorId) {
        config.headers['X-ABH-Operator-ID'] = String(localStorage.getItem(OPERATOR_ID));
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

