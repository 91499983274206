import {makeAutoObservable, runInAction} from "mobx";

import {TCompany, TStoreBilling, TBilling, TRoutes} from "../types";
import {OperatorAPI} from "../services/OperatorAPI";
import {otherAPI} from "../services";

export class OperatorStore {
  company: TCompany | null = null;
  billing: TStoreBilling = {
    list: [],
    loading: true,
    ids: []
  };
  routes: TRoutes[] = [];
  loadingRoutes: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  setCompany = (company: TCompany | null) => {
    this.company = company;
  }

  setBilling = (billing: TBilling[]) => {
    const idsArray = billing.map((el) => el.id)
    this.billing = {
      list: billing,
      loading: false,
      ids: idsArray
    }
  }

  setRoutes = (data: TRoutes[]) => {
    this.loadingRoutes = false;
    this.routes = data;
  }

  getCompanyAsync = () => {
    OperatorAPI.getCompany()
      .then(({ data }) => {
        this.setCompany(data);
      })
      .catch(() => {
        this.setCompany(null);
      });
  }

  changeCompanyAsync = (params: Partial<TCompany>) => {
    return OperatorAPI.changeCompany(params)
  }

  getRouteGroupsAsync = () => {
    this.loadingRoutes = true;
    otherAPI
      .getRouteGroups()
      .then(({ data }) => {
        this.setRoutes(data);
      })
      .catch(() => {
        this.setRoutes([]);
      });
  }

  getBillingAsync = () => {
    OperatorAPI.getBilling()
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setBilling(data)
        })
      }).catch(() => {
      runInAction(() => {
        this.setBilling([])
      })
    })
  }
}
