import React, {FC, ReactElement} from "react";
import {useFormik} from "formik";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import {MenuItem, TextField, Checkbox, FormControlLabel} from "@material-ui/core";

import SimpleTable from "../../simpleTable";
import {getContent, TABLE_NETWORK_KEYS, TABLE_NETWORK_HEAD_VALUES, TSXTableRowNetwork} from './helper';
import {COUNTRY_CODES_MAP, FieldControlPermission} from "../../../../utils";
import {TNetwork} from "../../../../types";

import s from './NetworkForm.module.scss';

type Props = {
  item: TNetwork,
  ids: number[],
  copyMessage: (str: string) => void,
  clickButtons: (str: string) => void;
}

const NetworkForm: FC<Props> = ({item, ids, copyMessage, clickButtons}) => {

  const formik = useFormik({
    initialValues: {
      network: item.name,
      name: item.name,
      type: item.type,
      country: item.country_iso,
      mccmnc: item.mcc_mnc,
      is_destination: item.capabilities.is_destination,
      is_origin: item.capabilities.is_origin,
      process_transit: item.capabilities.process_transit,
      provides_roaming: item.capabilities.provides_roaming,
      supports_mnp: item.capabilities.supports_mnp,
      supports_roaming: item.capabilities.supports_roaming
    },
    onSubmit: (() => {
    })
  })

  const {values, handleChange, handleSubmit} = formik;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTableHeadNetwork = (el: keyof typeof TABLE_NETWORK_HEAD_VALUES): string => {
    return getContent(TABLE_NETWORK_HEAD_VALUES[el])
  }

  const getTableRowNetwork = (id: string | number): ReactElement => {
    if (item.prefix_blocks.length > 0) {
      return TSXTableRowNetwork(id, item.prefix_blocks, copyMessage, anchorEl, handleClick, handleClose, clickButtons);
    }
    return <tr>
      <div className={s.empty}>There is no data</div>
    </tr>
  }

  const edit = false;

  const hideElem = item.type === "Application";

  return (
    <div className={s.wrapper}>
      {item && (
        <div className={s.content}>
          <form onSubmit={handleSubmit}>
            <div className={s.subtitle}>
              Identification
            </div>
            <div className={s.wrapperForm}>
              {edit && (
                <TextField
                  label="Network"
                  name="network"
                  placeholder="Network"
                  variant="outlined"
                  disabled
                  select
                  value={values.network}
                  onChange={handleChange}
                >
                  <MenuItem value={values.network}>
                    {values.network}
                  </MenuItem>
                </TextField>
              )}
              <div className={s.row}>
                <TextField
                  label="Network name"
                  name="name"
                  placeholder="Network name"
                  variant="outlined"
                  disabled
                  value={values.name}
                  onChange={handleChange}
                />
                <TextField
                  label="Network type"
                  name="type"
                  placeholder="Network type"
                  variant="outlined"
                  disabled
                  select
                  value={values.type}
                  onChange={handleChange}
                >
                  <MenuItem value={values.type}>
                    {values.type}
                  </MenuItem>
                </TextField>
              </div>
              {!hideElem &&
                <div className={s.row} style={{marginBottom: '38px'}}>
                  <TextField
                    label="Country"
                    name="country"
                    placeholder="Country"
                    variant="outlined"
                    disabled
                    select
                    value={values.country}
                    onChange={handleChange}
                  >
                    <MenuItem value={values.country}>
                      {/* @ts-ignore */}
                      {COUNTRY_CODES_MAP[values.country] || values.country}
                    </MenuItem>
                  </TextField>
                  <TextField
                    label="MCC MNC"
                    name="mccmnc"
                    placeholder="MCC MNC"
                    variant="outlined"
                    disabled
                    value={values.mccmnc}
                    onChange={handleChange}
                  />
                </div>
              }
            </div>

            <div className={s.subtitle}>
              Capabilities
            </div>
            <div className={s.checkboxes}>
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.is_destination}/>}
                label="Terminates trafficIs"
                disabled
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.is_origin}/>}
                label="Originates traffic"
                disabled
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.process_transit}/>}
                label="Handles transit traffic"
                disabled
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.provides_roaming}/>}
                label="Provides Roaming services"
                disabled
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.supports_mnp}/>}
                label="Supports MNP"
                disabled
              />
              <FormControlLabel
                control={<Checkbox onChange={handleChange} checked={values.supports_roaming}/>}
                label="Supports Roaming services"
                disabled
              />
            </div>
          </form>
          <div className={s.subtitle}>
            Prefix blocks <span><AddCircleRoundedIcon onClick={() => clickButtons(FieldControlPermission.create_prefix)}/></span>
          </div>
          <div className={s.table}>
            <SimpleTable
              styleName={s.tableNetwork}
              ids={ids}
              getTableHead={getTableHeadNetwork}
              tableKeys={TABLE_NETWORK_KEYS}
              getTableRow={getTableRowNetwork}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NetworkForm;
