import { ScenariosApi } from "../services";
import { makeAutoObservable, runInAction } from "mobx";
import { AxiosResponse } from "axios";
import _ from "lodash";

import {
  TCall,
  TCallsList,
  TLimit,
  TLimits,
  TList,
  TScenario,
  TScenarioBody,
  TScenariosList,
  TStatScenario,
  TStatsScenario,
} from "../types";
import moment from "moment";

export class ScenariosStore {
  scenarios: TScenariosList = {
    loading: true,
    list: [],
    ids: [],
  };
  page_size: number = 10;
  calls: TCallsList = {
    loading: true,
    list: [],
    ids: [],
  };
  limits: TLimits = {
    loading: true,
    limit: null,
  };
  stats: TStatsScenario = {
    loading: true,
    list: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setScenarios = (data: TScenario[]) => {
    const sortArray = _.sortBy(data, function (item) {
      return item.id;
    });
    const ids = sortArray.map((el) => el.id);
    this.scenarios = {
      loading: false,
      list: sortArray,
      ids: ids,
    };
  };

  setCalls = (data: TCall[], concat?: boolean) => {
    if (concat) {
      const calls = this.calls.list.concat(data);
      this.calls = {
        loading: false,
        list: calls,
        ids: calls.map((el) => el.id),
      };
    } else {
      this.calls = {
        loading: false,
        list: data,
        ids: data.map((el) => el.id),
      };
    }
  };

  setLimits = (values: TLimit | null) => {
    this.limits = {
      loading: false,
      limit: values,
    };
  };

  setStats = (data: TStatScenario[]) => {
    const mutateArray: TStatScenario[] = JSON.parse(JSON.stringify(data));
    const sortData = mutateArray.sort((x, y) => x.datetime - y.datetime);
    const arrayData: TList[] = sortData.map((el) => ({
      datetime: el.datetime,
      date: moment(el.datetime).format("DD/MM"),
      ok_count: el.ok_count,
      initiated_count: el.initiated_count || 0,
      domestic_cli_count: el.domestic_cli_count || 0,
      spoofed_cli_count: el.spoofed_cli_count || 0,
      undelivered_count: el.undelivered_count || 0,
      failed_route_count: el.failed_route_count || 0,
    }));

    this.stats = {
      loading: false,
      list: arrayData,
    };
  };

  getScenariosAsync = () => {
    ScenariosApi.getScenarios()
      .then((res: AxiosResponse<TScenario[]>) => {
        this.setScenarios(res.data);
      })
      .catch(() => {
        this.setScenarios([]);
      });
  };

  editStatus = async (id: number | string, status: boolean) => {
    await ScenariosApi.editStatusScenario(id, status);

    runInAction(() => {
      this.scenarios.list = this.scenarios.list.map((item) =>
        item.id === id
          ? {
              ...item,
              enabled: status,
            }
          : item
      );
    });
  };

  getCallAsync = (id: number, page_size?: number, concat?: boolean) => {
    const pageCount = page_size ? page_size : this.page_size;
    ScenariosApi.getNumberCalls(id, pageCount)
      .then((res: AxiosResponse<TCall[]>) => {
        runInAction(() => {
          this.setCalls(res.data, concat);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.setCalls([]);
        });
      });
  };

  getLimits = () => {
    this.limits = {
      ...this.limits,
      loading: true,
    };
    ScenariosApi.getLimits()
      .then((res: AxiosResponse<TLimit>) => {
        runInAction(() => {
          this.setLimits(res.data);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.setLimits(null);
        });
      });
  };

  getStatsAsync = (id: number) => {
    this.stats = {
      ...this.stats,
      loading: true,
    };
    ScenariosApi.getStats(id)
      .then((res: AxiosResponse<TStatScenario[]>) => this.setStats(res.data))
      .catch(() => this.setStats([]));
  };

  createScenario = (body: TScenarioBody) => {
    return ScenariosApi.createScenario(body);
  };

  updateScenario = (id: number | string, body: TScenarioBody) => {
    return ScenariosApi.updateScenario(id, body);
  };

  resolveNumberRequest = (
    value: string | number,
    networkId?: number | string
  ) => {
    return ScenariosApi.resolveNumber(value, networkId);
  };
}
