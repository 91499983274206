import {makeAutoObservable} from "mobx";
import {TSeverity, TSnackbar} from "../types";

export class NotificationStore {
  snackbar: TSnackbar = {
    open: false,
    message: '',
  }

  constructor() {
    makeAutoObservable(this)
  }

  changeStateSnackbar = (err?: any, severity?: TSeverity) => {
    if (err !== undefined) {
      this.snackbar = {
        open: true,
        message: err?.message || err,
        severity: severity
      }
    } else {
      this.snackbar = {
        open: false,
        message: '',
        severity: severity
      }
    }
  }
}
