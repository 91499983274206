export const ROOT = '/';

export const SIGN_UP = {
  ROOT: 'sign-up',
  CREATE: 'create-account',
  CONFIRM: 'confirm-email',
  OPERATOR: 'operator-info',
  COMPANY: 'company-info',
  INTEGRATION: 'integration-type',
  ACTIVATION: 'activation'
};

export const SIGN_IN = {
  ROOT: 'sign-in',
  FORGOT: 'forgot',
};

export const HELPERS = {
  NEW: 'new',
  INDEX: ':id',
  CREATE: 'create'
}

export const MAIN = {
  DASHBOARD: 'main',
  NODES: 'nodes',
  NETWORKS: 'networks',
  TEST_CALLS: 'testcalls',
  COMPANY_INFO: 'company-info',
  BILLING: 'billing',
  PARTICIPANTS: 'participants'
}
