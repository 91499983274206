import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, TextField} from "@material-ui/core";
import {useFormik} from "formik";
import * as yup from "yup";

import {useStores} from "../../../../../store/useStore";
import {FIELD_REQUIRED} from "../../../../../constants";

import s from '../../forms.module.scss'

const schema = yup.object({
  name: yup.string().required(FIELD_REQUIRED),
})

type Props = {
  step: number,
  setStep: (arg: number) => void
}

const NodeName: FC<Props> = observer(({step, setStep}) => {

  const {
    nodesStore: {
      createNodeChangeField,
      getCreateNodeFieldValue,
    }
  } = useStores();

  const formik = useFormik({
    initialValues: {
      name: getCreateNodeFieldValue('name')
    },
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit: ((values) => {
      createNodeChangeField({
        name: String(values.name)
      })
      setStep(step + 1);
    })
  })

  const {handleSubmit, values, handleChange, errors, touched, handleBlur, isValid} = formik;

  return (
    <div>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.nameField}>
          <TextField
            name="name"
            label="Node name / label"
            placeholder="Node name / label"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            error={(touched.name && Boolean(errors.name))}
            helperText={(touched.name && errors.name !== FIELD_REQUIRED && errors.name)}
          />
        </div>
        <div className={s.footer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
})

export default NodeName;
