import React from "react";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import CreateNode from "./CreateNode";
import ListNodes from "./listNodes";
import {HELPERS} from "../../../utils";

const Nodes = () => {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/${HELPERS.CREATE}`} component={CreateNode}/>
      <Route exact path={[path, `${path}/:id`]} component={ListNodes}/>
      <Redirect from="*" to={path}/>
    </Switch>
  )
}

export default Nodes
